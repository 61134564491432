import React, { Fragment, useEffect, useState } from "react";
import { ProjectDetailType, ProjectDeviceType, ProjectType } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getAccountSelector, getProjectListSelector } from "../../../redux/selectors";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { projectAPI } from "../../../services";
import { setProjectList } from "../../../redux/slices/projectListSlice";
import { handleMessage } from "../../commons/Message";
import { Empty } from "antd";

interface Props {
  projectDetail: ProjectDetailType;
  device: ProjectDeviceType;
  onSuccess: (newDevice: ProjectDeviceType) => void;
}

const TransferDeviceProjectForm: React.FC<Props> = ({ projectDetail, device, onSuccess }) => {
  const dispatch = useDispatch();
  const projectList = useSelector(getProjectListSelector);
  const account = useSelector(getAccountSelector);
  const [projectListFilter, setProjectListFilter] = useState<ProjectType[]>([]);

  const handleFilterProjectList = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    const newProjectListFilter = projectList.filter(
      (item) =>
        (`${item.id}`.toLowerCase().includes(value) ||
          item.name.toLowerCase().includes(value) ||
          item.code.toLowerCase().includes(value)) &&
        item.code !== projectDetail.profile.code
    );
    setProjectListFilter(newProjectListFilter);
  };

  const handleGetProjectList = async () => {
    try {
      dispatch(setLoading(true));
      const resultProjectApi = await projectAPI.getProjectList();
      if (resultProjectApi.data.result) {
        dispatch(setProjectList(resultProjectApi.data.data));
      } else {
        console.log(resultProjectApi.data);
        handleMessage("error", resultProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const newProjecId = (document.querySelector('input[name="new-project"]:checked') as HTMLInputElement)?.value;
      console.log(newProjecId);
      const resultTransferDeviceProjectApi = await projectAPI.transferDeviceToOtherProject(newProjecId, device.id);
      if (resultTransferDeviceProjectApi.data.result) {
        handleMessage("success", resultTransferDeviceProjectApi.data.msg);
        onSuccess(resultTransferDeviceProjectApi.data.data);
      } else {
        handleMessage("error", resultTransferDeviceProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (account?.role === "ROLE_ADMIN" && projectList.length === 0) {
      handleGetProjectList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProjectListFilter(projectList.filter((item) => item.code !== projectDetail.profile.code));
  }, [projectDetail.profile.code, projectList]);

  return (
    <div className="transfer-device-project-form">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-3">Chuyển thiết bị sang dự án khác</h1>
        <div className="text-muted fw-semibold fs-5">Dự án hiện tại: {projectDetail.profile.name}</div>
      </div>
      <div className="w-100 position-relative">
        <img
          src="/assets/icons/search.svg"
          alt=""
          className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
        />
        <input
          type="text"
          className="form-control form-control-lg px-15"
          placeholder="Search by id, name or code of project..."
          onInput={handleFilterProjectList}
          disabled={projectList.length === 0}
        />
      </div>
      <div className="py-5">
        <div className="mh-375px scroll-y me-n7 pe-7">
          {projectList.length === 0 || projectListFilter.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            projectListFilter.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  <div className="rounded d-flex flex-stack bg-active-lighten px-4 py-2">
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="new-project"
                          value={item.id}
                          id={`transfer-project-${item.id}`}
                        />
                        <label className="ms-5" htmlFor={`transfer-project-${item.id}`}>
                          <span className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">{item.name}</span>
                          <div className="fw-semibold text-muted">{item.code}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {index !== projectListFilter.length - 1 && (
                    <div className="border-bottom border-gray-300 border-bottom-dashed"></div>
                  )}
                </Fragment>
              );
            })
          )}
        </div>
      </div>
      <div className="text-end">
        <button className="btn btn-primary w-100" onClick={handleSubmit} disabled={projectList.length === 0}>
          Chuyển dự án
        </button>
      </div>
    </div>
  );
};

export default TransferDeviceProjectForm;
