import React, { useContext, useMemo } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, UpdatedUserType } from "../../../types";
import { SubmitHandler, useForm } from "react-hook-form";
import { SettingProjectUserContext } from "./context/context";
import { handleCheckAxiosError, regex } from "../../../utils";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setAccount } from "../../../redux/slices/accountSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { setUpdatedUser, setUserList } from "./context/reducer";

interface Props {
  projectDetail: ProjectDetailType;
}

const UpdateUserForm: React.FC<Props> = ({ projectDetail }) => {
  const dispatch = useDispatch();
  const { state, dispatchContext } = useContext(SettingProjectUserContext);
  const updatedUser = state.updatedUser;
  const { code, projectId } = projectDetail.profile;
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<UpdatedUserType>({
    defaultValues: useMemo(() => {
      return updatedUser
        ? {
            id: updatedUser.id,
            email: updatedUser.email,
            fullName: updatedUser.fullName,
            phone: updatedUser.phone,
            wifiProfile: updatedUser.wifiProfile,
            department: updatedUser.department,
          }
        : {};
    }, [updatedUser]),
  });

  const formValuesState = watch();

  const onSubmit: SubmitHandler<UpdatedUserType> = async (values) => {
    try {
      dispatch(setLoading(true));
      if (values.phone === "") {
        values.phone = null;
      }
      const resultUpdateUserApi = await projectAPI.updateUser(projectId, values);
      if (resultUpdateUserApi.data.result) {
        handleMessage("success", resultUpdateUserApi.data.msg);
        dispatchContext(setUpdatedUser(null));
        dispatchContext(
          setUserList(
            state.userList.map((item) => {
              if (item.id === resultUpdateUserApi.data.data.id) {
                return resultUpdateUserApi.data.data;
              } else return item;
            })
          )
        );
      } else {
        handleMessage("error", resultUpdateUserApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="create-user-form">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-1">Cập nhật thông tin người dùng</h1>
        <div className="text-muted fw-semibold fs-5">Cập nhật thông tin người dùng dự án {code}</div>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Họ tên</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.fullName && "border-danger"}`}
                  placeholder="Họ tên"
                  {...register("fullName", {
                    required: "Vui lòng nhập Họ tên!",
                  })}
                  onBlur={() => setValue("fullName", formValuesState.fullName?.trim())}
                />
                {errors.fullName && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.fullName.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="email"
                  className={`form-control form-control-lg ${errors.email && "border-danger"}`}
                  placeholder="Email"
                  {...register("email", {
                    required: "Vui lòng nhập Email!",
                    pattern: {
                      value: regex.email,
                      message: "Email không hợp lệ!",
                    },
                  })}
                />
                {errors.email && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.email.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">Số điện thoại</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Số điện thoại"
                  {...register("phone", {
                    pattern: {
                      value: regex.phone,
                      message: "Số điện thoại không hợp lệ!",
                    },
                  })}
                />
                {errors.phone && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.phone.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">Phòng ban</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="string"
                  className="form-control form-control-lg"
                  placeholder="Phòng ban"
                  {...register("department")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Wifi profile</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder"
                  value={formValuesState.wifiProfile}
                  onChange={(value) => setValue("wifiProfile", value, { shouldDirty: true })}
                  options={projectDetail.profile.wifiProfile.map((item) => {
                    return {
                      value: item.name,
                      label: item.name,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button type="reset" className="btn btn-secondary me-3" onClick={() => reset()} disabled={!isDirty}>
              Reset
            </button>
            <button type="submit" className="btn btn-primary" disabled={!isDirty}>
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(UpdateUserForm, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
