import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccountSelector, getIsRegistrationOpenSelector } from "../../../redux/selectors";
import AccountTopBarIntroduction from "../AccountTopbarIntroduction";

interface Props {
  onClickMenuItem?: () => void;
}

const menuData = [
  { name: "Formats", idEle: "#Formats" },
  {
    name: "Differentiations",
    idEle: "#Differentiations",
  },
  {
    name: "Locations",
    idEle: "#Locations",
  },
  {
    name: "Targeting",
    idEle: "#Targeting",
  },
  {
    name: "Contact",
    idEle: "#Contact",
  },
];

const MenuIntroduction: React.FC<Props> = ({ onClickMenuItem }) => {
  const account = useSelector(getAccountSelector);
  const isRegistrationOpen = useSelector(getIsRegistrationOpenSelector);

  const handleScrollTo = (idElement: string) => {
    const element = document.querySelector(idElement);
    if (element) {
      element.scrollIntoView();
      onClickMenuItem && onClickMenuItem();
    }
  };

  return (
    <div className="menu-introduction bg-wimesh">
      <div className="container d-md-flex justify-content-between">
        <ul className="list-style-none m-0 p-0 d-md-flex">
          {menuData.map((item, index) => {
            return (
              <li
                key={index}
                className="menu-introduction__item"
                onClick={() => handleScrollTo(item.idEle)}
              >
                <span className="d-block py-5 px-3 text-white text-decoration-none fs-5 bg-hover-wimesh pe-pointer">
                  {item.name}
                </span>
              </li>
            );
          })}
        </ul>
        <div>
          {account ? (
            <div className="menu-introduction__item position-relative account-topbar d-none d-md-block">
              <p className="d-flex align-items-center mb-0 py-5 px-3 text-white text-decoration-none fs-5 bg-hover-wimesh pe-pointer">
                <img
                  src="/assets/icons/user-solid.svg"
                  alt="account-icon"
                  width={15}
                  className="img-white me-2"
                />
                <span className="text-truncate" style={{ maxWidth: 150 }}>
                  {account.email}
                </span>
              </p>
              <AccountTopBarIntroduction />
            </div>
          ) : (
            <div>
              <Link
                to="/login"
                className="menu-introduction__item d-block d-md-inline-block py-5 px-3 text-white text-decoration-none text-hover-underline fs-5"
              >
                Login
              </Link>
              {isRegistrationOpen && (
                <span className="text-white fs-5 d-none d-md-inline-block">&nbsp;&#47;&nbsp;</span>
              )}
              {isRegistrationOpen && (
                <Link
                  to="/signup"
                  className="d-block d-md-inline-block py-5 px-3 text-white text-decoration-none text-hover-underline fs-5"
                >
                  Sign up
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(MenuIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
