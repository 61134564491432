import React from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import PopupChangePassword from "../ModalChangePassword";
import { setShowModalChangePassword } from "../../../redux/slices/appSlice";
import { getAccountSelector } from "../../../redux/selectors";
import { setAccount } from "../../../redux/slices/accountSlice";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { accountAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { withErrorBoundary } from "react-error-boundary";
import { setMenu } from "../../../redux/slices/menuSlice";

// ********************************************************** \\

const NavBar: React.FC = () => {
  const dispatch = useDispatch();

  const account = useSelector(getAccountSelector);

  const handleLogout = async () => {
    try {
      dispatch(setLoading(true));
      const resultLogoutApi = await accountAPI.logout();
      if (resultLogoutApi.data.result) {
        dispatch(setAccount(null));
        handleMessage("success", resultLogoutApi.data.msg);
        dispatch(setMenu([]));
      } else {
        handleMessage("error", resultLogoutApi.data.msg);
      }
    } catch (error) {
      console.log("Lỗi đăng xuất: ", error);
      handleMessage("error", "Lỗi hệ thống. Vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="app-navbar flex-shrink-0">
        <div className="app-navbar-item ms-1 ms-md-3" id="kt_header_user_menu_toggle">
          <div
            className="cursor-pointer symbol symbol-30px symbol-md-40px"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            id="kt_header_user_menu_toggle_avatar"
          >
            <img src="/assets/images/avatar.jpeg" alt="account" />
          </div>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
            data-kt-menu="true"
            id="kt_header_user_menu_toggle_menu"
          >
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                <div className="symbol symbol-50px me-4">
                  <img alt="Logo" src="/assets/images/avatar.jpeg" />
                </div>
                <div className="d-flex flex-column w-150px">
                  <div className="fw-bold fs-5 text-overflow text-overflow-1 text-truncate">
                    {account?.email}
                  </div>

                  <span className="fw-semibold text-muted text-hover-primary fs-7 text-overflow text-overflow-1 text-truncate">
                    {account?.phone}
                  </span>
                </div>
              </div>
            </div>
            <div className="separator my-2"></div>

            <div className="menu-item px-4 my-1">
              <span
                onClick={() => dispatch(setShowModalChangePassword(true))}
                className="menu-link px-4"
              >
                Thay đổi mật khẩu
              </span>
            </div>

            <div className="menu-item px-4">
              <NavLink
                to="/"
                onClick={handleLogout}
                className="menu-link px-4 text-decoration-none"
              >
                Đăng xuất
              </NavLink>
            </div>
          </div>
        </div>
        {/* <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-35px h-md-35px"
            id="kt_app_header_menu_toggle"
          >
            <span className="svg-icon svg-icon-2 svg-icon-md-1">
              <img src="/assets/icon/menu.svg" alt="" />
            </span>
          </div>
        </div> */}
      </div>
      <PopupChangePassword />
    </>
  );
};

export default withErrorBoundary(NavBar, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
