import React, { useEffect, useMemo } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, TemplateConfigType } from "../../../types";
import { SubmitHandler, useForm } from "react-hook-form";
import ImagePreview from "../../commons/ImagePreview";
import { projectAPI, uploadAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError, regex } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { useDispatch } from "react-redux";
import { ColorPicker } from "antd";
import { setLoading } from "../../../redux/slices/loadingSlice";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

const SettingProjectConfig: React.FC<Props> = ({ projectDetail, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<TemplateConfigType>({
    defaultValues: useMemo(() => {
      return {
        textTrial: projectDetail.profile.templateConfig.textTrial,
        url: projectDetail.profile.templateConfig.url,
        urlRedirect: projectDetail.profile.templateConfig.urlRedirect,
        background: projectDetail.profile.templateConfig.background,
        bgColor: projectDetail.profile.templateConfig.bgColor,
        txtColor: projectDetail.profile.templateConfig.txtColor,
        projectId: projectDetail.profile.templateConfig.projectId,
      };
    }, [projectDetail]),
  });
  const formValuesState = watch();

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const inputName = e.target.getAttribute("input-name") as "background";
      if (!e.target.files) {
        setValue(inputName, "");
        return;
      }
      const file = e.target.files[0];
      const resultUploadFileApi = await uploadAPI.uploadFile(file);
      if (resultUploadFileApi.data.result) {
        setValue(inputName, `${resultUploadFileApi.data.data.urlImage}`, { shouldDirty: true });
      } else {
        handleMessage("error", resultUploadFileApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    }
  };

  const onSubmit: SubmitHandler<TemplateConfigType> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultUpdateProfileProjectApi = await projectAPI.updateProjectProfile({
        ...projectDetail.profile,
        templateConfig: formData,
      });
      if (resultUpdateProfileProjectApi.data.result) {
        onUpdateSuccess({ ...projectDetail, profile: resultUpdateProfileProjectApi.data.data });
        handleMessage("success", resultUpdateProfileProjectApi.data.msg);
      } else {
        handleMessage("error", resultUpdateProfileProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    register("background");
    register("bgColor");
    register("txtColor");
    register("projectId");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      textTrial: projectDetail.profile.templateConfig.textTrial,
      url: projectDetail.profile.templateConfig.url,
      urlRedirect: projectDetail.profile.templateConfig.urlRedirect,
      background: projectDetail.profile.templateConfig.background,
      bgColor: projectDetail.profile.templateConfig.bgColor,
      txtColor: projectDetail.profile.templateConfig.txtColor,
      projectId: projectDetail.profile.templateConfig.projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  return (
    <div className="setting-project-config">
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body border-top p-9">
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Text trial login</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.textTrial && "border-danger"}`}
                    placeholder="Text trial login"
                    {...register("textTrial", {
                      required: "Vui lòng nhập Text trial login!",
                    })}
                    onBlur={() => setValue("textTrial", formValuesState.textTrial?.trim())}
                  />
                  {errors.textTrial && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.textTrial.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Banner</label>
            <div className="col-lg-8">
              <div
                className={`image-input image-input-outline ${
                  formValuesState.background === "" ? "image-input-empty" : ""
                }`}
                data-kt-image-input="true"
                style={{
                  background: "url(/assets/icons/landscape.svg) no-repeat center center / contain",
                }}
              >
                <div className="image-input-wrapper w-125px h-125px">
                  <ImagePreview src={formValuesState.background} className="w-100 h-100 object-fit-contain" />
                </div>
                <label
                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="change"
                >
                  <i className="fa-solid fa-pencil"></i>
                  <input type="file" input-name="background" accept=".png, .jpg, .jpeg" onChange={handleUploadFile} />
                </label>
              </div>
              <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Link banner</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.url && "border-danger"}`}
                    placeholder="Link banner"
                    {...register("url", {
                      pattern: {
                        value: regex.urlAllowEmpty,
                        message: "Link banner không hợp lệ!",
                      },
                    })}
                    onBlur={() => setValue("url", formValuesState.url?.trim())}
                  />
                  <div className="form-text">URL được khi người dùng bấm vào banner.</div>
                  {errors.url && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.url.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Link redirect</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.urlRedirect && "border-danger"}`}
                    placeholder="Link redirect"
                    {...register("urlRedirect", {
                      required: "Vui lòng nhập Link redirect!",
                      pattern: {
                        value: regex.url,
                        message: "Link redirect không hợp lệ!",
                      },
                    })}
                    onBlur={() => setValue("urlRedirect", formValuesState.urlRedirect?.trim())}
                  />
                  <div className="form-text">URL được mở sau khi người dùng đăng nhập wifi thành công.</div>
                  {errors.urlRedirect && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.urlRedirect.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Background color</label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <ColorPicker
                    showText
                    value={formValuesState.bgColor}
                    onChange={(value) => setValue("bgColor", value.toHexString(), { shouldDirty: true })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Text color</label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <ColorPicker
                    showText
                    value={formValuesState.txtColor}
                    onChange={(value) => setValue("txtColor", value.toHexString(), { shouldDirty: true })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
            className="btn btn-light btn-active-light-primary me-2"
            onClick={() => reset()}
            type="reset"
            disabled={!isDirty}
          >
            Huỷ
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isDirty}>
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(SettingProjectConfig, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
