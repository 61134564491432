import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/loadingSlice";
import { handleCheckAxiosError } from "../utils";
import { setAccount } from "../redux/slices/accountSlice";
import { customerAPI } from "../services";
import { CustomerType } from "../types";
import { handleMessage } from "../components/commons/Message";
import { Empty, Modal } from "antd";
import CreateCustomerForm from "../components/dashboard/CreateCustomerForm";
import SettingCustomer from "../components/dashboard/SettingCustomer";

const CustomerPage: React.FC = () => {
  const dispatch = useDispatch();
  const [customerList, setCustomerList] = useState<CustomerType[]>([]);
  const [customerListFilter, setCustomerListFilter] = useState<CustomerType[]>([]);
  const [isShowCreateCustomerForm, setIsShowCreateCustomerForm] = useState(false);
  const [deletedCustomer, setDeletedCustomer] = useState<CustomerType | null>(null);
  const [updatedCustomer, setUpdatedCustomer] = useState<CustomerType | null>(null);

  const handleInputSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    const newProjectListFilter = customerList.filter(
      (item) =>
        `${item.id}`.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value) ||
        item.code?.toLowerCase().includes(value) ||
        item.phone.toLowerCase().includes(value) ||
        item.fullName.toLowerCase().includes(value)
    );
    setCustomerListFilter(newProjectListFilter);
  };

  const handleGetAllCustomers = async () => {
    try {
      dispatch(setLoading(true));
      const resultGetAllCustomerApi = await customerAPI.getCustomers();
      if (resultGetAllCustomerApi.data.result) {
        setCustomerList(resultGetAllCustomerApi.data.data);
      } else {
        handleMessage("error", resultGetAllCustomerApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteCustomer = async () => {
    try {
      if (deletedCustomer) {
        dispatch(setLoading(true));
        const resultDeleteCustomerApi = await customerAPI.deleteCustomer(deletedCustomer);
        if (resultDeleteCustomerApi.data.result) {
          handleMessage("success", resultDeleteCustomerApi.data.msg);
          setCustomerList((prev) => prev.filter((item) => item.id !== deletedCustomer.id));
        } else {
          handleMessage("error", resultDeleteCustomerApi.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      setDeletedCustomer(null);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    setCustomerListFilter(customerList);
    setIsShowCreateCustomerForm(false);
    setUpdatedCustomer(null);
    const inputSearchEle: HTMLInputElement | null = document.querySelector(
      "#customer-page__search-input"
    );
    if (inputSearchEle) {
      inputSearchEle.value = "";
    }
  }, [customerList]);

  useEffect(() => {
    handleGetAllCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customer-page">
      <div className="card card-flush h-xl-100">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">Danh sách khách hàng</span>
            <span className="text-gray-500 mt-1 fw-semibold fs-6">
              {customerListFilter.length} khách hàng
            </span>
          </h3>
          <div className="card-toolbar">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setIsShowCreateCustomerForm(true)}
                >
                  Thêm
                </button>
              </div>
              <div className="position-relative my-1">
                <img
                  src="/assets/icons/search.svg"
                  alt=""
                  className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
                />
                <input
                  type="text"
                  data-kt-table-widget-4="search"
                  className="form-control form-control-sm w-125px fs-7 ps-12"
                  id="customer-page__search-input"
                  placeholder="Search"
                  onInput={handleInputSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-2">
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer">
                <thead>
                  <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-100px sorting_disabled">Id</th>
                    <th className="min-w-200px sorting_disabled">Tên</th>
                    <th className="text-start min-w-125px sorting_disabled">Số điện thoại</th>
                    <th className="text-center min-w-125px sorting_disabled">Email</th>
                    <th className="text-center min-w-100px sorting_disabled">Level</th>
                    <th className="text-center min-w-100px sorting_disabled">Dự án</th>
                    <th className="text-end min-w-100px sorting_disabled"></th>
                  </tr>
                </thead>
                <tbody className="fw-bold text-gray-600">
                  {customerListFilter.length === 0 ? (
                    <tr>
                      <td colSpan={7}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    customerListFilter.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <span className="text-gray-800 text-hover-primary">#{item.id}</span>
                          </td>
                          <td className="text-start">{item.fullName}</td>
                          <td className="text-start">{item.phone}</td>
                          <td className="text-center">{item.email}</td>
                          <td className="text-center">Level {item.level}</td>
                          <td className={`text-center ${item.code ? "" : "text-warning"}`}>
                            {item.code || "Chưa có dự án"}
                          </td>
                          <td className="text-end">
                            <div className="d-flex justify-content-end">
                              <button
                                className="btn btn-icon btn-primary me-2"
                                onClick={() => setUpdatedCustomer(item)}
                              >
                                <i className="fa-regular fa-pen-to-square fs-2"></i>
                              </button>
                              <button
                                className="btn btn-icon btn-danger"
                                onClick={() => setDeletedCustomer(item)}
                              >
                                <i className="fa-regular fa-trash-can fs-2"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isShowCreateCustomerForm || !!deletedCustomer || !!updatedCustomer}
        maskClosable={false}
        onCancel={() => {
          setIsShowCreateCustomerForm(false);
          setDeletedCustomer(null);
          setUpdatedCustomer(null);
        }}
        footer={null}
        destroyOnClose
        style={{ top: 10 }}
      >
        {isShowCreateCustomerForm ? (
          <CreateCustomerForm
            onSuccess={(newCustomer: CustomerType) => {
              setCustomerList((prev) => [...prev, newCustomer]);
            }}
          />
        ) : updatedCustomer ? (
          <SettingCustomer
            onSuccess={(updatedCustomer: CustomerType) => {
              setCustomerList((prev) =>
                prev.map((item) => {
                  if (item.id === updatedCustomer.id) {
                    item = updatedCustomer;
                  }
                  return item;
                })
              );
            }}
            updatedCustomer={updatedCustomer}
          />
        ) : (
          <div className="py-20">
            <div className="text-center mb-10">
              <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
            </div>
            <div className="text-center mb-10">
              Bạn có chắc chắn muốn xoá khách hàng{" "}
              <span className="fw-bolder">{deletedCustomer?.email}</span> ?
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-light-primary mx-2"
                onClick={() => setDeletedCustomer(null)}
              >
                Huỷ
              </button>
              <button
                type="button"
                className="btn btn-light-danger mx-2"
                onClick={handleDeleteCustomer}
              >
                Tôi chắc chắn
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default withErrorBoundary(CustomerPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
