import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAccountRolesSelector } from "../redux/selectors";
import { Navigate, useLocation } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";

interface Props {
  children: any;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const accountRoles = useSelector(getAccountRolesSelector);
  const canAccess = accountRoles ? accountRoles.some((item) => pathname.startsWith(item)) : null;
  useEffect(() => {}, [accountRoles]);
  if (canAccess === true || canAccess === null) return <Fragment>{children}</Fragment>;
  return <Navigate to="/dashboard" />;
};

export default withErrorBoundary(ProtectedRoute, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
