import axiosInstance from "../axiosInstance";

const loginReportAPI = {
  getLoginReportDetail: (projectId: string | null, startDate: number, endDate: number) => {
    return axiosInstance.post("/project/loginReport", {
      projectId,
      startDate,
      endDate,
    });
  },
};

export default loginReportAPI;
