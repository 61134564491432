import { RootState } from "../store";

export const getModalChangePasswordSelector = (state: RootState) => state.app.isShowModalChangePassword;
export const getIsLoadingSelector = (state: RootState) => state.loading.status;
export const getAccountSelector = (state: RootState) => state.account.data;
export const getIsFirstTimeGetAccountInfoSelector = (state: RootState) => state.account.isFirstTimeGetAccountInfo;
export const getMenuSelector = (state: RootState) => state.menu.data;
export const getAccountRolesSelector = (state: RootState) => state.menu.accountRoles;
export const getIntroductionSelector = (state: RootState) => state.introduction.data;
export const getIsRegistrationOpenSelector = (state: RootState) => state.introduction.data?.registrationOpen || false;
export const getIsIntroductionOpenSelector = (state: RootState) => state.introduction.data?.introductionOpen || false;
export const getIsGetIntroductionSelector = (state: RootState) => state.introduction.isGetIntroductionData;
export const getProjectListSelector = (state: RootState) => state.projectList.data;
