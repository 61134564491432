import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Image } from "antd";

interface Props {
  src: string;
  className?: string;
}

const ImagePreview: React.FC<Props> = ({ src, className }) => {
  const [isError, setIsError] = useState(src ? (src !== "" ? false : true) : true);
  const handleLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.setAttribute("src", "/assets/images/empty-image.png");
    setIsError(true);
  };

  return (
    <>
      <Image
        src={src !== "" ? src : "/assets/images/empty-image.png"}
        preview={!isError}
        className={className || ""}
        loading="lazy"
        onError={handleLoadError}
      />
    </>
  );
};

export default withErrorBoundary(ImagePreview, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
