const convertUnixTimeToTime = (unixTime: string | number) => {
  let unixMilisecond = +unixTime / 1000000000000 < 1 ? +unixTime * 1000 : +unixTime;
  let a = new Date(unixMilisecond);
  // get year
  let year: number | string = a.getFullYear();
  // get month
  let month: number | string = a.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  // get date
  let date: number | string = a.getDate();
  if (date < 10) {
    date = "0" + date;
  }
  // get hour
  let hour: number | string = a.getHours();
  if (hour < 10) {
    hour = "0" + hour;
  }
  // get min
  let min: number | string = a.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  return `${hour}:${min} ${date}/${month}/${year}`;
};

export default convertUnixTimeToTime;
