import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const LocationsIntroduction: React.FC = () => {
  const introduction = useSelector(getIntroductionSelector);
  return (
    <div className="locations-introduction pt-15" id="Locations">
      <div className="container-sm pb-10">
        <div className="locations-introduction__header">
          <h2 className="fs-2hx text-center fw-bold pt-0 pt-md-5">The Power of Locations</h2>
          <p className="fs-sm-4">
            Địa điểm cho phép các nhãn hàng suy đoán và thu thập được nhiều thông tin hữu ích về
            người dùng như hành vi tiêu dùng, sở thích, thói quen và khả năng tiếp nhận quảng cáo.
          </p>
        </div>
        <div className="locations-introduction__list row mt-1 gx-4 gy-1">
          {introduction?.locations.map((item, index) => {
            return (
              <div key={index} className="col-12 col-md-6 col-lg-3">
                <div className="locations-introduction__list__item">
                  <img
                    src={item.image}
                    alt={`${item.name}`}
                    height={255}
                    className="d-block mx-auto mb-2 object-fit-cover w-100"
                  />
                  <p className="text-center fs-5 fw-bold mb-4">{item.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(LocationsIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
