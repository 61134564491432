import { createSlice } from "@reduxjs/toolkit";
import { AccountType } from "../../types";

export interface AccountState {
  data: AccountType | null;
  isFirstTimeGetAccountInfo: boolean;
}

const initialState: AccountState = { data: null, isFirstTimeGetAccountInfo: false };

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action: { payload: AccountType | null }) => {
      state.data = action.payload;
    },
    setIsFirstTimeGetAccountInfo: (state) => {
      state.isFirstTimeGetAccountInfo = true;
    },
  },
});

export const { setAccount, setIsFirstTimeGetAccountInfo } = accountSlice.actions;

export default accountSlice.reducer;
