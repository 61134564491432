import { DeviceEntType } from "../../types";

const generateDeviceEntInit = (deviceId: string) => {
  const deviceEntInit: DeviceEntType = {
    userId: 0,
    deviceName: "WEB_APP",
    deviceId: deviceId,
    os: "WEB",
    osVersion: "",
    appVersion: process.env.REACT_APP_APP_VERSION,
    network: "WIFI",
    type: "LAPTOP",
    status: "",
    createdAt: 0,
    updatedAt: 0,
    deletedAt: 0,
    expiredAt: 0,
  };
  return deviceEntInit;
};

export default generateDeviceEntInit;
