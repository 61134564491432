import { AdminType, CreatedAdminType } from "../../types";
import axiosInstance from "../axiosInstance";

const adminAPI = {
  // GET ALL ADMIN LIST
  getAllAdmin: () => {
    return axiosInstance.post("/project/admins");
  },
  // DELETE ADMIN
  deleteAdmin: (deletedAdmin: AdminType) => {
    return axiosInstance.post("/project/admins/delete", {
      ...deletedAdmin,
    });
  },
  // CREATE ADMIN
  createAdmin: (newAdmin: CreatedAdminType) => {
    return axiosInstance.post("/project/admins/register", {
      ...newAdmin,
    });
  },
  // UPDATE ADMIN
  updateAdmin: (updatedAdmin: AdminType) => {
    return axiosInstance.post("/project/admins/edit", {
      ...updatedAdmin,
    });
  },
};

export default adminAPI;
