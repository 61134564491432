import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";

const data = [
  {
    image: "https://cdn-icons-png.flaticon.com/512/4238/4238444.png",
    name: "Interactive Banner",
  },
  {
    image: "https://cdn-icons-png.flaticon.com/512/73/73581.png",
    name: "Data Collection",
  },
  {
    image: "https://cdn-icons-png.flaticon.com/512/3193/3193015.png",
    name: "Text Matching",
  },
  {
    image: "https://cdn-icons-png.flaticon.com/512/2991/2991195.png",
    name: "Video",
  },
  {
    image: "https://cdn-icons-png.flaticon.com/512/686/686589.png",
    name: "Games",
  },
  {
    image: "https://cdn-icons-png.flaticon.com/512/10348/10348994.png",
    name: "Other Formats",
  },
];

const FormatsIntroduction: React.FC = () => {
  return (
    <div className="formats-introduction py-15" id="Formats">
      <div className="container-sm">
        <div className="formats-introduction__header">
          <h2 className="fs-2hx text-center fw-bold pt-0 pt-md-5">Advertising Formats</h2>
          <p className="fs-sm-4">
            Với khả năng tùy biến cao, nền tảng ACM cho phép các nhãn hàng linh hoạt hơn trong việc
            lựa chọn các hình thức quảng cáo sáng tạo khác biệt để tăng tương tác với người dùng
          </p>
        </div>
        <div className="formats-introduction__list row gy-4 mt-1">
          {data.map((item, index) => {
            return (
              <div key={index} className="col-12 col-md-6 col-lg-4">
                <div className="formats-introduction__list__item bg-white py-15">
                  <img
                    src={item.image}
                    alt={`${item.name}-icon`}
                    className="img-wimesh mx-auto d-block mb-5"
                    width={50}
                  />
                  <p className="text-center fs-sm-4 fw-bold mb-2">{item.name}</p>
                  <button className="btn btn-link text-primary d-block mx-auto text-decoration-underline">
                    Chi tiết
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(FormatsIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
