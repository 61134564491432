import React, { Fragment, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectCustomerType } from "../../../types";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { setAccount } from "../../../redux/slices/accountSlice";
import { handleCheckAxiosError } from "../../../utils";
import { Empty } from "antd";

interface Props {
  projectId: string;
  projectCode: string;
  onCloseForm: () => void;
  onAddCustomerSuccess: (customerList: ProjectCustomerType[]) => void;
}

const AddProjectCustomerForm: React.FC<Props> = ({ projectId, projectCode, onCloseForm, onAddCustomerSuccess }) => {
  const dispatch = useDispatch();
  const [customerNotProject, setCustomerNotProject] = useState<ProjectCustomerType[]>([]);
  const [customerNotProjectFilter, setCustomerNotProjectFilter] = useState<ProjectCustomerType[]>([]);

  const handleFilterCustomerNotProject = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    const newCustomerNotProjectFilter = customerNotProject.filter(
      (item) =>
        `${item.id}`.toLowerCase().includes(value) ||
        item.fullName.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value) ||
        item.phone.toLowerCase().includes(value)
    );
    setCustomerNotProjectFilter(newCustomerNotProjectFilter);
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      // Get customer id are selected
      const checkboxElement: NodeListOf<HTMLInputElement> = document.querySelectorAll(
        ".add-project-customer-form input[name='add-customer']"
      );
      const payload: string[] = [];
      checkboxElement.forEach((item) => {
        if (item.checked) {
          payload.push(item.value);
        }
      });
      if (payload.length === 0) return;
      // Request API
      const resultAddCustomerToProjectApi = await projectAPI.addCustomerToProject(projectId, payload);
      if (resultAddCustomerToProjectApi.data.result) {
        handleMessage("success", resultAddCustomerToProjectApi.data.msg);
        // set new customer list in project
        const newCustomerList: ProjectCustomerType[] = [];
        customerNotProject.forEach((item) => {
          if (payload.includes(item.id)) {
            newCustomerList.push(item);
          }
        });
        onAddCustomerSuccess(newCustomerList);
      } else {
        handleMessage("error", resultAddCustomerToProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      onCloseForm();
    }
  };

  const handleGetCustomerNotProject = async () => {
    try {
      dispatch(setLoading(true));
      const resultGetCusomerNotInAnyProjectApi = await projectAPI.getCustomerListNotInAnyProject();
      if (resultGetCusomerNotInAnyProjectApi.data.result) {
        setCustomerNotProject(resultGetCusomerNotInAnyProjectApi.data.data);
        setCustomerNotProjectFilter(resultGetCusomerNotInAnyProjectApi.data.data);
      } else {
        handleMessage("error", resultGetCusomerNotInAnyProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    handleGetCustomerNotProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="add-project-customer-form">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-3">Thêm quản trị viên</h1>
        <div className="text-muted fw-semibold fs-5">Thêm quản trị viên vào dự án {projectCode}</div>
      </div>
      <div className="w-100 position-relative">
        <img
          src="/assets/icons/search.svg"
          alt=""
          className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
        />
        <input
          type="text"
          className="form-control form-control-lg px-15"
          placeholder="Search by phone, full name or email..."
          onInput={(e) => handleFilterCustomerNotProject(e)}
          disabled={customerNotProject.length === 0}
        />
      </div>
      <div className="py-5">
        <div className="mh-375px scroll-y me-n7 pe-7">
          {customerNotProject.length === 0 || customerNotProjectFilter.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            customerNotProjectFilter.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  <div className="rounded d-flex flex-stack bg-active-lighten px-4 py-2">
                    <div className="d-flex align-items-center">
                      <label className="form-check form-check-custom me-5">
                        <input className="form-check-input" type="checkbox" name="add-customer" value={item.id} />
                      </label>
                      <div className="ms-5">
                        <span className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">{item.email}</span>
                        <div className="fw-semibold text-muted">{item.phone}</div>
                      </div>
                    </div>
                  </div>
                  {index !== customerNotProjectFilter.length - 1 && (
                    <div className="border-bottom border-gray-300 border-bottom-dashed"></div>
                  )}
                </Fragment>
              );
            })
          )}
        </div>
      </div>
      <div className="text-end">
        <button className="btn btn-primary w-100" onClick={handleSubmit} disabled={customerNotProject.length === 0}>
          Thêm
        </button>
      </div>
    </div>
  );
};

export default withErrorBoundary(AddProjectCustomerForm, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
