import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { withErrorBoundary } from "react-error-boundary";

const handleMessage = (
  type: "info" | "success" | "warn" | "error",
  message: string
) => {
  toast[type](message, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

const Message: React.FC = () => {
  return (
    <ToastContainer
      position="top-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export { handleMessage };
export default withErrorBoundary(Message, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
