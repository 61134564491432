import { createSlice } from "@reduxjs/toolkit";
import { ProjectType } from "../../types";

export interface ProjectListState {
  data: ProjectType[];
}

const initialState: ProjectListState = { data: [] };

export const projectListSlice = createSlice({
  name: "projectList",
  initialState,
  reducers: {
    setProjectList: (state, action: { payload: ProjectType[] }) => {
      state.data = action.payload;
    },
  },
});

export const { setProjectList } = projectListSlice.actions;

export default projectListSlice.reducer;
