import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const BannerIntroduction: React.FC = () => {
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const introduction = useSelector(getIntroductionSelector);

  return (
    <>
      <div
        className="banner-introduction position-relative d-flex align-items-center"
        style={{
          background: `url(${introduction?.banner.background}) right bottom / cover`,
        }}
      >
        <div className="container-sm" style={{ zIndex: 2 }}>
          <div className="row">
            <div className="col-12 col-md-6">
              <img
                src={introduction?.banner.img}
                alt="banner-introduction"
                id="image-banner"
                className="mt-3 mt-md-0"
              />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0 text-white" id="content-banner">
              <h1 className="fw-bold mb-4 text-white fs-2qx">
                AWING, nền tảng tạo sự khác biệt cho WiFi marketing
              </h1>
              <h4 className="fw-bold text-justify mb-3 text-white">
                Nền tảng điện toán đám mây ACM (Adaptive Campaign Management) do AWING phát triển có
                thể biến đổi bất kỳ một hệ thống Wi-Fi sẵn có thành Wi-Fi marketing.
              </h4>
              <h4 className="fw-bold text-justify text-white">
                ACM giúp các hệ thống Wi-Fi có thể liên kết để tạo thành một mạng lưới quảng cáo
                rộng lớn. ACM là công cụ hiệu quả cho các nhãn hàng tiếp cận đến đúng đối tượng
                khách hàng mục tiêu mà có hành vi tiêu dùng rõ ràng, tại các địa điểm cụ thể.
              </h4>
              <button
                className="btn bg-wimesh bg-hover-wimesh text-white rounded-pill mt-4"
                onClick={() => setIsOpenVideoModal(true)}
              >
                <img
                  src="/assets/icons/play-button.svg"
                  width={25}
                  className="img-white me-2"
                  alt="play-icon"
                />
                KHÁM PHÁ THÊM
              </button>
              <br />
              <Link
                to="/#Contact"
                className="btn bg-wimesh bg-hover-wimesh text-white rounded-pill mt-3 mb-4"
              >
                <img
                  src="/assets/icons/phone-circle.svg"
                  width={25}
                  className="img-white me-2"
                  alt="phone-icon"
                />
                LIÊN HỆ
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={`banner-introduction--modal ${isOpenVideoModal && "open"}`}>
        <div
          className="banner-introduction--modal__overlay"
          onClick={() => setIsOpenVideoModal(false)}
        ></div>
        <div className="banner-introduction--modal__inner">
          <div className="banner-introduction--modal__inner__content">
            {isOpenVideoModal && (
              <iframe
                title="video"
                className="w-100"
                src="https://www.youtube.com/embed/iHg7NfcrZgE?autoplay=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withErrorBoundary(BannerIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
