import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import { withErrorBoundary } from "react-error-boundary";
import { handleMessage } from "../../components/commons/Message";
import { useDispatch } from "react-redux";
import { projectAPI } from "../../services";
import { setLoading } from "../../redux/slices/loadingSlice";
import { ProjectType } from "../../types";
import { Link } from "react-router-dom";
import "../scss/project-page.scss";
import { setAccount } from "../../redux/slices/accountSlice";
import { handleCheckAxiosError } from "../../utils";
import ImagePreview from "../../components/commons/ImagePreview";

const LoginReportPage: React.FC = () => {
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState<ProjectType[]>([]);
  const [projectListFilter, setProjectListFilter] = useState<ProjectType[]>([]);

  const handleGetProjectList = async () => {
    try {
      dispatch(setLoading(true));
      const resultProjectApi = await projectAPI.getProjectList();
      if (resultProjectApi.data.result) {
        setProjectList(resultProjectApi.data.data);
        setProjectListFilter(resultProjectApi.data.data);
      } else {
        console.log(resultProjectApi.data);
        handleMessage("error", resultProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleInputSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    const newProjectListFilter = projectList.filter(
      (item) =>
        `${item.id}`.toLowerCase().includes(value) ||
        item.name.toLowerCase().includes(value) ||
        item.code.toLowerCase().includes(value)
    );
    setProjectListFilter(newProjectListFilter);
  };

  useEffect(() => {
    handleGetProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-page">
      {/* <h1 className="page-heading d-flex text-gray-900 fw-bold fs-5 flex-column justify-content-center mb-5">
        Project Dashboard
      </h1> */}
      <div className="card card-flush h-xl-100">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">Thống kê đăng nhập</span>
            <span className="text-gray-500 mt-1 fw-semibold fs-6">{projectListFilter.length} dự án</span>
          </h3>
          <div className="card-toolbar">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <div className="position-relative my-1">
                <img
                  src="/assets/icons/search.svg"
                  alt=""
                  className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
                />
                <input
                  type="text"
                  id="project-page__search-input"
                  data-kt-table-widget-4="search"
                  className="form-control form-control-sm w-125px fs-7 ps-12"
                  placeholder="Search"
                  onInput={(e) => handleInputSearch(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-2">
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer">
                <thead>
                  <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-100px sorting_disabled">Code</th>
                    <th className="text-start min-w-200px sorting_disabled">Tên dự án</th>
                    <th className="text-center w-50px sorting_disabled">Logo</th>
                    <th className="text-start min-w-100px sorting_disabled">Tên miền</th>
                    <th className="text-end min-w-100px sorting_disabled"></th>
                  </tr>
                </thead>
                <tbody className="fw-bold text-gray-600">
                  {projectList.length === 0 || projectListFilter.length === 0 ? (
                    <tr>
                      <td colSpan={7}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    projectListFilter.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={`/dashboard/loginReport/list/${item.code}`}
                              className="text-gray-800 text-hover-primary"
                            >
                              {item.code}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/dashboard/loginReport/list/${item.code}`}
                              className="text-gray-800 text-hover-primary"
                            >
                              {item.name}
                            </Link>
                          </td>
                          <td className="text-center">
                            <ImagePreview src={item.logo} className="img-avatar-player rounded w-50px h-50px" />
                          </td>
                          <td className="text-start">{item.domain}</td>
                          <td className="text-end">
                            <div className="d-flex justify-content-end">
                              <Link
                                to={`/dashboard/loginReport/list/${item.code}`}
                                className="btn btn-icon btn-primary me-2"
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(LoginReportPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
