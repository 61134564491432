import dayjs from "dayjs";
import React, { useEffect } from "react";
import "./index.scss";

// CDN imported in public/index.html

interface Props {
  onChange?: (start: number, end: number) => void;
  className?: string;
  name: string;
  startDate?: string;
  endDate?: string;
}

const DateRangePicker: React.FC<Props> = ({ className, name, startDate, endDate, onChange }) => {
  const now = dayjs();

  useEffect(() => {
    $(`input[name="date-range-picker-${name}"]`).daterangepicker(
      {
        timePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 30,
        autoApply: true,
        opens: "center",
        startDate: startDate || now.startOf("month").format("DD/MM/YYYY HH:mm"),
        endDate:
          endDate ||
          now
            .month(now.month() + 1)
            .startOf("month")
            .format("DD/MM/YYYY HH:mm"),
        locale: {
          format: "DD/MM/YYYY HH:mm",
        },
      },
      function (start, end) {
        onChange && onChange(start.unix(), end.unix());
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, name, onChange, startDate]);
  return (
    <div className="date-range-picker">
      <input type="text" name={`date-range-picker-${name}`} className={className || ""} />
    </div>
  );
};

export default DateRangePicker;
