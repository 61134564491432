import React, { useEffect, useState } from "react";
import "./index.scss";
import { withErrorBoundary } from "react-error-boundary";
import { Button } from "antd";
import { Statistic } from "antd";

interface Props {
  numberInput: number;
  onSubmit: (value: string | null) => void;
  onResend: () => void;
  deadline: number;
}

const OTPInput: React.FC<Props> = ({ numberInput, onSubmit, onResend, deadline }) => {
  const [isDisableResend, setIsDisableResend] = useState<boolean>(true);
  const [otpValue, setOtpValue] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const clip = e.clipboardData.getData("text").trim();
    let regex = /^\d$/;
    if (!regex.test(clip) && clip.length !== numberInput) {
      e.preventDefault();
      return;
    }

    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".otp-input input");
    const characters = clip.split("");
    inputs.forEach(function (otpInput, i) {
      otpInput.value = characters[i] || "";
    });

    inputs[5].focus();

    setOtpValue(clip);
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".otp-input input");
    const currentInput = e.target as HTMLInputElement;
    const currentIndex = Number(currentInput.name.replace("otp-input-", ""));
    const inputValue = currentInput.value.trim();

    if (!/^\d$/.test(inputValue)) {
      currentInput.value = "";
      return;
    }

    if (inputValue && currentIndex < inputs.length - 1) {
      inputs[currentIndex + 1].focus();
    }

    let otp = "";
    inputs.forEach((input) => {
      otp += input.value.trim();
    });
    otp.length === numberInput ? setOtpValue(otp) : setOtpValue(null);
    !isValid && setIsValid(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".otp-input input");
    const currentInput = e.target as HTMLInputElement;
    const currentIndex = Number(currentInput.name.replace("otp-input-", ""));
    const inputValue = currentInput.value.trim();

    if (e.key === "Backspace") {
      setOtpValue(null);
      !isValid && setIsValid(true);
    }

    if (!inputValue && e.key === "Backspace" && currentIndex > 0) {
      inputs[currentIndex - 1].focus();
    }
  };

  const handleSubmit = () => {
    if (!otpValue) {
      setIsValid(false);
    }
    onSubmit(otpValue);
  };

  useEffect(() => {
    setIsDisableResend(true);
  }, [deadline]);

  return (
    <div className="otp-input">
      <div className="otp-input__form position-relative">
        {[...Array(numberInput).keys()].map((item) => {
          return (
            <input
              key={item}
              type="text"
              name={`otp-input-${item}`}
              pattern="\d"
              maxLength={1}
              onPaste={(e) => handlePaste(e)}
              onInput={(e) => handleInput(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              className={!isValid ? "invalid" : ""}
            />
          );
        })}
        {!isValid && (
          <p className="error-message mb-0 position-absolute bottom-0 text-danger">
            OTP không hợp lệ
          </p>
        )}
      </div>
      <Button
        type="primary"
        className="mx-auto bg-wimesh bg-hover-wimesh d-block"
        onClick={handleSubmit}
      >
        Xác nhận
      </Button>
      <div className="mb-0 fs-6 mt-3 d-flex justify-content-center align-items-center">
        <span className="d-block me-1">Bạn chưa nhận được OTP?</span>
        <button onClick={onResend} className="btn btn-link py-0" disabled={isDisableResend}>
          Gửi lại
        </button>
        <span id="otp-countdown" className={isDisableResend ? "d-inline-block" : "d-none"}>
          &nbsp;trong&nbsp;
          {isDisableResend && (
            <Statistic.Countdown
              format="ss"
              value={deadline}
              onFinish={() => setIsDisableResend(false)}
              className="d-inline-block fs-6"
              valueStyle={{ fontSize: "1rem" }}
            />
          )}
          s
        </span>
      </div>
    </div>
  );
};

export default withErrorBoundary(OTPInput, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
