import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/appSlice";
import loadingReducer from "./slices/loadingSlice";
import accountReducer from "./slices/accountSlice";
import menuReducer from "./slices/menuSlice";
import introductionReducer from "./slices/introductionSlice";
import projectListReducer from "./slices/projectListSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    loading: loadingReducer,
    account: accountReducer,
    menu: menuReducer,
    introduction: introductionReducer,
    projectList: projectListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
