import axiosInstance from "../axiosInstance";

interface ContactFormData {
  name: string;
  email: string;
  title: string;
  phone: string;
  content: string;
  formality: string;
}

const introductionAPI = {
  getIntroduction: () => {
    return axiosInstance.post("/introduction");
  },
  contactForm: (data: ContactFormData) => {
    return axiosInstance.post("/contact", {
      ...data,
    });
  },
};

export default introductionAPI;
