import { NotificationType, UserType } from "../../../../types";
import { ConfirmationDataType } from "../ConfirmSettingUser";

export interface SettingProjectUserState {
  userList: UserType[];
  userListFilter: UserType[];
  action: "ACTION_BY_FILE" | "CREATE_USER" | "UPDATE_USER" | "CONFIRMATION" | null;
  notificationList: NotificationType[];
  selectedEmailList: string[];
  confirmationData: ConfirmationDataType | null;
  updatedUser: UserType | null;
}

export const initialSettingProjectUserState: SettingProjectUserState = {
  userList: [],
  userListFilter: [],
  action: null,
  notificationList: [],
  selectedEmailList: [],
  confirmationData: null,
  updatedUser: null,
};
