import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const AboutIntroduction: React.FC = () => {
  const introduction = useSelector(getIntroductionSelector);
  return (
    <div className="about-introduction bg-white">
      <div className="container-sm">
        <div className="row gx-4">
          <div className="col-12 col-lg-6">
            <div className="pt-0 pt-md-15 mt-10">
              <h2 className="h1 fw-bold mb-5 mb-md-10 fs-2hx">
                Wi-Fi Marketing, nhãn hàng tài trợ Free Internet
              </h2>
              <div className="about-introduction__quotes p-5 border-start border-4 border-wimesh mb-6 fs-4 text-justify lh-base">
                Wi-Fi là nhu cầu cơ bản cần có trong tất cả các hoạt động thường ngày từ vui chơi
                giải trí, mua sắm, khi đi nhà hàng, café, và cả khi di chuyển trên các phương tiện
                công cộng.
              </div>
              <p className="mb-6 fs-4 text-justify">
                Nền tảng ACM của AWING cho phép nhãn hàng tài trợ Wi-Fi miễn phí, đổi lại người dùng
                sẽ tương tác với quảng cáo.
              </p>
              <p className="mb-6 fs-4 text-justify">
                Quảng cáo được hiển thị độc quyền dưới dạng tài trợ giúp nhãn hàng tạo thiện cảm với
                người dùng, từ đó tăng khả năng tương tác và ghi nhớ về thông điệp/sản phẩm nhãn
                hàng muốn truyền tải.
              </p>
              <button className="btn bg-wimesh bg-hover-wimesh text-uppercase rounded-pill text-white mb-5">
                <img
                  src="/assets/icons/info.svg"
                  alt="info-icon"
                  width={20}
                  className="img-white me-2"
                />
                Tìm hiểu thêm
              </button>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="about-introduction__image bg-white p-lg-10 p-0 pt-5 h-100">
              <img
                src={introduction?.about.img}
                alt="people-use-free-internet"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="about-introduction__image p-lg-5 p-0 pt-10 bg-white h-100 d-lg-none">
        <img
          src={introduction?.about.img}
          alt="people-use-free-internet"
          className="w-100 h-100 object-fit-cover"
        />
      </div>
    </div>
  );
};

export default withErrorBoundary(AboutIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
