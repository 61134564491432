import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { getAccountSelector } from "../../../redux/selectors";
import { handleMessage } from "../../commons/Message";
import { accountAPI } from "../../../services";
import { setAccount } from "../../../redux/slices/accountSlice";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setMenu } from "../../../redux/slices/menuSlice";

const AccountTopBarIntroduction: React.FC = () => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountSelector);

  const handleLogout = async () => {
    try {
      dispatch(setLoading(true));
      const resultLogoutApi = await accountAPI.logout();
      if (resultLogoutApi.data.result) {
        dispatch(setAccount(null));
        handleMessage("success", resultLogoutApi.data.msg);
        dispatch(setMenu([]));
      } else {
        handleMessage("error", resultLogoutApi.data.msg);
      }
    } catch (error) {
      console.log("Lỗi đăng xuất: ", error);
      handleMessage("error", "Lỗi hệ thống. Vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      {account && (
        <div className="account-topbar-introduction rounded">
          <p className="p-4 fs-5 mb-0 border-bottom fw-bold text-wimesh">{account.email}</p>
          <Link
            to="/dashboard"
            className="d-flex align-items-center text-decoration-none p-4 fs-5 text-dark"
          >
            <img
              src="/assets/icons/dashboard.svg"
              width={20}
              className="me-2"
              alt="dashboard icon"
            />
            Dashboard
          </Link>
          {/* <Link
            to="/setting"
            className="d-flex align-items-center text-decoration-none p-4 fs-5 text-dark"
          >
            <img src="/assets/icons/setting.svg" width={20} className="me-2" alt="dashboard icon" />
            Settings
          </Link> */}
          <div className="p-4 border-top">
            <button className="border-0 px-4 py-2 rounded fs-5" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default withErrorBoundary(AccountTopBarIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
