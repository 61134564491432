import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./scss/login-page.scss";
import { Link } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/loadingSlice";
import { accountAPI } from "../services";
import { handleMessage } from "../components/commons/Message";
import { TokenEntType } from "../types";
import { namekey, regex } from "../utils";
import { setAccount } from "../redux/slices/accountSlice";

interface FormValuesType {
  account: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValuesType>();
  const formValuesState = watch();
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<FormValuesType> = async (data) => {
    try {
      dispatch(setLoading(true));
      const { account, password } = data;
      // Request login
      const resultAccountLoginApi = await accountAPI.login(account, password);
      if (resultAccountLoginApi.data.result) {
        const newToken: TokenEntType = resultAccountLoginApi.data.data;
        localStorage.setItem(namekey.token, JSON.stringify(newToken));
        // * Login successfully, request account info
        const resultAccountInfoApi = await accountAPI.getInfo();
        if (resultAccountInfoApi.data.result) {
          dispatch(setAccount(resultAccountInfoApi.data.data));
          handleMessage("success", resultAccountLoginApi.data.msg);
        } else {
          handleMessage("error", resultAccountInfoApi.data.msg);
        }
      } else {
        handleMessage("error", resultAccountLoginApi.data.msg);
      }
    } catch (error) {
      console.log("Lỗi đăng nhập: ", error);
      handleMessage("error", "Lỗi hệ thống vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="login-page px-5 mb-5 pb-5">
      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0 fs-2 fw-light text-capitalize">Đăng nhập</p>
        <div className="login-page__social-media d-flex">
          <a href="#facebook" className="d-flex align-items-center justify-content-center ms-2">
            <img src="/assets/icons/facebook.svg" alt="facebook icon" width={20} />
          </a>
          <a href="#google" className="d-flex align-items-center justify-content-center ms-2">
            <img src="/assets/icons/google.svg" alt="google icon" width={15} />
          </a>
        </div>
      </div>
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-4 position-relative">
          <label className="text-uppercase mt-2 fw-bold">Số điện thoại / Email</label>
          <input
            type="text"
            placeholder="Số điện thoại / Email"
            className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
              errors.account && "border-danger"
            }`}
            {...register("account", {
              required: "Vui lòng nhập Số điện thoại / Email!",
              pattern: {
                value: regex.emailOrPassword,
                message: "Số điện thoại hoặc Email không hợp lệ!",
              },
            })}
            onBlur={() => setValue("account", formValuesState.account?.trim())}
          />
          {errors.account && (
            <p className="error-message mb-0 text-danger px-4">{errors.account.message}</p>
          )}
        </div>
        <div className="mb-4 position-relative">
          <label className="text-uppercase mt-2 fw-bold">Mật khẩu</label>
          <input
            type="password"
            placeholder="Mật khẩu"
            className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
              errors.password && "border-danger"
            }`}
            {...register("password", {
              required: "Vui lòng nhập Mật khẩu!",
            })}
          />
          {errors.password && (
            <p className="error-message mb-0 text-danger px-4">{errors.password.message}</p>
          )}
        </div>
        <button className="btn text-white fs-5 text-uppercase bg-wimesh bg-hover-wimesh rounded-pill w-100 mt-3">
          Đăng nhập
        </button>
        <div className="mt-3 text-end">
          <Link to="/forgot-password" className="text-decoration-none text-capitalize">
            Quên mật khẩu?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(LoginPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
