import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import MenuIntroduction from "../MenuIntroduction";
import AccountTopbarIntroduction from "../AccountTopbarIntroduction";
import "./index.scss";
import { useSelector } from "react-redux";
import { getAccountSelector, getIntroductionSelector } from "../../../redux/selectors";

const HeaderIntroduction: React.FC = () => {
  const account = useSelector(getAccountSelector);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const introduction = useSelector(getIntroductionSelector);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 768 && isOpenDrawer) {
        setIsOpenDrawer(false);
      }
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isOpenDrawer]);

  return (
    <>
      <div className="header-introduction bg-white">
        <div className="header-introduction__container container-md d-flex justify-content-between align-items-center bg-white">
          <button
            className="border-0 bg-white d-block d-md-none"
            onClick={() => setIsOpenDrawer(true)}
          >
            <img src="/assets/icons/menu.svg" alt="menu-toggle" width={30} />
          </button>
          <div className={account ? "" : "flex-grow-1 flex-grow-md-unset text-center"}>
            <img
              src="/assets/images/logo.png"
              alt="wimesh-logo"
              height={50}
              style={account ? {} : { paddingRight: 16 }} // 16 is width toggle menu button / 2
            />
          </div>
          <div className="d-none d-md-flex align-items-end">
            <img
              src="/assets/icons/qa.svg"
              alt="Q&A icon"
              width={40}
              className="me-2 d-none d-lg-block"
            />
            <div className="d-none d-md-block">
              <p className="mb-0">
                <small>Hỏi đáp</small>
              </p>
              <p className="mb-0 fw-bold text-wimesh">Tư vấn trực tuyến</p>
            </div>
          </div>
          <div className="d-none d-md-flex align-items-end">
            <img
              src="/assets/icons/hotline.svg"
              alt="hotline icon"
              width={40}
              className="me-2 d-none d-lg-block"
            />
            <div className="d-none d-md-block">
              <p className="mb-0">
                <small>Hotline</small>
              </p>
              <a href={`tel:${introduction?.hotline}`} className="mb-0 fw-bold text-wimesh">
                {introduction?.hotline}
              </a>
            </div>
          </div>
          <div className="d-none d-md-flex align-items-end">
            <img
              src="/assets/icons/email.svg"
              alt="Q&A icon"
              width={40}
              className="me-2 d-none d-lg-block"
            />
            <div className="d-none d-md-block">
              <p className="mb-0">
                <small>Email</small>
              </p>
              <a href={`mailto:${introduction?.email}`} className="mb-0 fw-bold text-wimesh">
                {introduction?.email}
              </a>
            </div>
          </div>
          {account && (
            <div className="account-topbar position-relative d-block d-md-none border rounded">
              <p className="d-flex align-items-center mb-0 p-4 text-decoration-none fs-5 pe-pointer">
                <img src="/assets/icons/user-solid.svg" alt="account-icon" width={15} />
              </p>
              <AccountTopbarIntroduction />
            </div>
          )}
        </div>
      </div>
      <Drawer
        open={isOpenDrawer}
        placement="left"
        onClose={() => setIsOpenDrawer(false)}
        headerStyle={{ display: "none" }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
        className="bg-wimesh d-block"
      >
        <button
          className="border-0 bg-wimesh d-block ms-auto me-3"
          onClick={() => setIsOpenDrawer(false)}
        >
          <img src="/assets/icons/close.svg" alt="menu-toggle" width={20} className="img-white" />
        </button>
        <MenuIntroduction onClickMenuItem={() => setIsOpenDrawer(false)} />
      </Drawer>
    </>
  );
};

export default withErrorBoundary(HeaderIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
