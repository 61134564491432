import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { LoginTypeStatusType, ProjectDetailType, ProjectProfileType } from "../../../types";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { setAccount } from "../../../redux/slices/accountSlice";
import { handleCheckAxiosError } from "../../../utils";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

type KeyLoginTypeStatusType = keyof LoginTypeStatusType;
type KeyProjectProfileSelectType = "projectStatus" | "templateLoginType";

const SettingProjectLogin: React.FC<Props> = ({ projectDetail, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({ ...projectDetail.profile });
  const [isDirty, setIsDirty] = useState(false);

  const handleCheckDirty = (newFormValues: ProjectProfileType) => {
    const { loginTypeStatus: defaultLoginTypeStatus, ...otherDefault } = projectDetail.profile;
    let resultCheck = false;
    // Check radio login type status
    resultCheck = Object.keys(newFormValues.loginTypeStatus).some((key) => {
      return (
        defaultLoginTypeStatus[key as KeyLoginTypeStatusType] !==
        newFormValues.loginTypeStatus[key as KeyLoginTypeStatusType]
      );
    });
    if (resultCheck) return true;
    // check allow domain login list
    if (otherDefault.allowDomainLoginWifi.length !== newFormValues.allowDomainLoginWifi.length) return true;
    resultCheck = newFormValues.allowDomainLoginWifi.every((item) => {
      return otherDefault.allowDomainLoginWifi.includes(item);
    });
    if (resultCheck) return true;
    // Check other fields
    resultCheck = Object.keys(otherDefault).some((key) => {
      return otherDefault[key as KeyProjectProfileSelectType] !== newFormValues[key as KeyProjectProfileSelectType];
    });
    if (resultCheck) return true;
    return false;
  };

  const handleChangeRadio = (key: KeyLoginTypeStatusType, e: React.ChangeEvent<HTMLInputElement>) => {
    const newLoginTypeStatusFormValues = { ...formValues.loginTypeStatus };
    newLoginTypeStatusFormValues[key] = e.target.checked;
    const newFormValues = { ...formValues, loginTypeStatus: newLoginTypeStatusFormValues };
    setIsDirty(handleCheckDirty(newFormValues));
    setFormValues(newFormValues);
  };

  const handleChangeSelect = (key: KeyProjectProfileSelectType, value: string) => {
    const newFormValues = { ...formValues };
    newFormValues[key] = value;
    setIsDirty(handleCheckDirty(newFormValues));
    setFormValues(newFormValues);
  };

  const handleChangeSelectMultiple = (value: string[]) => {
    const newFormValues = { ...formValues };
    newFormValues.allowDomainLoginWifi = value;
    setIsDirty(handleCheckDirty(newFormValues));
    setFormValues(newFormValues);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      const resultUpdateProfileProjectApi = await projectAPI.updateProjectProfile(formValues);
      if (resultUpdateProfileProjectApi.data.result) {
        onUpdateSuccess({ ...projectDetail, profile: resultUpdateProfileProjectApi.data.data });
        handleMessage("success", resultUpdateProfileProjectApi.data.msg);
      } else {
        handleMessage("error", resultUpdateProfileProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleReset = () => {
    setFormValues({ ...projectDetail.profile });
    setIsDirty(false);
  };

  useEffect(() => {
    // reset form
    setFormValues({ ...projectDetail.profile });
    setIsDirty(false);
  }, [projectDetail]);

  return (
    <div className="setting-project-login collapse show">
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit} noValidate>
        <div className="card-body border-top p-9">
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Loại đăng nhập</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <Select
                    className="w-100 fw-bolder"
                    value={formValues.projectStatus}
                    onChange={(value) => handleChangeSelect("projectStatus", value)}
                    options={projectDetail.profile.selectList.projectStatus}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Login template</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <Select
                    className="w-100 fw-bolder"
                    value={formValues.templateLoginType}
                    onChange={(value) => handleChangeSelect("templateLoginType", value)}
                    options={projectDetail.profile.selectList.templateLoginType}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Hình thức đăng nhập</label>
            <div className="col-lg-8">
              <div className="row">
                {Object.keys(projectDetail.profile.loginTypeStatus).map((key: string) => {
                  return (
                    <div className="col-6 fv-row fv-plugins-icon-container mb-3" key={key}>
                      <div className="form-check form-switch form-check-custom">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`setting-project-login__login-type-status__${key}`}
                          checked={formValues.loginTypeStatus[key as KeyLoginTypeStatusType]}
                          onChange={(e) => handleChangeRadio(key as KeyLoginTypeStatusType, e)}
                        />
                        <label
                          className="form-check-label text-dark"
                          htmlFor={`setting-project-login__login-type-status__${key}`}
                        >
                          {key}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Domain gmail đăng nhập</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Domain gmail đăng nhập"
                    value={formValues.allowDomainLoginWifi}
                    onChange={handleChangeSelectMultiple}
                    options={[{ value: "@gmail.com", label: "@gmail.com" }]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" className="btn btn-secondary me-2" onClick={handleReset} disabled={!isDirty}>
            Huỷ
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isDirty}>
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(SettingProjectLogin, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
