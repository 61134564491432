import { NotificationType, UserType } from "../../../../types";
import { ConfirmationDataType } from "../ConfirmSettingUser";

export enum ActionType {
  SetUserList,
  SetUserListFilter,
  SetAction,
  SetNotificationList,
  SetSelectedEmailList,
  SetConfirmation,
  SetUpdatedUser,
}

export interface SetUserList {
  type: ActionType.SetUserList;
  payload: UserType[];
}

export interface SetUserListFilter {
  type: ActionType.SetUserListFilter;
  payload: UserType[];
}

export interface SetAction {
  type: ActionType.SetAction;
  payload: "ACTION_BY_FILE" | "CREATE_USER" | null;
}

export interface SetNotificationList {
  type: ActionType.SetNotificationList;
  payload: NotificationType[];
}

export interface SetSeletedEmailList {
  type: ActionType.SetSelectedEmailList;
  payload: string[];
}

export interface SetConfirmationData {
  type: ActionType.SetConfirmation;
  payload: ConfirmationDataType | null;
}

export interface SetUpatedUser {
  type: ActionType.SetUpdatedUser;
  payload: UserType | null;
}

export type SettingProjectUserActions =
  | SetUserList
  | SetUserListFilter
  | SetAction
  | SetNotificationList
  | SetSeletedEmailList
  | SetConfirmationData
  | SetUpatedUser;
