import React, { useEffect, useMemo, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { handleCheckAxiosError, regex } from "../../../utils";
import { Select } from "antd";
import { setAccount } from "../../../redux/slices/accountSlice";
import { CustomerType, ProjectType, UpdatedCustomerType } from "../../../types";
import { customerAPI, projectAPI } from "../../../services";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { handleMessage } from "../../commons/Message";

interface Props {
  updatedCustomer: CustomerType;
  onSuccess: (newCustomer: CustomerType) => void;
}

const SettingCustomer: React.FC<Props> = ({ updatedCustomer, onSuccess }) => {
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState<ProjectType[]>([]);
  const [isLoadingProjectList, setIsLoadingProjectList] = useState(false);
  const [isSelectDirty, setIsSelectDirty] = useState(false);
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<UpdatedCustomerType>({
    defaultValues: useMemo(() => {
      return {
        id: updatedCustomer.id,
        phone: updatedCustomer.phone,
        email: updatedCustomer.email,
        fullName: updatedCustomer.fullName,
        level: updatedCustomer.level,
        projectId: updatedCustomer.projectId,
      };
    }, [updatedCustomer]),
  });
  const formValuesState = watch();

  const handleGetProjectList = async () => {
    try {
      setIsLoadingProjectList(true);
      const resultGetProjectList = await projectAPI.getProjectList();
      if (resultGetProjectList.data.result) {
        setProjectList(resultGetProjectList.data.data);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      setIsLoadingProjectList(false);
    }
  };

  const hanndlSetIsSelectDirty = (newFormValuesState: UpdatedCustomerType) => {
    setIsSelectDirty(
      newFormValuesState.projectId !== updatedCustomer.projectId || newFormValuesState.level !== updatedCustomer.level
    );
  };

  const onSubmit: SubmitHandler<UpdatedCustomerType> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultUpdateCustomerApi = await customerAPI.updateCustomer(formData);
      if (resultUpdateCustomerApi.data.result) {
        handleMessage("success", resultUpdateCustomerApi.data.msg);
        onSuccess(resultUpdateCustomerApi.data.data);
      } else {
        handleMessage("error", resultUpdateCustomerApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    register("level");
    register("projectId");
    handleGetProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="setting-customer">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-3">Cập nhật thông tin khách hàng</h1>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tên khách hàng</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.fullName && "border-danger"}`}
                  placeholder="Tên khách hàng"
                  {...register("fullName", {
                    required: "Vui lòng nhập Tên khách hàng!",
                  })}
                  onBlur={() => setValue("fullName", formValuesState.fullName.trim())}
                />
                {errors.fullName && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.fullName.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="email"
                  className={`form-control form-control-lg ${errors.email && "border-danger"}`}
                  placeholder="Email"
                  {...register("email", {
                    required: "Vui lòng nhập Email!",
                    pattern: {
                      value: regex.email,
                      message: "Email không hợp lệ ",
                    },
                  })}
                />
                {errors.email && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.email.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Số điện thoại</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="number"
                  className={`form-control form-control-lg ${errors.phone && "border-danger"}`}
                  placeholder="Số điện thoại"
                  {...register("phone", {
                    required: "Vui lòng nhập Số điện thoại!",
                    pattern: {
                      value: regex.phone,
                      message: "Số điện thoại không hợp lệ ",
                    },
                  })}
                  onBlur={() => setValue("phone", formValuesState.phone.trim())}
                />
                {errors.phone && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.phone.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Level</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder"
                  value={formValuesState.level}
                  onChange={(value) => {
                    hanndlSetIsSelectDirty({ ...formValuesState, level: value });
                    setValue("level", value);
                  }}
                  options={[
                    { value: 1, label: "Level 1" },
                    { value: 2, label: "Level 2" },
                    { value: 3, label: "Level 3" },
                    { value: 4, label: "Level 4" },
                    { value: 5, label: "Level 5" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Dự án</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder"
                  value={formValuesState.projectId}
                  onChange={(value) => {
                    hanndlSetIsSelectDirty({ ...formValuesState, projectId: value });
                    setValue("projectId", value);
                  }}
                  loading={isLoadingProjectList}
                  options={[
                    { value: null, label: "Không chọn dự án" },
                    ...projectList.map((item) => {
                      return { value: item.id, label: item.code };
                    }),
                  ]}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button
              className="btn btn-secondary me-3"
              disabled={!isDirty && !isSelectDirty}
              type="reset"
              onClick={() => reset()}
            >
              Reset
            </button>
            <button className="btn btn-primary" disabled={!isDirty && !isSelectDirty} type="submit">
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(SettingCustomer, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
