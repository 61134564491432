import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { Select } from "antd";
import { ProjectDetailType, ProjectDeviceUpdatedType, ProjectDeviceType } from "../../../types/ProjectType";

interface Props {
  projectDetail: ProjectDetailType;
  device: ProjectDeviceType;
  onSuccess: (newDevice: ProjectDeviceType) => void;
}

const UpdateDeviceForm: React.FC<Props> = ({ projectDetail, device, onSuccess }) => {
  const dispatch = useDispatch();
  const deviceEntTypeList = projectDetail.profile.selectList.deviceEntTypeList;
  const projectId = projectDetail.profile.projectId;
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<ProjectDeviceUpdatedType>({
    defaultValues: {
      id: device.id,
      projectId: projectId,
      identity: device.identity,
      ip: device.ip,
      deviceUserName: device.deviceUserName,
      devicePassword: device.devicePassword,
      macFree: device.macFree,
      serverName: device.serverName,
      port: device.port,
      deviceEntTypeId: device.deviceEntTypeId,
      mac: device.mac,
    },
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<ProjectDeviceUpdatedType> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultCreateCustomerApi = await projectAPI.updateDevice(formData);
      if (resultCreateCustomerApi.data.result) {
        handleMessage("success", resultCreateCustomerApi.data.msg);
        onSuccess(resultCreateCustomerApi.data.data);
      } else {
        handleMessage("error", resultCreateCustomerApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    register("deviceEntTypeId");
    register("identity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="update-device-form">
      <div className="text-center mb-6">
        <h1 className="mb-3">Cập nhật thiết bị</h1>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Host/IP</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.ip && "border-danger"}`}
                  placeholder="IP"
                  {...register("ip", {
                    required: "Vui lòng nhập IP!",
                  })}
                  onBlur={() => setValue("ip", formValuesState.ip.trim())}
                />
                {errors.ip && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.ip.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Port</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="number"
                  className={`form-control form-control-lg ${errors.port && "border-danger"}`}
                  placeholder="Port"
                  {...register("port", {
                    required: "Vui lòng nhập Port!",
                  })}
                />
                {errors.port && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.port.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Device username</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.deviceUserName && "border-danger"}`}
                  placeholder="Username"
                  {...register("deviceUserName", {
                    required: "Vui lòng nhập Username!",
                  })}
                  onBlur={() => setValue("deviceUserName", formValuesState.deviceUserName.trim())}
                />
                {errors.deviceUserName && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.deviceUserName.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Device password</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <div className="input-group">
                  <input
                    type={isShowPassword ? "text" : "password"}
                    className={`form-control form-control-lg ${errors.devicePassword && "border-danger"}`}
                    placeholder="Password"
                    {...register("devicePassword", {
                      required: "Vui lòng nhập Password!",
                    })}
                    onBlur={() => setValue("devicePassword", formValuesState.devicePassword.trim())}
                  />
                  <button className="input-group-text" type="button" onClick={() => setIsShowPassword((prev) => !prev)}>
                    {isShowPassword ? (
                      <i className="fa-regular fa-eye-slash w-15px"></i>
                    ) : (
                      <i className="fa-regular fa-eye w-15px"></i>
                    )}
                  </button>
                </div>
                {errors.devicePassword && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.devicePassword.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">MAC Free</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="MAC Free"
                  {...register("macFree")}
                  onBlur={() => setValue("macFree", formValuesState.macFree.trim())}
                />
                {errors.macFree && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.macFree.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Loại thiết bị</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder"
                  value={formValuesState.deviceEntTypeId}
                  onChange={(value) => setValue("deviceEntTypeId", value, { shouldDirty: true })}
                  options={deviceEntTypeList}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="update-device-form__category my-6">
          <p className="mb-0 fw-bold">Thông tin từ thiết bị</p>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">Identity</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Identity"
                  value={device.identity}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">MAC</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.mac && "border-danger"}`}
                  placeholder="MAC"
                  {...register("mac", {
                    required: "Vui lòng nhập MAC!",
                  })}
                />
                {errors.mac && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.mac.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Server name</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.serverName && "border-danger"}`}
                  placeholder="Server name"
                  {...register("serverName", {
                    required: "Vui lòng nhập Server name!",
                  })}
                  onBlur={() => setValue("serverName", formValuesState.serverName.trim())}
                />
                {errors.serverName && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.serverName.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button className="btn btn-secondary me-3" type="reset" disabled={!isDirty} onClick={() => reset()}>
              Reset
            </button>
            <button className="btn btn-primary" type="submit" disabled={!isDirty}>
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(UpdateDeviceForm, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
