import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { handleCheckAxiosError, regex } from "../../../utils";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { setAccount } from "../../../redux/slices/accountSlice";
import { useDispatch } from "react-redux";
import { ProjectCustomerType, ProjectCreatedType, ProjectType } from "../../../types";
import { Select } from "antd";
import { setLoading } from "../../../redux/slices/loadingSlice";

interface Props {
  onCreatedSuccess: (newProject: ProjectType) => void;
}

const CreateProjectForm: React.FC<Props> = ({ onCreatedSuccess }) => {
  const dispatch = useDispatch();
  const [isLoadingCustomerNoProject, setIsLoadingCustomerNoProject] = useState(false);
  const [customerNotProject, setCustomerNotProject] = useState<ProjectCustomerType[]>([]);
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm<ProjectCreatedType>({
    defaultValues: {
      userId: null,
    },
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<ProjectCreatedType> = async (value) => {
    try {
      dispatch(setLoading(true));
      const resultCreateProjectApi = await projectAPI.createProject(value);
      if (resultCreateProjectApi.data.result) {
        handleMessage("success", resultCreateProjectApi.data.msg);
        onCreatedSuccess(resultCreateProjectApi.data.data);
      } else {
        handleMessage("error", resultCreateProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetCustomerNotProject = async () => {
    try {
      setIsLoadingCustomerNoProject(true);
      const resultGetCusomerNotInAnyProjectApi = await projectAPI.getCustomerListNotInAnyProject();
      if (resultGetCusomerNotInAnyProjectApi.data.result) {
        setCustomerNotProject(resultGetCusomerNotInAnyProjectApi.data.data);
      } else {
        handleMessage("error", resultGetCusomerNotInAnyProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      setIsLoadingCustomerNoProject(false);
    }
  };

  useEffect(() => {
    handleGetCustomerNotProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-project-form">
      <div className="text-center mb-13">
        <h1 className="mb-3">Thêm dự án</h1>
      </div>
      <form
        className="form fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tên dự án</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Tên dự án"
                  {...register("name", {
                    required: "Vui lòng nhập Tên dự án!",
                  })}
                  onBlur={() => setValue("name", formValuesState.name?.trim())}
                />
                {errors.name && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.name.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Code dự án</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Code dự án"
                  {...register("code", {
                    required: "Vui lòng nhập Code dự án!",
                  })}
                  onInput={(e) =>
                    setValue("code", (e.target as HTMLInputElement).value.toUpperCase())
                  }
                  onBlur={() => setValue("code", formValuesState.code?.trim())}
                />
                {errors.code && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.code.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
            Tiêu đề chào mừng
          </label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Tiêu đề chào mừng"
                  {...register("title", {
                    required: "Vui lòng nhập Tiêu đề chào mừng!",
                  })}
                  onBlur={() => setValue("title", formValuesState.title?.trim())}
                />
                {errors.title && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.title.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">Quản trị viên</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder mb-3"
                  value={formValuesState.userId}
                  onChange={(value) => setValue("userId", value)}
                  options={[
                    { value: null, label: "Không chọn Quản trị viên" },
                    ...customerNotProject.map((item) => {
                      return { value: item.id, label: `${item.email} - ${item.phone}` };
                    }),
                  ]}
                  loading={isLoadingCustomerNoProject}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Login domain</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Login domain"
                  {...register("domain", {
                    required: "Vui lòng nhập Login domain!",
                    pattern: {
                      value: regex.url,
                      message: "Login domain không hợp lệ!",
                    },
                  })}
                  onBlur={() => setValue("domain", formValuesState.domain?.trim())}
                />
                {errors.domain && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.domain.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-12 col-form-label">
            <button className="btn btn-primary w-100">Tạo dự án</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(CreateProjectForm, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
