import React from "react";
import { SettingProjectUserState, initialSettingProjectUserState } from "./state";
import { SettingProjectUserActions } from "./actions";

export const SettingProjectUserContext = React.createContext<{
  state: SettingProjectUserState;
  dispatchContext: React.Dispatch<SettingProjectUserActions>;
}>({
  state: initialSettingProjectUserState,
  dispatchContext: () => undefined,
});
