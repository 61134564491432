import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { getAccountSelector } from "../../../redux/selectors";

// ********************************************************** \\

const Menu: React.FC = () => {
  const account = useSelector(getAccountSelector);
  return (
    <div
      className="app-header-menu app-header-mobile-drawer align-items-stretch"
      data-kt-drawer="true"
      data-kt-drawer-name="app-header-menu"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="250px"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_app_header_menu_toggle"
      data-kt-swapper="true"
      data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
      data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
    >
      <div
        className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
        id="kt_app_header_menu"
        data-kt-menu="true"
      >
        <div
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-placement="bottom-start"
          className="menu-item menu-lg-down-accordion me-0 me-lg-2"
        >
          {account?.projectInfo?.logo && <img src={account.projectInfo.logo} className="w-40px h-40px" alt="" />}
          <span className="menu-title ms-5 fs-5 fw-bold" style={{ textTransform: "capitalize" }}>
            {account?.projectInfo?.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(Menu, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
