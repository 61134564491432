import React, { useCallback, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { getMenuSelector, getAccountRolesSelector, getAccountSelector } from "../redux/selectors";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/dashboard/Footer";
import { setLoading } from "../redux/slices/loadingSlice";
import { handleMessage } from "../components/commons/Message";
import { menuAPI } from "../services";
import Header from "../components/dashboard/Header";
import SideBar from "../components/dashboard/SideBar";
import { setMenu } from "../redux/slices/menuSlice";
import "./scss/DashboardTemplate.scss";

interface Props {
  component: React.FC;
}

const DashboardTemplate: React.FC<Props> = ({ component: Component }) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountSelector);
  const navigate = useNavigate();
  const menu = useSelector(getMenuSelector);
  const accountRoles = useSelector(getAccountRolesSelector);
  const { pathname } = useLocation();

  const handleGetMenu = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const resultMenuApi = await menuAPI();
      if (resultMenuApi.data.result) {
        dispatch(setMenu(resultMenuApi.data.data));
        if (account && account.projectInfo && pathname === "/dashboard") {
          navigate("/dashboard/loginReport");
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error getting menu: ", error);
      handleMessage("error", "Lỗi hệ thống. Vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    if (menu.length === 0) {
      handleGetMenu();
    }
    return () => {
      const overlayEle = document.querySelector(".drawer-overlay");
      const bodyEle = document.body;
      if (overlayEle) {
        bodyEle.removeChild(overlayEle);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard-template">
      {account ? (
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <Header />
          <div className="app-wrapper flex-column flex-row-fluid " id="kt_app_wrapper">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div className="app-container container-fluid py-3 py-lg-6 h-100">{accountRoles && <Component />}</div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default withErrorBoundary(DashboardTemplate, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
