import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getMenuSelector } from "../../../redux/selectors";
import { MenuType } from "../../../types";
import { convertRemToPixels } from "../../../utils";
import { withErrorBoundary } from "react-error-boundary";

// ********************************************************** \\

const SideBar: React.FC = () => {
  const [sideBarMenuHeight, setSideBarMenuHeight] = useState<number>(0);
  const menu: MenuType[] = useSelector(getMenuSelector);

  const calculateMenuHeight = () => {
    const sidebarLogoEle: HTMLElement | null = document.querySelector("#kt_app_sidebar_logo");
    if (sidebarLogoEle) {
      // 1 rem is margin top/bottom of element use this result
      return window.innerHeight - sidebarLogoEle.offsetHeight - convertRemToPixels(1) * 2;
    } else {
      return window.innerHeight - convertRemToPixels(1) * 2;
    }
  };

  const handleSetClassSideBar = () => {
    const sidebarEle = document.querySelector("#kt_app_sidebar");
    if (window.innerWidth < 992) {
      if (sidebarEle) {
        sidebarEle.classList.add("drawer", "drawer-start");
      }
    } else {
      if (sidebarEle) {
        sidebarEle.classList.remove("drawer", "drawer-start");
      }
    }
  };

  // set height of sidebar menu when browser resize
  // set class for sidebar menu when browser resize
  const handleWindowResize = useCallback(() => {
    // set height
    setSideBarMenuHeight(calculateMenuHeight());

    // set class
    handleSetClassSideBar();
  }, []);

  window.addEventListener("resize", handleWindowResize);

  // show&hide sidebar menu item
  const handleToggleMenu = (e: any) => {
    let clickedEle = e.target;
    while (clickedEle && !clickedEle.classList.contains("menu-item", "menu-accordion")) {
      clickedEle = clickedEle.parentElement;
    }
    // show or hidden sub menu if sub menu exist
    if (clickedEle.querySelector(".menu-sub .menu-item")) {
      clickedEle.classList.toggle("hover");
      clickedEle.classList.toggle("show");
    }
  };

  // setting sidebar always show or only show when hover
  const handleSettingSideBar = () => {
    let optionSettingSidebar = document.body.getAttribute("data-kt-app-sidebar-minimize");
    let btnToggle = document.querySelector("#kt_app_sidebar_toggle");

    if (optionSettingSidebar === "on") {
      document.body.setAttribute("data-kt-app-sidebar-minimize", "");
      btnToggle?.classList.remove("active");
    } else {
      document.body.setAttribute("data-kt-app-sidebar-minimize", "on");
      btnToggle?.classList.add("active");
    }
  };

  // set sidebar menu height and class sidebar when first render and remove event window resize
  useEffect(() => {
    setSideBarMenuHeight(calculateMenuHeight());
    handleSetClassSideBar();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <div
      id="kt_app_sidebar"
      className="app-sidebar flex-column border-end"
      style={{ borderRight: "var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important" }}
      data-kt-drawer="true"
      data-kt-drawer-name="app-sidebar"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="225px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
    >
      <div className="app-sidebar-logo px-6 border-0" id="kt_app_sidebar_logo">
        <Link to="/dashboard" className="w-100">
          <img
            alt="Logo"
            src="/assets/images/logo.png"
            className="h-50px app-sidebar-logo-default w-100 object-fit-contain"
          />

          <img alt="Logo" src="/assets/images/logo-small.png" className="h-20px app-sidebar-logo-minimize" />
        </Link>

        <div
          id="kt_app_sidebar_toggle"
          className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
          data-kt-toggle="true"
          data-kt-toggle-state="active"
          data-kt-toggle-target="body"
          data-kt-toggle-name="app-sidebar-minimize"
        >
          <span className="svg-icon svg-icon-2 rotate-180" onClick={handleSettingSideBar}>
            <img src="/assets/icons/sidebar-toggle.svg" alt="" className="img-muted img-hover-wimesh" />
          </span>
        </div>
      </div>

      <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
        <div
          id="kt_app_sidebar_menu_wrapper"
          className="app-sidebar-wrapper hover-scroll-overlay-y my-3"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
          data-kt-scroll-wrappers="#kt_app_sidebar_menu"
          data-kt-scroll-offset="5px"
          data-kt-scroll-save-state="true"
          style={{ height: sideBarMenuHeight }}
        >
          <div
            className="menu menu-column menu-rounded menu-sub-indention px-3"
            id="#kt_app_sidebar_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
          >
            {menu.map((menuItem, index) => {
              return (
                <div
                  key={index}
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion show hover"
                  onClick={(e) => handleToggleMenu(e)}
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      <span className="svg-icon svg-icon-2">
                        <i className={`fa-solid ${menuItem.icon}`}></i>
                      </span>
                    </span>
                    <span className="menu-title">{menuItem.title}</span>
                    <span className="menu-arrow"></span>
                  </span>
                  <div className="menu-sub menu-sub-accordion">
                    {menuItem.arrSubMenu &&
                      menuItem.arrSubMenu.map((subMenuItem, index) => {
                        return (
                          <div key={index} className="menu-item">
                            <NavLink className="menu-link text-decoration-none" to={subMenuItem.url}>
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">{subMenuItem.name}</span>
                            </NavLink>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(SideBar, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
