import { createSlice } from "@reduxjs/toolkit";

export interface AppState {
  isShowModalChangePassword: boolean;
}

const initialState: AppState = { isShowModalChangePassword: false };

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setShowModalChangePassword: (state, action: { payload: boolean }) => {
      state.isShowModalChangePassword = action.payload;
    },
  },
});

export const { setShowModalChangePassword } = appSlice.actions;

export default appSlice.reducer;
