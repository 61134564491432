import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/loadingSlice";
import { accountAPI } from "../services";
import { useSearchParams } from "react-router-dom";
import "./scss/forgot-password-wifi.scss";
import { ForgotPasswordWifiFormDataType } from "../types";
import { handleMessage } from "../components/commons/Message";
import { handleCheckAxiosError } from "../utils";
import { setAccount } from "../redux/slices/accountSlice";
import { SubmitHandler, useForm } from "react-hook-form";

interface FormValuesType {
  password: string;
  confirmPassword: string;
}

const ForgotPassLoginWifiPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isChangeSuccess, setIsChangeSuccess] = useState(false);
  const [formData, setFormData] = useState<ForgotPasswordWifiFormDataType | null>(null);
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || "";
  const code = searchParams.get("code") || "";
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValuesType>();

  const onSubmit: SubmitHandler<FormValuesType> = async (data) => {
    try {
      dispatch(setLoading(true));
      if (formData && key) {
        const resultChangePasswordWifiApi = await accountAPI.changePasswordWifi(formData.email, data.password, key);
        if (resultChangePasswordWifiApi.data.result) {
          setIsChangeSuccess(true);
          handleMessage("success", resultChangePasswordWifiApi.data.msg);

          setTimeout(() => {
            window.location.href = formData.linkLogin;
          }, 3000);
        } else {
          handleMessage("error", resultChangePasswordWifiApi.data.msg);
        }
      }
    } catch (error) {
      console.log("Lỗi đăng ký: ", error);
      handleMessage("error", "Lỗi hệ thống vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetForgotPasswordLoginFormData = async () => {
    try {
      dispatch(setLoading(true));
      const resultGetFormData = await accountAPI.getFormForgotPasswordWifi(key, code);
      if (resultGetFormData.data.result) {
        setFormData(resultGetFormData.data.data);
      } else {
        handleMessage("error", resultGetFormData.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    handleGetForgotPasswordLoginFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="forgot-password-wifi d-flex align-items-center justify-content-center"
      style={{ background: "url(/assets/images/bg_network.jpeg) center center" }}
    >
      <div className="forgot-password-wifi__form-container bg-white text-center mx-10">
        {formData && (
          <div className="forgot-password-wifi__form-container__logo pt-10 pb-5">
            <img src={formData.logo} alt="" width={200} height={200} />
            <p className="text-white mt-2 fs-3 mb-0">{formData.projectName}</p>
          </div>
        )}
        <div className="forgot-password-wifi__form-container__form bg-white container">
          <h5 className="mb-0 py-5 fs-2 border-bottom border-primary border-5">Tài Khoản</h5>
          <h4 className="mb-3 mt-10 fs-1">Thay đổi mật khẩu</h4>
          {formData && formData.email !== "" && (
            <p className="fs-5 mb-8">
              Bạn đang thực hiện thao tác thay đổi mật khẩu cho tài khoản &#91;{formData.email}&#93;
            </p>
          )}
          {formData && formData.msgError !== "" ? (
            <div className="alert alert-danger d-flex align-items-center px-5 py-3">
              <div className="d-flex flex-column">
                <h4 className="mb-1 text-danger">{formData.msgError}</h4>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <input
                  type="password"
                  className={`w-100 fs-3 ${errors.password && "border-danger"}`}
                  placeholder="Mật khẩu mới"
                  {...register("password", { required: "Vui lòng nhập Mật khẩu mới!" })}
                  onBlur={(e) => setValue("password", e.target.value.trim())}
                />
                {errors.password && <p className="mb-0 text-danger text-start mt-1">{errors.password.message}</p>}
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  className={`w-100 fs-3 ${errors.confirmPassword && "border-danger"}`}
                  placeholder="Nhập lại mật khẩu mới"
                  {...register("confirmPassword", {
                    required: "Vui lòng nhập lại Mật khẩu!",
                    validate: (val: string) => {
                      if (watch("password") !== val) {
                        return "Mật khẩu không trùng khớp!";
                      }
                    },
                  })}
                  onBlur={(e) => setValue("confirmPassword", e.target.value.trim())}
                />
                {errors.confirmPassword && (
                  <p className="mb-0 text-danger text-start mt-1">{errors.confirmPassword.message}</p>
                )}
              </div>
              <button className="btn btn-primary w-100 py-2 mb-10 fs-3" disabled={isChangeSuccess}>
                Cập nhật
              </button>
              {isChangeSuccess && formData && (
                <a href={formData?.linkLogin} className="d-block link-primary mb-5 fs-3">
                  Đi đến trang đăng nhập wifi
                </a>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(ForgotPassLoginWifiPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
