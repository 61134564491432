const handleFormatFileSize = (bytes: number) => {
  let result = Math.round((bytes / 1024) * 100) / 100;
  if (result < 216) return `${result} KB`;
  result = Math.round((bytes / 1024 / 1024) * 100) / 100;
  if (result < 216) return `${result} MB`;
  result = Math.round((bytes / 1024 / 1024 / 1024) * 100) / 100;
  if (result < 216) return `${result} GB`;
  result = Math.round((bytes / 1024 / 1024 / 1024 / 1024) * 100) / 100;
  return `${result} TB`;
};

export default handleFormatFileSize;
