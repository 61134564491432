import React, { useEffect, useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType } from "../../../types";
import { handleCheckAxiosError, regex } from "../../../utils";
import "./index.scss";
import { projectAPI, uploadAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setAccount } from "../../../redux/slices/accountSlice";
import ImagePreview from "../../commons/ImagePreview";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

interface IFormInput {
  name: string;
  title: string;
  welcomeText: string;
  linkBanner: string;
  domain: string;
  logo: string;
  background: string;
  imgBanner: string;
  linkRedirect: string;
}

const SettingInformationProject: React.FC<Props> = ({ projectDetail, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return {
        name: projectDetail.profile.name,
        title: projectDetail.profile.title,
        welcomeText: projectDetail.profile.welcomeText,
        linkBanner: projectDetail.profile.linkBanner,
        domain: projectDetail.profile.domain,
        logo: projectDetail.profile.logo,
        background: projectDetail.profile.background,
        imgBanner: projectDetail.profile.imgBanner,
        linkRedirect: projectDetail.profile.linkRedirect,
      };
    }, [projectDetail]),
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultUpdateProfileProjectApi = await projectAPI.updateProjectProfile({
        ...projectDetail.profile,
        ...formData,
      });
      if (resultUpdateProfileProjectApi.data.result) {
        onUpdateSuccess({ ...projectDetail, profile: resultUpdateProfileProjectApi.data.data });
        handleMessage("success", resultUpdateProfileProjectApi.data.msg);
      } else {
        handleMessage("error", resultUpdateProfileProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const inputName = e.target.getAttribute("input-name") as "logo" | "background" | "imgBanner";
      if (!e.target.files) {
        setValue(inputName, "");
        return;
      }
      const file = e.target.files[0];
      const resultUploadFileApi = await uploadAPI.uploadFile(file);
      if (resultUploadFileApi.data.result) {
        setValue(inputName, `${resultUploadFileApi.data.data.urlImage}`, { shouldDirty: true });
      } else {
        handleMessage("error", resultUploadFileApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    }
  };

  useEffect(() => {
    reset({
      name: projectDetail.profile.name,
      title: projectDetail.profile.title,
      welcomeText: projectDetail.profile.welcomeText,
      linkBanner: projectDetail.profile.linkBanner,
      domain: projectDetail.profile.domain,
      logo: projectDetail.profile.logo,
      background: projectDetail.profile.background,
      imgBanner: projectDetail.profile.imgBanner,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail]);

  return (
    <div className="setting-project-informaion collapse show">
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="card-body border-top p-9">
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tên dự án</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.name && "border-danger"}`}
                    placeholder="Tên dự án"
                    {...register("name", {
                      required: "Vui lòng nhập Tên dự án!",
                    })}
                    onBlur={() => setValue("name", formValuesState.name?.trim())}
                  />
                  {errors.name && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.name.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Code dự án</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Code dự án"
                    readOnly
                    disabled
                    value={projectDetail.profile.code}
                  />
                  <div className="form-text">Tên viết tắt dự án.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tiêu đề</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.title && "border-danger"}`}
                    placeholder="Tiêu đề"
                    {...register("title", {
                      required: "Vui lòng nhập Tiêu đề!",
                    })}
                    onBlur={() => setValue("title", formValuesState.title?.trim())}
                  />
                  {errors.title && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.title.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Câu chào mừng</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3 mb-lg-0"
                    placeholder="Tiêu đề chào mừng"
                    {...register("welcomeText")}
                    onBlur={() => setValue("welcomeText", formValuesState.welcomeText?.trim())}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Link banner</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.linkBanner && "border-danger"}`}
                    placeholder="Link banner"
                    {...register("linkBanner", {
                      pattern: {
                        value: regex.urlAllowEmpty,
                        message: "Link banner không hợp lệ!",
                      },
                    })}
                    onBlur={() => setValue("linkBanner", formValuesState.linkBanner?.trim())}
                  />
                  <div className="form-text">URL được khi người dùng bấm vào banner.</div>
                  {errors.linkBanner && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.linkBanner.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Link redirect</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.linkRedirect && "border-danger"}`}
                    placeholder="Link redirect"
                    {...register("linkRedirect", {
                      pattern: {
                        value: regex.urlAllowEmpty,
                        message: "Link redirect không hợp lệ!",
                      },
                    })}
                    onBlur={() => setValue("linkRedirect", formValuesState.linkRedirect?.trim())}
                  />
                  <div className="form-text">URL được mở sau khi người dùng đăng nhập wifi thành công.</div>
                  {errors.linkRedirect && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.linkRedirect.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Login domain</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12 fv-row fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.domain && "border-danger"}`}
                    placeholder="Login domain"
                    {...register("domain", {
                      required: "Vui lòng nhập Login domain!",
                      pattern: {
                        value: regex.url,
                        message: "Login domain không hợp lệ!",
                      },
                    })}
                    onBlur={() => setValue("domain", formValuesState.domain?.trim())}
                  />
                  {errors.domain && (
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                      {errors.domain.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Logo</label>
            <div className="col-lg-8">
              <div
                className={`image-input image-input-outline ${formValuesState.logo === "" ? "image-input-empty" : ""}`}
                data-kt-image-input="true"
                style={{
                  background: "url(/assets/icons/landscape.svg) no-repeat center center / contain",
                }}
              >
                <div className="image-input-wrapper w-125px h-125px">
                  <ImagePreview src={formValuesState.logo} className="w-100 h-100 object-fit-contain" />
                </div>
                <label
                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="change"
                >
                  <i className="fa-solid fa-pencil"></i>
                  <input type="file" input-name="logo" accept=".png, .jpg, .jpeg" onChange={handleUploadFile} />
                </label>
              </div>
              <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Background</label>
            <div className="col-lg-8">
              <div
                className={`image-input image-input-outline ${
                  formValuesState.background === "" ? "image-input-empty" : ""
                }`}
                data-kt-image-input="true"
                style={{
                  background: "url(/assets/icons/landscape.svg) no-repeat center center / contain",
                }}
              >
                <div className="image-input-wrapper w-125px h-125px">
                  <ImagePreview src={formValuesState.background} className="w-100 h-100 object-fit-contain" />
                </div>
                <label
                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="change"
                >
                  <i className="fa-solid fa-pencil"></i>
                  <input type="file" input-name="background" accept=".png, .jpg, .jpeg" onChange={handleUploadFile} />
                </label>
              </div>
              <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>
          <div className="row mb-3 mb-lg-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Banner</label>
            <div className="col-lg-8">
              <div
                className={`image-input image-input-outline ${
                  formValuesState.imgBanner === "" ? "image-input-empty" : ""
                }`}
                data-kt-image-input="true"
                style={{
                  background: "url(/assets/icons/landscape.svg) no-repeat center center / contain",
                }}
              >
                <div className="image-input-wrapper w-125px h-125px">
                  <ImagePreview src={formValuesState.imgBanner} className="w-100 h-100 object-fit-contain" />
                </div>
                <label
                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="change"
                >
                  <i className="fa-solid fa-pencil"></i>
                  <input type="file" input-name="imgBanner" accept=".png, .jpg, .jpeg" onChange={handleUploadFile} />
                </label>
              </div>
              <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button className="btn btn-light btn-active-light-primary me-2" onClick={() => reset()} disabled={!isDirty}>
            Huỷ
          </button>
          <button type="submit" className="btn btn-primary" disabled={!isDirty}>
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(SettingInformationProject, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
