import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { handleCheckAxiosError, regex } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setAccount } from "../../../redux/slices/accountSlice";
import { introductionAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";

interface FormValues {
  name: string;
  email: string;
  title: string;
  phone: string;
  content: string;
  formality: string;
}

const FormIntroduction: React.FC = () => {
  const dispatch = useDispatch();
  const introduction = useSelector(getIntroductionSelector);
  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
    watch,
    setValue,
  } = useForm<FormValues>({ mode: "onBlur" });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      dispatch(setLoading(true));
      const resultContactFormApi = await introductionAPI.contactForm(data);
      if (resultContactFormApi.data.result) {
        handleMessage("success", resultContactFormApi.data.msg);
      } else {
        handleMessage("error", resultContactFormApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formState = watch();

  return (
    <div className="form-introduction bg-white" id="Contact">
      <div
        className="form-introduction__background"
        style={{
          background: "url(/assets/images/bg_contact.jpg) no-repeat scroll 50%",
        }}
      ></div>
      <div className="form-introduction__container container-sm py-10">
        <div className="row">
          <div className="col-12 col-lg-8">
            <form className="p-10 rounded bg-white" onSubmit={handleSubmit(onSubmit)} noValidate>
              <h4 className="mb-8 fs-2x">Liên hệ với chúng tôi</h4>
              <div className="row gy-6">
                {/* Name Input */}
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    className={`form-control fs-5 ${errors.name && "border-danger"}`}
                    placeholder="Họ tên *"
                    {...register("name", {
                      required: "Vui lòng nhập Họ tên!",
                      validate: (value) => {
                        return !!value.trim() || "Vui lòng nhập Họ tên!";
                      },
                    })}
                    onInput={() => clearErrors("name")}
                    onBlur={() => setValue("name", formState.name?.trim())}
                  />
                  {errors.name && (
                    <div className="valid-feedback d-block text-danger">{errors.name.message}</div>
                  )}
                </div>
                {/* Email Input */}
                <div className="col-12 col-md-6">
                  <input
                    type="email"
                    className={`form-control fs-5 ${errors.email && "border-danger"}`}
                    placeholder="Email *"
                    {...register("email", {
                      required: "Vui lòng nhập Email!",
                      pattern: {
                        value: regex.email,
                        message: "Email không hợp lệ!",
                      },
                    })}
                    onInput={() => clearErrors("email")}
                    onBlur={() => setValue("email", formState.email?.trim())}
                  />
                  {errors.email && (
                    <div className="valid-feedback d-block text-danger">{errors.email.message}</div>
                  )}
                </div>
                {/* Title Input */}
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    className="form-control fs-5"
                    placeholder="Tiêu đề"
                    {...register("title")}
                    onBlur={() => setValue("title", formState.title?.trim())}
                  />
                </div>
                {/* Phone Input */}
                <div className="col-12 col-md-6">
                  <input
                    type="tel"
                    className={`form-control fs-5 ${errors.phone && "border-danger"}`}
                    placeholder="Số điện thoại *"
                    {...register("phone", {
                      required: "Vui lòng nhập Số điện thoại!",
                      pattern: {
                        value: regex.phone,
                        message: "Số điện thoại không hợp lệ!",
                      },
                    })}
                    onInput={() => clearErrors("phone")}
                    onBlur={() => setValue("phone", formState.phone?.trim())}
                  />
                  {errors.phone && (
                    <div className="valid-feedback d-block text-danger">{errors.phone.message}</div>
                  )}
                </div>
                {/* Content Input */}
                <div className="col-12">
                  <textarea
                    className={`form-control fs-5 ${errors.content && "border-danger"}`}
                    placeholder="Nội dung *"
                    rows={5}
                    {...register("content", {
                      required: "Vui lòng nhập Nội dung cần tư vấn!",
                    })}
                    onInput={() => clearErrors("content")}
                    onBlur={() => setValue("content", formState.content?.trim())}
                  />
                  {errors.content && (
                    <div className="valid-feedback d-block text-danger">
                      {errors.content.message}
                    </div>
                  )}
                </div>
                {/* Radio */}
                <p className={`mb-0 mt-8 fs-5 ${errors.formality && "text-danger"}`}>
                  Vui lòng chọn hình thức hợp tác(*):
                </p>
                <div className="col-12 col-md-6">
                  <div className="form-check form-check-custom form-check-success form-check-solid form-check-sm align-items-start">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="advertisement"
                      id="inlineRadio1"
                      {...register("formality", { required: true })}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      <h5>TƯ VẤN QUẢNG CÁO</h5>
                      <p className="mb-0">
                        Tư vấn truyền thông thương hiệu, sản phẩm, dịch vụ tới khách hàng tiềm năng
                        khi truy cập Wi-Fi tại các địa điểm trên nền tảng quảng cáo AWING (AWING
                        Wi-Fi Marketing Network)
                      </p>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-check form-check-custom form-check-success form-check-solid form-check-sm align-items-start">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="technique"
                      id="inlineRadio2"
                      {...register("formality", { required: true })}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      <h5>TƯ VẤN KỸ THUẬT</h5>
                      <p className="mb-0">
                        Tư vấn xây dựng hệ thống Wi-Fi chất lượng cao, quy mô lớn, giá thành phải
                        chăng. Chuyển đổi thành hệ thống Wi-Fi Marketing cho địa điểm/cửa hàng/doanh
                        nghiệp
                      </p>
                    </label>
                  </div>
                </div>
                {/* Button Submit */}
                <button className="btn bg-wimesh bg-hover-wimesh text-white text-uppercase fw-bold mt-6 rounded-pill">
                  Gửi thông tin
                </button>
              </div>
            </form>
          </div>
          {/* Contact Information */}
          <div className="col-12 col-lg-4">
            <div className="form-introduction__information pt-5">
              <div className="d-flex flex-column align-items-center mb-10">
                <img
                  src="/assets/icons/phone.svg"
                  alt="phone icon"
                  width={30}
                  className="img-wimesh mb-6"
                />
                <a
                  href={`tel:${introduction?.hotline}`}
                  className="text-hover-wimesh text-dark text-decoration-none fs-6 fw-bold"
                >
                  {introduction?.hotline}
                </a>
              </div>
              <div className="d-flex flex-column align-items-center mb-10">
                <img
                  src="/assets/icons/mail.svg"
                  alt="mail icon"
                  width={30}
                  className="img-wimesh mb-6"
                />
                <a
                  href={`mailto:${introduction?.email}`}
                  className="text-hover-wimesh text-dark text-decoration-none fs-6 fw-bold"
                >
                  {introduction?.email}
                </a>
              </div>
              <div className="d-flex flex-column align-items-center mb-10">
                <img
                  src="/assets/icons/global.svg"
                  alt="global icon"
                  width={30}
                  className="img-wimesh mb-6"
                />
                <a
                  href={introduction?.website}
                  target="_blank"
                  rel="noreferrer"
                  className="text-hover-wimesh text-dark text-decoration-none fs-6 fw-bold"
                >
                  {introduction?.website}
                </a>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="/assets/icons/facebook.svg"
                  alt="facebook icon"
                  width={30}
                  className="img-wimesh mb-3"
                />
                <a
                  href={introduction?.facebook}
                  className="text-hover-wimesh text-dark text-decoration-none fs-6 fw-bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  {introduction?.facebook}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(FormIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
