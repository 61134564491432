import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const data = [
  {
    image: "/assets/icons/eye.svg",
    title: "100% Viewability",
    content: "Eye-ball (ánh nhìn) tập trung trọn vẹn vào màn hình khi kết nối Wi-Fi",
  },
  {
    image: "/assets/icons/smart-phone.svg",
    title: "100% Share of Voice",
    content: "Hình ảnh thương hiệu hiển thị duy nhất trên màn hình",
  },
  {
    image: "/assets/icons/lock.svg",
    title: "100% Brand Safety",
    content: "Wi-Fi chính thống do các đơn vị uy tín cung cấp",
  },
  {
    image: "/assets/icons/band.svg",
    title: "No Ad Fraud & Blocking",
    content: "Không bị ảnh hưởng bởi các công cụ chặn quảng cáo online",
  },
];

const DifferentiationsIntroduction: React.FC = () => {
  const introduction = useSelector(getIntroductionSelector);
  return (
    <div className="differentiations-introduction pt-15 bg-white" id="Differentiations">
      <div className="container-sm pb-10">
        <div className="differentiations-introduction__header">
          <h2 className="fs-2hx text-center fw-bold pt-0 pt-md-5">Differentiations</h2>
          <p className="fs-sm-4">
            Nền tảng ACM tạo sự khác biệt, chiếm trọn sự chú ý của khách hàng mục tiêu
          </p>
        </div>
        <div className="differentiations-introduction__list row gy-8 gy-lg-4 mt-1">
          {data.map((item, index) => {
            return (
              <div key={index} className="col-12 col-md-6 col-lg-3">
                <div className="differentiations-introduction__list__item py-10 px-8 h-100">
                  <img
                    src={item.image}
                    alt={`${item.title}-icon`}
                    width={60}
                    className="d-block mx-auto img-wimesh mb-6"
                  />
                  <p className="text-center fs-2 fw-bold mb-3">{item.title}</p>
                  <p className="text-center fs-4">{item.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="differentiations-introduction__statistics d-flex flex-column flex-lg-row bg-wimesh">
        <div className="differentiations-introduction__statistics__item text-white text-center py-10">
          <p className="mb-0 fw-bold">{introduction?.differentiations.location}</p>
          <p className="mb-0 fs-3">Địa điểm</p>
        </div>
        <div className="differentiations-introduction__statistics__item flex-grow-1 text-white text-center py-10">
          <p className="mb-0 fw-bold">{introduction?.differentiations.customers}</p>
          <p className="mb-0 fs-3">Khách hàng tiêu dùng</p>
        </div>
        <div className="differentiations-introduction__statistics__item flex-grow-1 text-white text-center py-10">
          <p className="mb-0 fw-bold">{introduction?.differentiations.province}</p>
          <p className="mb-0 fs-3">TP./Tỉnh</p>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(DifferentiationsIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
