const convertUnixTimeToDate = (unixTime: number) => {
  let unixMilisecond = unixTime / 1000000000000 < 1 ? unixTime * 1000 : unixTime;
  let expire: Date = new Date(unixMilisecond);
  let date: number | string = expire.getDate();
  if (date < 10) {
    date = "0" + date;
  }
  let month: number | string = expire.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let year: number = expire.getFullYear();
  return `${date}/${month}/${year}`;
};

export default convertUnixTimeToDate;
