import React, { useEffect, useMemo } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AdminType } from "../../../types";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { adminAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError, regex } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { Select } from "antd";

interface Props {
  updatedAdmin: AdminType;
  onSuccess: (newAdmin: AdminType) => void;
}

const SettingAdmin: React.FC<Props> = ({ updatedAdmin, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<AdminType>({
    defaultValues: useMemo(() => {
      return {
        id: updatedAdmin.id,
        phone: updatedAdmin.phone,
        email: updatedAdmin.email,
        fullName: updatedAdmin.fullName,
        level: updatedAdmin.level,
        projectId: updatedAdmin.projectId,
      };
    }, [updatedAdmin]),
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<AdminType> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultUpdateAdminApi = await adminAPI.updateAdmin(formData);
      if (resultUpdateAdminApi.data.result) {
        handleMessage("success", resultUpdateAdminApi.data.msg);
        onSuccess(resultUpdateAdminApi.data.data);
      } else {
        handleMessage("error", resultUpdateAdminApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    register("level");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="setting-admin">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-3">Cập nhật thông tin Admin</h1>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tên Admin</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.fullName && "border-danger"}`}
                  placeholder="Tên Admin"
                  {...register("fullName", {
                    required: "Vui lòng nhập Tên Admin!",
                  })}
                  onBlur={() => setValue("fullName", formValuesState.fullName.trim())}
                />
                {errors.fullName && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.fullName.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="email"
                  className={`form-control form-control-lg ${errors.email && "border-danger"}`}
                  placeholder="Email"
                  {...register("email", {
                    required: "Vui lòng nhập Email!",
                    pattern: {
                      value: regex.email,
                      message: "Email không hợp lệ ",
                    },
                  })}
                />
                {errors.email && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.email.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Số điện thoại</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="number"
                  className={`form-control form-control-lg ${errors.phone && "border-danger"}`}
                  placeholder="Số điện thoại"
                  {...register("phone", {
                    required: "Vui lòng nhập Số điện thoại!",
                    pattern: {
                      value: regex.phone,
                      message: "Số điện thoại không hợp lệ ",
                    },
                  })}
                  onBlur={() => setValue("phone", formValuesState.phone.trim())}
                />
                {errors.phone && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.phone.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Level</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder"
                  value={formValuesState.level}
                  onChange={(value) => {
                    setValue("level", value);
                  }}
                  options={[
                    { value: 1, label: "Level 1" },
                    { value: 2, label: "Level 2" },
                    { value: 3, label: "Level 3" },
                    { value: 4, label: "Level 4" },
                    { value: 5, label: "Level 5" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button
              className="btn btn-secondary me-3"
              disabled={!isDirty && formValuesState.level === updatedAdmin.level}
              type="reset"
              onClick={() => reset()}
            >
              Reset
            </button>
            <button
              className="btn btn-primary"
              disabled={!isDirty && formValuesState.level === updatedAdmin.level}
              type="submit"
            >
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(SettingAdmin, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
