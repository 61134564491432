import React, { useMemo } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { WifiProfileType } from "../../../types";
import { projectAPI } from "../../../services";

interface Props {
  projectId: string;
  wifiProfile: WifiProfileType;
  onSuccess: (newWifiProfile: WifiProfileType) => void;
}

const UpdateWifiProfileForm: React.FC<Props> = ({ projectId, wifiProfile, onSuccess }) => {
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<WifiProfileType>({
    defaultValues: useMemo(() => {
      return {
        id: wifiProfile.id,
        aliveTimeout: wifiProfile.aliveTimeout,
        idleTimeOut: wifiProfile.idleTimeOut,
        name: wifiProfile.name,
        rateLimits: wifiProfile.rateLimits,
        shareUser: wifiProfile.shareUser,
        statusRefresh: wifiProfile.statusRefresh,
      };
    }, [wifiProfile]),
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<WifiProfileType> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultCreateWifiProfileApi = await projectAPI.updatedWifiProfile(projectId, formData);
      if (resultCreateWifiProfileApi.data.result) {
        handleMessage("success", resultCreateWifiProfileApi.data.msg);
        onSuccess(resultCreateWifiProfileApi.data.data);
      } else {
        handleMessage("error", resultCreateWifiProfileApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="update-wifi-profile-form">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-3">Cập nhật wifi profile</h1>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Tên wifi profile</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.name && "border-danger"}`}
                  placeholder="Tên wifi profile"
                  {...register("name", {
                    required: "Vui lòng nhập Tên wifi profile!",
                  })}
                  onBlur={() => setValue("name", formValuesState.name.trim())}
                />
                {errors.name && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.name.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Thời gian sử dụng</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.aliveTimeout && "border-danger"}`}
                  placeholder="hh:mm:ss"
                  {...register("aliveTimeout", {
                    required: "Vui lòng nhập Thời gian sử dụng!",
                  })}
                />
                {errors.aliveTimeout && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.aliveTimeout.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Số lượng user</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="number"
                  className={`form-control form-control-lg ${errors.shareUser && "border-danger"}`}
                  placeholder="Số lượng user"
                  {...register("shareUser", {
                    required: "Vui lòng nhập Số lượng user!",
                  })}
                  onBlur={() => setValue("shareUser", formValuesState.shareUser.trim())}
                />
                {errors.shareUser && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.shareUser.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Băng thông</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.rateLimits && "border-danger"}`}
                  placeholder="100M/100M"
                  {...register("rateLimits", {
                    required: "Vui lòng nhập Băng thông!",
                  })}
                  onBlur={() => setValue("rateLimits", formValuesState.rateLimits.trim())}
                />
                {errors.rateLimits && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.rateLimits.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button className="btn btn-secondary me-3" type="reset" disabled={!isDirty} onClick={() => reset()}>
              Reset
            </button>
            <button className="btn btn-primary" type="submit" disabled={!isDirty}>
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(UpdateWifiProfileForm, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
