import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const ServiceIntroduction: React.FC = () => {
  const introduction = useSelector(getIntroductionSelector);
  return (
    <div className="service-introduction">
      <div className="row mx-0">
        <div className="col-lg-6 best-service-col">
          <h2 className="fs-2hx fw-bold pt-20 mt-5 mb-8">Best Service</h2>
          <div className="service-introduction__list">
            <div className="d-flex align-items-center mb-8">
              <div className="service-introduction__list__icon d-flex align-items-center justify-content-center rounded-circle bg-wimesh">
                <img
                  src="/assets/icons/loudspeaker.svg"
                  alt="loudspeaker icon"
                  width={50}
                  height={50}
                  className="img-white"
                />
              </div>
              <p className="mb-0 ms-6 fs-4">
                100% hoàn thành các chiến dịch quảng cáo phức hợp với độ khó cao
              </p>
            </div>
            <div className="d-flex align-items-center mb-8">
              <div className="service-introduction__list__icon d-flex align-items-center justify-content-center rounded-circle bg-wimesh">
                <img
                  src="/assets/icons/column-chart.svg"
                  alt="column chart icon"
                  width={50}
                  height={50}
                  className="img-white"
                />
              </div>
              <p className="mb-0 ms-6 fs-4">
                Số liệu quảng cáo rõ ràng và minh bạch (đã được chứng minh khi so sánh với các đơn
                vị tracking thứ ba uy tín như Sizmek, Double Click, Media Mind...)
              </p>
            </div>
            <div className="d-flex align-items-center mb-8">
              <div className="service-introduction__list__icon d-flex align-items-center justify-content-center rounded-circle bg-wimesh">
                <img
                  src="/assets/icons/hotline.svg"
                  alt="hotline icon"
                  width={50}
                  height={50}
                  className="img-white"
                />
              </div>
              <p className="mb-0 ms-6 fs-4">
                Tư vấn, lên kế hoạch và vận hành chiến dịch nhanh chóng và linh hoạt
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 position-relative">
          <div className="service-introduction__list__wrapper-img">
            <img src={introduction?.service.img} alt="service" className="w-100 h-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(ServiceIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
