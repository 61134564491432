import axios from "axios";
import { history } from "../routes/HistoryRouter";
import baseAPI from "./config";
import { generateUnixTimeNow, namekey } from "../utils";
import { TokenEntType } from "../types";
import tokenAPI from "./api/tokenAPI";
import { pathnameNotAuth } from "../utils/constants";

const axiosInstance = axios.create({
  baseURL: baseAPI,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    time: generateUnixTimeNow(),
    deviceId: localStorage.getItem(namekey.deviceId),
  },
});

let isRefreshing = false;
let isGettingToken = false;
let refreshRequestQueue: any = [];
let getTokenRequestQueue: any = [];

const processRefreshQueue = (error: any, token?: string) => {
  refreshRequestQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  refreshRequestQueue = [];
};

const processGetTokenQueue = (error: any, token?: string) => {
  getTokenRequestQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  getTokenRequestQueue = [];
};

axiosInstance.interceptors.request.use(
  async function (config) {
    const tokenEntString: string | null = localStorage.getItem(namekey.token);
    if (tokenEntString) {
      const tokenEnt: TokenEntType = JSON.parse(tokenEntString);
      config.headers.Authorization = `Bearer ${tokenEnt.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // User need login again
    if (error?.response?.status === 401) {
      let pathname = window.location.pathname;
      if (!pathnameNotAuth.includes(pathname)) {
        console.log(pathname);
        history.push("/login");
      }
    } else if (error?.response?.status === 400 && !originalRequest._retry) {
      if (isGettingToken) {
        try {
          const token = await new Promise(function (resolve, reject) {
            getTokenRequestQueue.push({ resolve, reject });
          });
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return await axios(originalRequest);
        } catch (err) {
          return await Promise.reject(err);
        }
      } else {
        originalRequest._retry = true;
        isGettingToken = true;

        return new Promise(async function (resolve, reject) {
          try {
            const resultTokenApi = await tokenAPI.getToken();
            if (resultTokenApi.data.result) {
              const newToken: TokenEntType = resultTokenApi.data.data;
              localStorage.setItem(namekey.token, JSON.stringify(newToken));
              originalRequest.headers.Authorization = `Bearer ${newToken.token}`;
              processGetTokenQueue(null, newToken.token);
              resolve(axios(originalRequest));
            }
          } catch (error) {
            processGetTokenQueue(error);
            reject(error);
          } finally {
            isGettingToken = false;
          }
        });
      }
    } else if (error?.response?.status === 419 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise(function (resolve, reject) {
            refreshRequestQueue.push({ resolve, reject });
          });
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return await axios(originalRequest);
        } catch (err) {
          return await Promise.reject(err);
        }
      } else {
        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(async function (resolve, reject) {
          try {
            const resultRefreshApi = await tokenAPI.refreshTokenn();
            if (resultRefreshApi.data.result) {
              const newToken: TokenEntType = resultRefreshApi.data.data;
              localStorage.setItem(namekey.token, JSON.stringify(newToken));
              originalRequest.headers.Authorization = `Bearer ${newToken.token}`;
              processRefreshQueue(null, newToken.token);
              resolve(axios(originalRequest));
            }
          } catch (error) {
            processRefreshQueue(error);
            reject(error);
          } finally {
            isRefreshing = false;
          }
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
