import React, { useContext, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { handleCheckAxiosError, handleFormatFileSize } from "../../../utils";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { uploadAPI } from "../../../services";
import { ProjectDetailType, UserType } from "../../../types";
import { setAccount } from "../../../redux/slices/accountSlice";
import { handleMessage } from "../../commons/Message";
import { SettingProjectUserContext } from "./context/context";
import { setNotificationList, setUserList } from "./context/reducer";

interface Props {
  projectDetail: ProjectDetailType;
}

const AddDeleteUserByFile: React.FC<Props> = ({ projectDetail }) => {
  const dispatch = useDispatch();
  const { state, dispatchContext } = useContext(SettingProjectUserContext);
  const { projectId, code } = projectDetail.profile;
  const [file, setFile] = useState<File | null>(null);
  const [radioIsAdd, setRadioIsAdd] = useState(true); // add is true, delete is false

  const handleChangeInputUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      if (radioIsAdd) {
        handleAddUserByfFile();
      } else {
        handleDeleteUserByfFile();
      }
    } catch (error) {
      handleCheckAxiosError("error", () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleAddUserByfFile = async () => {
    try {
      dispatch(setLoading(true));
      if (file) {
        const resultUploadUserFile = await uploadAPI.uploadUsersFileToProject(projectId, file);
        if (resultUploadUserFile.data.result) {
          dispatchContext(
            setUserList([...state.userList, ...resultUploadUserFile.data.data.success])
          );
          dispatchContext(setNotificationList(resultUploadUserFile.data.data.notification));
          setFile(null);
        } else {
          handleMessage("error", resultUploadUserFile.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteUserByfFile = async () => {
    try {
      dispatch(setLoading(true));
      if (file) {
        const resultUploadUserFile = await uploadAPI.uploadDeleteUserFileToProject(projectId, file);
        if (resultUploadUserFile.data.result) {
          const deletedUserEmailList: string[] = resultUploadUserFile.data.data.success.map(
            (item: UserType) => item.email
          );
          dispatchContext(
            setUserList(
              state.userList.filter((item) => deletedUserEmailList.includes(item.email) === false)
            )
          );
          dispatchContext(setNotificationList(resultUploadUserFile.data.data.notification));
          setFile(null);
        } else {
          handleMessage("error", resultUploadUserFile.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="add-delete-user-by-file">
      <div className="text-center mb-13">
        <h1 className="mb-3">Thêm / Xoá người dùng bằng File</h1>
        <div className="text-muted fw-semibold fs-5">
          Thêm / Xoá danh sách người dùng dự án {code}
        </div>
      </div>
      <div className="form-group">
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p className="required fw-semibold fs-6 mb-0">Vui lòng chọn phương thức xử lý file</p>
          <a href="https://wi-mesh.com" className="fs-7 d-block" target="_blank" rel="noreferrer">
            <i className="fa-solid fa-file-excel text-primary fs-7" /> Xem file mẫu
          </a>
        </div>
        <div className="d-flex align-items-center">
          <div className="form-check form-check-custom form-check-solid form-check-sm mb-5 me-5">
            <input
              className="form-check-input"
              type="radio"
              id="add-delete-user-by-file__add"
              checked={radioIsAdd}
              onChange={(e) => setRadioIsAdd(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="add-delete-user-by-file__add">
              Thêm người dùng
            </label>
          </div>
          <div className="form-check form-check-custom form-check-solid form-check-sm mb-5">
            <input
              className="form-check-input"
              type="radio"
              id="add-delete-user-by-file__delete"
              checked={!radioIsAdd}
              onChange={(e) => setRadioIsAdd(!e.target.checked)}
            />
            <label className="form-check-label" htmlFor="add-delete-user-by-file__delete">
              Xoá người dùng
            </label>
          </div>
        </div>
        <div className="dropzone dropzone-queue mb-2" id="kt_modal_upload_dropzone">
          <div className="dropzone-panel mb-4">
            <input
              type="file"
              accept=".xlsx"
              className="d-none"
              id="users-file"
              onChange={handleChangeInputUpload}
            />
            <label
              htmlFor="users-file"
              className="btn btn-light-primary btn-sm d-inline-flex align-items-center"
            >
              <i className="fa-solid fa-upload"></i>
              Tải file
            </label>
          </div>
          {file && (
            <div className="dropzone-items wm-200px">
              <div className="dropzone-item p-5">
                <div className="dropzone-file">
                  <div className="dropzone-filename text-gray-900" title="some_image_file_name.jpg">
                    <span data-dz-name="">{file.name}</span>
                    <strong>
                      &#40;
                      {handleFormatFileSize(file.size)}
                      &#41;
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <span className="form-text fs-6 text-muted">Allowed file types: xlsx.</span>
        <div className="mt-13 text-end">
          <button className="btn btn-primary" onClick={handleSubmit} disabled={!file}>
            Xong
          </button>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(AddDeleteUserByFile, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
