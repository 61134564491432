import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { useLocation } from "react-router-dom";

const ButtonGoToTop: React.FC = () => {
  const { pathname } = useLocation();
  const [displayBtn, setDisplayBtn] = useState("");

  // When the user scrolls down 100px from the top of the document, show the button
  window.onscroll = (): void => {
    scrollFunction();
  };

  function scrollFunction(): void {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      setDisplayBtn("block");
    } else {
      setDisplayBtn("none");
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function handleGoToTop(): void {
    document.body.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
  return (
    <button
      type="button"
      className={`border-0 btn p-0 ${
        pathname.startsWith("/dashboard") ? "btn-primary" : "bg-wimesh"
      }`}
      id="btn-back-to-top"
      onClick={handleGoToTop}
      style={{ display: displayBtn }}
    >
      <img src="/assets/icons/arrow-circle.svg" width="20px" alt="" />
    </button>
  );
};
export default withErrorBoundary(ButtonGoToTop, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
