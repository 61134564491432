import { createSlice } from "@reduxjs/toolkit";
import { IntroductionType } from "../../types";

export interface IntroductionState {
  isGetIntroductionData: boolean;
  data: IntroductionType | null;
}

const initialState: IntroductionState = {
  isGetIntroductionData: false,
  data: null,
};

export const introductionSlice = createSlice({
  name: "introduction",
  initialState,
  reducers: {
    setIntroduction: (state, action: { payload: IntroductionType }) => {
      state.data = { ...action.payload };
      if (!state.isGetIntroductionData) {
        state.isGetIntroductionData = true;
      }
    },
  },
});

export const { setIntroduction } = introductionSlice.actions;

export default introductionSlice.reducer;
