import md5 from "crypto-js/md5";

const generateDeviceId = () => {
  const nowDateString: string = new Date(Date.now()).toUTCString();
  //   const expire: Date = new Date(Date.now() + 86400 * 30 * 1000); // 30 day
  const numberRandom: number = Math.floor(Math.random() * 999999);
  let newDeviceId = `${md5(nowDateString + numberRandom)}`;
  newDeviceId = newDeviceId.toUpperCase();
  return `${newDeviceId}`;
};

export default generateDeviceId;
