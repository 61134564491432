import { createSlice } from "@reduxjs/toolkit";
import { MenuType } from "../../types";

export interface MenuState {
  data: MenuType[];
  accountRoles: string[] | null;
}

const initialState: MenuState = {
  data: [],
  accountRoles: null,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action: { payload: MenuType[] }) => {
      state.data = action.payload;
      const newAccountRoles: string[] = [];
      action.payload.forEach((menuItem) => {
        menuItem.arrSubMenu.forEach((subMenuItem) => {
          newAccountRoles.push(subMenuItem.url);
        });
      });
      state.accountRoles = newAccountRoles;
    },
  },
});

export const { setMenu } = menuSlice.actions;

export default menuSlice.reducer;
