import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const PartnerIntroduction: React.FC = () => {
  const introduction = useSelector(getIntroductionSelector);
  return (
    <div className="partner-introduction pt-15 bg-white">
      <div className="container-sm pb-10">
        <div className="targeting-introduction__header">
          <h2 className="fs-2hx text-center fw-bold pt-0 pt-md-5">Our Partners</h2>
        </div>
        <div className="targeting-introduction__list mt-10">
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
            slidesPerView={1}
            breakpoints={{
              368: { slidesPerView: 2, spaceBetween: 30 },
              576: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
            }}
          >
            {introduction?.partner.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item} alt="" height={150} className="d-block m-auto" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(PartnerIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
