import { NotificationType, UserType } from "../../../../types";
import { ConfirmationDataType } from "../ConfirmSettingUser";
import {
  SetUserList,
  SetUserListFilter,
  SetAction,
  SetNotificationList,
  SetSeletedEmailList,
  SetConfirmationData,
  SetUpatedUser,
  SettingProjectUserActions,
  ActionType,
} from "./actions";
import { SettingProjectUserState } from "./state";

export function settingProjectUserReducer(
  state: SettingProjectUserState,
  action: SettingProjectUserActions
): SettingProjectUserState {
  switch (action.type) {
    case ActionType.SetUserList:
      return { ...state, userList: action.payload };
    case ActionType.SetUserListFilter:
      return { ...state, userListFilter: action.payload };
    case ActionType.SetAction: {
      return { ...state, action: action.payload };
    }
    case ActionType.SetNotificationList: {
      return { ...state, notificationList: action.payload };
    }
    case ActionType.SetSelectedEmailList: {
      return { ...state, selectedEmailList: action.payload };
    }
    case ActionType.SetConfirmation: {
      if (action.payload) {
        return { ...state, confirmationData: action.payload, action: "CONFIRMATION" };
      } else {
        return { ...state, confirmationData: action.payload, action: null };
      }
    }
    case ActionType.SetUpdatedUser: {
      if (action.payload) {
        return { ...state, updatedUser: action.payload, action: "UPDATE_USER" };
      } else {
        return { ...state, updatedUser: action.payload, action: null };
      }
    }
    default:
      return state;
  }
}

// helper functions to simplify the caller
export const setUserList = (userList: UserType[]): SetUserList => ({
  type: ActionType.SetUserList,
  payload: userList,
});

export const setUserListFilter = (userListFilter: UserType[]): SetUserListFilter => ({
  type: ActionType.SetUserListFilter,
  payload: userListFilter,
});

export const setAction = (action: "ACTION_BY_FILE" | "CREATE_USER" | null): SetAction => ({
  type: ActionType.SetAction,
  payload: action,
});

export const setNotificationList = (notificationList: NotificationType[]): SetNotificationList => ({
  type: ActionType.SetNotificationList,
  payload: notificationList,
});

export const setSelectedEmailList = (selectedEmailList: string[]): SetSeletedEmailList => ({
  type: ActionType.SetSelectedEmailList,
  payload: selectedEmailList,
});

export const setConfirmationData = (
  confirmationData: ConfirmationDataType | null
): SetConfirmationData => ({
  type: ActionType.SetConfirmation,
  payload: confirmationData,
});

export const setUpdatedUser = (updatedUser: UserType | null): SetUpatedUser => ({
  type: ActionType.SetUpdatedUser,
  payload: updatedUser,
});
