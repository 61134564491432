import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./index.scss";

const TargetingIntroduction: React.FC = () => {
  return (
    <div className="targeting-introduction pt-15 bg-white" id="Targeting">
      <div className="container-sm pb-10">
        <div className="targeting-introduction__header">
          <h2 className="fs-2hx text-center fw-bold pt-0 pt-md-5">Targeting</h2>
          <p className="fs-sm-4">
            Target chính xác theo định hướng dữ liệu (Data-Driven Marketing)
          </p>
        </div>
        <div className="targeting-introduction__list row g-8 mt-1">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="targeting-introduction__list__item p-8 pb-5 h-100">
              <div className="targeting-introduction__item__icon m-auto rounded-circle d-flex align-items-center justify-content-center">
                <img src="/assets/icons/location.svg" alt="location icon" className="img-wimesh" />
              </div>
              <p className="text-center mt-3 fs-5 fw-bold">Geo-Fencing</p>
              <p className="text-center mt-4 fs-4 mb-0">
                Quảng cáo phân hoạch theo địa điểm hoặc khu vực
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="targeting-introduction__list__item p-8 pb-5 h-100">
              <div className="targeting-introduction__item__icon m-auto rounded-circle d-flex align-items-center justify-content-center">
                <img src="/assets/icons/users.svg" alt="account icon" className="img-wimesh" />
              </div>
              <p className="text-center mt-3 fs-5 fw-bold">Demographics</p>
              <p className="text-center mt-4 fs-4 mb-0">
                Quảng cáo hiển thị phù hợp theo độ tuổi và giới tính
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="targeting-introduction__list__item p-8 pb-5 h-100">
              <div className="targeting-introduction__item__icon m-auto rounded-circle d-flex align-items-center justify-content-center">
                <img src="/assets/icons/target.svg" alt="target icon" className="img-wimesh" />
              </div>
              <p className="text-center mt-3 fs-5 fw-bold">Data Management Platform</p>
              <p className="text-center mt-4 fs-4 mb-0">
                Target sâu hơn tới thói quen, hành vi, sở thích,...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(TargetingIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
