import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectCustomerType, ProjectDetailType } from "../../../types";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { Empty, Modal } from "antd";
import AddProjectCustomerForm from "./AddProjectCustomerForm";
import { useParams } from "react-router-dom";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

const SettingProjectCustomer: React.FC<Props> = ({ projectDetail }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [customerList, setCustomerList] = useState<ProjectCustomerType[]>([]);
  const [customerListFilter, setCustomerListFilter] = useState<ProjectCustomerType[]>([]);
  const [isShowModalAddCustomer, setIsShowModalAddCustomer] = useState(false);
  const [customerDelete, setCustomerDelete] = useState<ProjectCustomerType | null>(null);

  const handleFilterCustomerList = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    const newCustomerNotProjectFilter = customerList.filter(
      (item) =>
        `${item.id}`.toLowerCase().includes(value) ||
        item.fullName.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value) ||
        item.phone.toLowerCase().includes(value)
    );
    setCustomerListFilter(newCustomerNotProjectFilter);
  };

  const handleGetProjectCustomer = async (projectId: string) => {
    try {
      dispatch(setLoading(true));
      const resultGetProjectCustomer = await projectAPI.getProjectCustomer(projectId);
      if (resultGetProjectCustomer.data.result) {
        setCustomerList(resultGetProjectCustomer.data.data);
        setCustomerListFilter(resultGetProjectCustomer.data.data);
      } else {
        handleMessage("error", resultGetProjectCustomer.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteCustomer = async () => {
    if (!customerDelete) return;
    try {
      dispatch(setLoading(true));
      const resultDeleteCustomerFromProject = await projectAPI.deleteCustomerFromProject(
        projectDetail.profile.projectId,
        customerDelete.id
      );
      if (resultDeleteCustomerFromProject.data.result) {
        handleMessage("success", resultDeleteCustomerFromProject.data.msg);
        setCustomerList((prev) => prev.filter((item) => item.id !== customerDelete.id));
        setCustomerListFilter((prev) => prev.filter((item) => item.id !== customerDelete.id));
      } else {
        handleMessage("error", resultDeleteCustomerFromProject.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      setCustomerDelete(null);
    }
  };

  useEffect(() => {
    handleGetProjectCustomer(projectDetail.profile.projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail.profile.projectId]);

  useEffect(() => {});

  return (
    <div className="setting-project-customer collapse show">
      <div className="card-body border-top p-9">
        <div className="card-toolbar">
          <div className="d-flex flex-end flex-wrap gap-4">
            {id && (
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setIsShowModalAddCustomer(true)}
                >
                  Thêm
                </button>
              </div>
            )}
            <div className="position-relative my-1">
              <img
                src="/assets/icons/search.svg"
                alt=""
                className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
              />
              <input
                type="text"
                data-kt-table-widget-4="search"
                className="form-control form-control-sm w-125px fs-7 ps-12"
                placeholder="Search"
                onInput={handleFilterCustomerList}
              />
            </div>
          </div>
        </div>
        <div
          id="kt_table_widget_4_table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer"
              id="kt_table_widget_4_table"
            >
              <thead>
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-100px sorting_disabled">ID</th>
                  <th className="min-w-100px sorting_disabled">Email</th>
                  <th className="text-start min-w-100px sorting_disabled">Số điện thoại</th>
                  <th className="text-start min-w-125px sorting_disabled">Tên</th>
                  {id && <th className="text-start min-w-100px sorting_disabled"></th>}
                </tr>
              </thead>
              <tbody className="fw-bold text-gray-600">
                {customerList.length === 0 || customerListFilter.length === 0 ? (
                  <tr>
                    <td colSpan={5}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  customerListFilter.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-start">{item.id}</td>
                        <td className="text-start">{item.email}</td>
                        <td className="text-start">{item.phone}</td>
                        <td className="text-start">{item.fullName}</td>
                        {id && (
                          <td className="text-center">
                            <button
                              className="btn btn-link text-hover-danger"
                              onClick={() => setCustomerDelete(item)}
                            >
                              <i className="fa-regular fa-trash-can fs-2"></i>
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        open={isShowModalAddCustomer}
        footer={null}
        onCancel={() => setIsShowModalAddCustomer(false)}
        style={{ top: 20 }}
        destroyOnClose
      >
        <AddProjectCustomerForm
          projectId={projectDetail.profile.projectId}
          projectCode={projectDetail.profile.code}
          onCloseForm={() => setIsShowModalAddCustomer(false)}
          onAddCustomerSuccess={(customerList: ProjectCustomerType[]) => {
            setCustomerList((prev) => [...prev, ...customerList]);
            setCustomerListFilter((prev) => [...prev, ...customerList]);
          }}
        />
      </Modal>
      <Modal
        open={!!customerDelete}
        footer={null}
        onCancel={() => setCustomerDelete(null)}
        destroyOnClose
      >
        {!!customerDelete && (
          <div className="py-20">
            <div className="text-center mb-10">
              <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
            </div>
            <div className="text-center mb-10">
              Bạn có chắc chắn muốn xoá <span className="fw-bolder">{customerDelete.email}</span>{" "}
              khỏi dự án <span className="fw-bolder">{projectDetail.profile.code}</span> ?
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-light-primary mx-2"
                onClick={() => setCustomerDelete(null)}
              >
                Huỷ
              </button>
              <button
                type="button"
                className="btn btn-light-danger mx-2"
                onClick={handleDeleteCustomer}
              >
                Tôi chắc chắn
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default withErrorBoundary(SettingProjectCustomer, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
