import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { LoginReportType } from "../../types";
import { setLoading } from "../../redux/slices/loadingSlice";
import { handleMessage } from "../../components/commons/Message";
import { convertUnixTimeToTime, handleCheckAxiosError } from "../../utils";
import { setAccount } from "../../redux/slices/accountSlice";
import { loginReportAPI } from "../../services";
import DateRangePicker from "../../components/commons/DateRangePicker ";
import { Tooltip } from "antd";

type TimeFilterType = {
  startDate: number;
  endDate: number;
};

const LoginReportDetailPage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const now = moment();
  const [loginReportDetail, setLoginReportDetail] = useState<LoginReportType | null>(null);
  const [timeFilter, setTimeFilter] = useState<TimeFilterType>({
    startDate: moment(now.startOf("D")).unix(), // start of today
    endDate: moment(now.add(1, "days").startOf("D")).unix(), // start of tomorrow
  });

  const handleGetLoginReportDetail = async (id: string | null) => {
    try {
      dispatch(setLoading(true));
      const resultLoginReportDetailApi = await loginReportAPI.getLoginReportDetail(
        id,
        timeFilter.startDate,
        timeFilter.endDate
      );
      if (resultLoginReportDetailApi.data.result) {
        setLoginReportDetail(resultLoginReportDetailApi.data.data);
      } else {
        handleMessage("error", resultLoginReportDetailApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDateRangePickerChange = (start: number, end: number) => {
    setTimeFilter({ startDate: start, endDate: end });
  };

  useEffect(() => {
    handleGetLoginReportDetail(id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="login-report-detail-page">
      <div className="card mb-5">
        <div className="card-header border-0 min-h-50px">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Tìm kiếm</h3>
          </div>
        </div>
        <div className="collapse show">
          <div className="card-body border-top px-9 py-2">
            <div className="col-12">
              <div className="mb-4">
                <label className="col-form-label col-form-label-md fw-semibold fs-6x text-end">Thời gian</label>
                <DateRangePicker
                  startDate={moment.unix(timeFilter.startDate).format("DD/MM/YYYY HH:mm")}
                  endDate={moment.unix(timeFilter.endDate).format("DD/MM/YYYY HH:mm")}
                  name="publicDate"
                  className="form-control form-control-md bg-transparent"
                  onChange={(start, end) => handleDateRangePickerChange(start, end)}
                />
              </div>
            </div>
            <div className="col-12">
              <button className="btn btn-primary mb-4" onClick={() => handleGetLoginReportDetail(id || null)}>
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-flush mb-5 h-xl-100">
        <div className="card-header rounded bg-info align-items-start h-200px" data-bs-theme="light">
          <h3 className="card-title align-items-start flex-column text-white pt-5">
            <span className="fw-bold fs-2x mb-3">Thống kê đăng nhập</span>
            {loginReportDetail && (
              <p className="mb-2">
                Từ <span className="fw-bold text-warning">{convertUnixTimeToTime(loginReportDetail.startDate)}</span>{" "}
                đến <span className="fw-bold text-warning">{convertUnixTimeToTime(loginReportDetail.endDate)}</span>
              </p>
            )}
            <span className="fs-4 text-white opacity-75">{loginReportDetail?.projectName}</span>
          </h3>
        </div>
        <div className="card-body mt-n20">
          <div className="mt-n20 position-relative">
            <div className="row g-3 g-lg-6">
              <div className="col-4">
                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="fa-solid fa-globe fs-2x text-primary"></i>
                    </span>
                  </div>
                  <div className="m-0">
                    <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                      {loginReportDetail?.totalAttempts}
                    </span>
                    <span className="text-gray-500 fw-semibold fs-6">Máy đăng nhập</span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="fa-solid fa-circle-check fs-2x text-success"></i>
                    </span>
                  </div>
                  <div className="m-0">
                    <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                      {loginReportDetail?.successfulAttempts}
                    </span>
                    <span className="text-gray-500 fw-semibold fs-6">Thành công</span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <i className="fa-solid fa-circle-exclamation fs-2x text-danger"></i>
                    </span>
                  </div>
                  <div className="m-0">
                    <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                      {loginReportDetail?.failedAttempts}
                    </span>
                    <span className="text-gray-500 fw-semibold fs-6">Thất bại</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-5">
        <div className="card-body">
          <ul className="nav nav-pills nav-pills-custom mb-3" role="tablist">
            {loginReportDetail?.loginAttemptTypes.map((item) => {
              return (
                <li key={item.type} className="nav-item mb-3 me-3 me-lg-6" role="presentation">
                  <div
                    className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden active w-80px h-85px py-4"
                    data-bs-toggle="pill"
                    role="tab"
                  >
                    <span className="fs-2x text-gray-600">{item.totalAttempts}</span>
                    <span className="nav-text text-gray-700 fw-bold fs-6 lh-1">
                      {item.type.toLowerCase().charAt(0).toUpperCase() + item.type.toLowerCase().slice(1)}
                    </span>
                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                  </div>
                </li>
              );
            })}
          </ul>
          <p className="fs-4 fw-bold">Chi tiết thống kê</p>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800">
                  <th className="text-center">STT</th>
                  <th className="text-center">Thiết bị</th>
                  <th className="text-center">MAC</th>
                  <th className="text-center">Hình thức</th>
                  <th className="text-center">Username</th>
                  <th className="text-center">Trạng thái</th>
                  <th className="text-center">Thời gian</th>
                </tr>
              </thead>
              <tbody>
                {loginReportDetail?.deviceLogins.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item.deviceType}</td>
                      <td>{item.mac}</td>
                      <td>{item.loginType}</td>
                      <td className="text-center">{item.loginType !== "GUEST" && item.username}</td>
                      <td className="text-center">
                        {item.success ? (
                          <span className="text-success">Thành công</span>
                        ) : (
                          <Tooltip title={item.msg}>
                            <span className="d-inline-flex align-items-center text-danger">
                              Thất bại <i className="fa-regular fa-circle-question text-danger ms-1"></i>
                            </span>
                          </Tooltip>
                        )}
                      </td>
                      <td>{item.loginTime}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(LoginReportDetailPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
