import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./scss/LoginTemplate.scss";
import { Link, Navigate, useLocation } from "react-router-dom";
import "./scss/LoginTemplate.scss";
import { useSelector } from "react-redux";
import { getAccountSelector, getIsIntroductionOpenSelector, getIsRegistrationOpenSelector } from "../redux/selectors";

interface Props {
  component: React.FC;
}

const LoginTemplate: React.FC<Props> = ({ component: Component }) => {
  const { pathname } = useLocation();
  const account = useSelector(getAccountSelector);
  const isIntroductionOpen = useSelector(getIsIntroductionOpenSelector);
  const isRegistrationOpen = useSelector(getIsRegistrationOpenSelector);

  return (
    <>
      {account ? (
        <Navigate to={isIntroductionOpen ? "/" : "/dashboard"} />
      ) : (
        <div className="login-template">
          <div className="row g-0">
            <div className="col-12 col-md-6 order-1 order-md-0">
              <div className="d-flex flex-column align-items-center">
                <Link to="/">
                  <img src="/assets/images/logo.png" alt="" className="py-5 my-5" />
                </Link>
                <Component />
              </div>
            </div>
            <div className="col-12 col-md-6 bg-gradient-wimesh order-0 order-md-1">
              <div className="d-flex align-items-center justify-content-center h-100 py-5">
                <div className="py-5">
                  <p className="fs-3 mb-2 text-white text-center fw-bold">Chào mừng bạn đến Wi-MESH</p>
                  <p className="text-white text-center fs-5">
                    {pathname === "/signup"
                      ? "Bạn đã có tài khoản?"
                      : pathname === "/forgot-password"
                      ? "Bạn vui lòng điền thông tin để lấy lại mật khẩu"
                      : isRegistrationOpen
                      ? "Bạn chưa có tài khoản?"
                      : ""}
                  </p>
                  {pathname === "/login" && !isRegistrationOpen ? (
                    ""
                  ) : (
                    <div className="text-center">
                      <Link
                        id="switch-form-btn"
                        to={pathname === "/login" ? "/signup" : "/login"}
                        className="btn d-inline-block py-2 px-4 fs-5 text-decoration-none rounded-pill mx-auto"
                      >
                        {pathname === "/signup"
                          ? "Đăng nhập"
                          : pathname === "/forgot-password"
                          ? "Quay lại trang đăng nhập"
                          : "Đăng ký"}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withErrorBoundary(LoginTemplate, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
