import React, { useContext, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { NotificationType } from "../../../types";
import { SettingProjectUserContext } from "./context/context";
import { setNotificationList } from "./context/reducer";

interface Props {
  notificationList: NotificationType[];
}

const NotificationSettingUser: React.FC<Props> = ({ notificationList }) => {
  const { dispatchContext } = useContext(SettingProjectUserContext);
  const failedNotificationList = notificationList.filter((item) => item.status === false);
  const successNotificationList = notificationList.filter((item) => item.status === true);
  const [isNotificationDetail, setIsNotificationDetail] = useState(false);

  return (
    <div className="notification-setting-user">
      <div className="text-center mb-13">
        <h1 className="mb-3">Thông báo</h1>
      </div>
      <div>
        {successNotificationList.length > 0 && (
          <p className="badge badge-light-success py-4 d-flex justify-content-between fs-5 text-start">
            Thao tác THÀNH CÔNG {successNotificationList.length} người dùng vào dự án.
          </p>
        )}
        {failedNotificationList.length > 0 && (
          <p className="badge badge-light-danger py-4 d-flex justify-content-between fs-5 text-start">
            <span>Thao tác THẤT BẠI {failedNotificationList.length} người dùng</span>
            {isNotificationDetail ? (
              <span className="btn btn-link p-0" onClick={() => setIsNotificationDetail(false)}>
                Ẩn
              </span>
            ) : (
              <span className="btn btn-link p-0" onClick={() => setIsNotificationDetail(true)}>
                Chi tiết
              </span>
            )}
          </p>
        )}
      </div>
      {isNotificationDetail && (
        <>
          <h3 className="fw-bold mb-5 mt-10 text-center">Chi tiết thao tác thất bại</h3>
          <div style={{ maxHeight: "40vh" }} className="overflow-scroll px-1">
            {failedNotificationList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`alert d-flex align-items-center p-3 ${
                    item.status ? "alert-success" : "alert-danger"
                  }`}
                >
                  {item.status ? (
                    <i className="fa-solid fa-circle-check fs-2 text-success me-4"></i>
                  ) : (
                    <i className="fa-solid fa-circle-exclamation fs-2 text-danger me-4"></i>
                  )}
                  <div className="d-flex flex-column">
                    <span className="text-break">
                      {item.email} - {item.reason}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className="text-end mt-10">
        <button
          type="button"
          className="btn btn-primary mx-2"
          onClick={() => dispatchContext(setNotificationList([]))}
        >
          Xong
        </button>
      </div>
    </div>
  );
};

export default withErrorBoundary(NotificationSettingUser, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
