import React, { Fragment, useContext } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, UserType } from "../../../types";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { SettingProjectUserContext } from "./context/context";
import {
  setAction,
  setConfirmationData,
  setNotificationList,
  setUserList,
} from "./context/reducer";

type ConfirmationType = "DELETE" | "RESET_PASS" | "DISABLE_STATUS";

export interface ConfirmationDataType {
  type: ConfirmationType;
  emailList: string[];
}

interface Props {
  projectDetail: ProjectDetailType;
  confirmationData: ConfirmationDataType | null;
}

const ConfirmSettingUser: React.FC<Props> = ({ projectDetail, confirmationData }) => {
  const dispatch = useDispatch();
  const { state, dispatchContext } = useContext(SettingProjectUserContext);
  const { projectId } = projectDetail.profile;

  const handleSubmit = () => {
    if (confirmationData) {
      switch (confirmationData.type) {
        case "DELETE":
          handleDeleteUser(confirmationData.emailList);
          break;
        case "DISABLE_STATUS":
          handleDisableUserStatus(confirmationData.emailList);
          break;
        default:
          // default is type = "RESET_PASS"
          handleResetUserPass(confirmationData.emailList);
          break;
      }
    }
  };

  const handleDisableUserStatus = async (emailList: string[]) => {
    try {
      dispatch(setLoading(true));
      const resultChangeUserStatusApi = await projectAPI.changeUserStatus(
        projectId,
        emailList,
        false
      );
      if (resultChangeUserStatusApi.data.result) {
        const userListDisableSuccess: UserType[] = resultChangeUserStatusApi.data.data.success;
        const emailListDisableSuccess = userListDisableSuccess.map((item) => item.email);
        dispatchContext(
          setUserList(
            state.userList.map((item) => {
              if (emailListDisableSuccess.includes(item.email)) {
                item.status = false;
              }
              return item;
            })
          )
        );
        dispatchContext(setNotificationList(resultChangeUserStatusApi.data.data.notification));
      } else {
        handleMessage("error", resultChangeUserStatusApi.data.msg);
      }
    } catch (error) {
      dispatchContext(setConfirmationData(null));
      dispatchContext(setAction(null));
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteUser = async (emailList: string[]) => {
    try {
      dispatch(setLoading(true));
      const resultDeleteUserToProjectApi = await projectAPI.deleteUserToProject(
        projectId,
        emailList
      );
      if (resultDeleteUserToProjectApi.data.result) {
        const deleteUserList: UserType[] = resultDeleteUserToProjectApi.data.data.success;
        const deleteEmailList = deleteUserList.map((item) => item.email);
        dispatchContext(
          setUserList(
            state.userList.filter((item) => deleteEmailList.includes(item.email) === false)
          )
        );
        dispatchContext(setNotificationList(resultDeleteUserToProjectApi.data.data.notification));
      } else {
        handleMessage("error", resultDeleteUserToProjectApi.data.msg);
      }
    } catch (error) {
      dispatchContext(setConfirmationData(null));
      dispatchContext(setAction(null));
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleResetUserPass = async (emailList: string[]) => {
    try {
      dispatch(setLoading(true));

      const resultResetUserPassApi = await projectAPI.resetUserPass(projectId, emailList);
      if (resultResetUserPassApi.data.result) {
        dispatchContext(setConfirmationData(null));
        dispatchContext(setAction(null));
        dispatchContext(setNotificationList(resultResetUserPassApi.data.data.notification));
      } else {
        handleMessage("error", resultResetUserPassApi.data.msg);
      }
    } catch (error) {
      dispatchContext(setConfirmationData(null));
      dispatchContext(setAction(null));
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Fragment>
      {confirmationData && (
        <div className="confirm-setting-user py-10">
          <div className="text-center mb-10">
            <i className="fa-solid fa-circle-info fs-2hx text-warning"></i>
          </div>
          <div
            className={`${confirmationData.emailList.length === 1 ? "text-center mb-10" : "mb-4"}`}
          >
            Bán có muốn&nbsp;
            <span className="fw-bolder text-uppercase">
              {confirmationData.type === "DELETE"
                ? "xoá"
                : confirmationData.type === "DISABLE_STATUS"
                ? "khoá"
                : "reset mật khẩu"}
              &nbsp;
            </span>
            <span>
              {confirmationData.emailList.length > 1 ? (
                "các email bên dưới?"
              ) : (
                <span>
                  email <span className="fw-bolder">{confirmationData.emailList[0]}</span>?
                </span>
              )}
            </span>
          </div>
          {confirmationData.emailList.length > 1 && (
            <div className="d-flex flex-column mb-10">
              {confirmationData.emailList.map((item) => {
                return (
                  <li key={item} className="d-flex align-items-center py-2">
                    <span className="bullet bullet-line me-5"></span> {item}
                  </li>
                );
              })}
            </div>
          )}
          <div className="text-center">
            <button
              type="button"
              className="btn btn-light-primary mx-2"
              onClick={() => dispatchContext(setConfirmationData(null))}
            >
              Huỷ
            </button>
            <button type="button" className="btn btn-light-danger mx-2" onClick={handleSubmit}>
              Tôi chắc chắn
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withErrorBoundary(ConfirmSettingUser, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
