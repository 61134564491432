import React from "react";
import { withErrorBoundary } from "react-error-boundary";

const WelcomDashboardPage: React.FC = () => {
  return (
    <div className="welcome-dashboard-page h-100 d-flex flex-column align-items-center justify-content-center">
      <img src="/assets/images/logo.png" alt="" className="mb-4" />
      <h3 className="mb-0">Welcome to Dashboard</h3>
    </div>
  );
};

export default withErrorBoundary(WelcomDashboardPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
