import { handleMessage } from "../../components/commons/Message";
import { pathnameNotAuth } from "../constants";

const handleCheckAxiosError = (error: any, clearAccountInfo: () => void) => {
  const statusCode = error?.response?.status;
  const apiUrl = error?.config?.url;
  const location = window.location.pathname;
  if (statusCode === 401) {
    clearAccountInfo();
    if (pathnameNotAuth.includes(location) && apiUrl === "/auth/info") return;
    else {
      handleMessage("error", "Vui lòng đăng nhập để tiếp tục.");
    }
  } else {
    handleMessage("error", "Lỗi hệ thống. Vui lòng thử lại.");
  }
};

export default handleCheckAxiosError;
