import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./scss/introduction-page.scss";
import HeaderIntroduction from "../components/introduction/HeaderIntroduction";
import MenuIntroduction from "../components/introduction/MenuIntroduction";
import BannerIntroduction from "../components/introduction/BannerIntroduction";
import AboutIntroduction from "../components/introduction/AboutIntroduction";
import FormatsIntroduction from "../components/introduction/FormatsIntroduction";
import DifferentiationsIntroduction from "../components/introduction/DifferentiationsIntroduction";
import LocationsIntroduction from "../components/introduction/LocationsIntroduction";
import TargetingIntroduction from "../components/introduction/TargetingIntroduction";
import ServiceIntroduction from "../components/introduction/ServiceIntroduction";
import PartnerIntroduction from "../components/introduction/PartnerIntroduction";
import FormIntroduction from "../components/introduction/FormIntroduction";
import FooterIntroduction from "../components/introduction/FooterIntroduction";
import { useSelector } from "react-redux";
import { getIsGetIntroductionSelector, getIsIntroductionOpenSelector } from "../redux/selectors";
import { Navigate } from "react-router-dom";

const IntroductionPage: React.FC = () => {
  const isGetIntroduction = useSelector(getIsGetIntroductionSelector);
  const isIntroductionOpen = useSelector(getIsIntroductionOpenSelector);
  return (
    <div className="introduction-page">
      {!isGetIntroduction ? (
        <></>
      ) : isIntroductionOpen ? (
        <>
          <HeaderIntroduction />
          <div id="menu-pc" className="d-none d-md-block position-sticky top-0">
            <MenuIntroduction />
          </div>
          <BannerIntroduction />
          <AboutIntroduction />
          <FormatsIntroduction />
          <DifferentiationsIntroduction />
          <LocationsIntroduction />
          <TargetingIntroduction />
          <ServiceIntroduction />
          <PartnerIntroduction />
          <FormIntroduction />
          <FooterIntroduction />
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default withErrorBoundary(IntroductionPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
