import handleMd5 from "../../utils/functions/handleMd5";
import axiosInstance from "../axiosInstance";

const accountAPI = {
  // LOGIN
  login: (account: string, password: string) => {
    return axiosInstance.post("/auth/login", {
      email: account,
      password: handleMd5(password),
    });
  },
  // LOGOUT
  logout: () => {
    return axiosInstance.post("/auth/logout", undefined);
  },
  // GET ACCOUNT INFORMATION
  getInfo: () => {
    return axiosInstance.post("/auth/info", undefined);
  },
  // CHANGE PASSWORD
  changePassword: (oldPassword: string, newPassword: string) => {
    return axiosInstance.post("/auth/changePassword", {
      oldPassword: handleMd5(oldPassword),
      newPassword: handleMd5(newPassword),
    });
  },
  // FORGOT PASSWORD
  forgotPassword: (email: string) => {
    return axiosInstance.post("/auth/forgotPassword", {
      phoneOrEmail: email,
    });
  },
  // CONFIRM OTP
  confirmOTP: (email: string, otp: string) => {
    return axiosInstance.post("/auth/confirmOtp", {
      email,
      otp,
    });
  },
  // FORGOT PASSWORD LOGIN WIFI - GET INFO FORM
  getFormForgotPasswordWifi: (key: string, code: string) => {
    return axiosInstance.post("/forgot-pass-wifi", {
      key,
      code,
    });
  },
  // CHANGE PASSWORD LOGIN WIFI - FORGOT PASSWORD LOGIN WIFI
  changePasswordWifi: (email: string, newPassword: string, key: string) => {
    return axiosInstance.post("/forgot-pass-wifi/change", {
      key,
      email,
      newPassword,
    });
  },
};

export default accountAPI;
