import React from "react";
import { Button, Modal, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getModalChangePasswordSelector } from "../../../redux/selectors";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setShowModalChangePassword } from "../../../redux/slices/appSlice";
import { handleMessage } from "../../commons/Message";
import { accountAPI } from "../../../services";
import { withErrorBoundary } from "react-error-boundary";
import { setAccount } from "../../../redux/slices/accountSlice";
import { handleCheckAxiosError } from "../../../utils";

// ********************************************************** \\

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

// ********************************************************** \\

const ModalChangePassword: React.FC = () => {
  const isOpen: boolean = useSelector(getModalChangePasswordSelector);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleOk = () => {
    // click btn submit form when click btn ok modal
    const btnSubmit: HTMLElement | null = document.querySelector("#btnSubmitChangePassword");
    btnSubmit?.click();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(setShowModalChangePassword(false));
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      dispatch(setLoading(true));
      const resultChangePassword = await accountAPI.changePassword(
        values.oldPassword,
        values.newPassword
      );
      handleMessage(
        resultChangePassword.data.result ? "success" : "error",
        resultChangePassword.data.msg
      );
      resultChangePassword.data.result && dispatch(setShowModalChangePassword(false));
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Thay đổi"
      cancelText="Huỷ"
    >
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
      >
        <Form.Item className="mb-0">
          <Button
            type="primary"
            htmlType="submit"
            id="btnSubmitChangePassword"
            style={{ opacity: 0, visibility: "hidden" }}
          >
            Thay đổi
          </Button>
        </Form.Item>
        <Form.Item
          label="Mật khẩu cũ"
          name="oldPassword"
          rules={[{ required: true, message: "Vui lòng nhập mật khẩu cũ!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Mật khẩu mới"
          name="newPassword"
          rules={[
            { required: true, message: "Vui lòng nhập mật khẩu mới!" },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
              message: "Mật khẩu ít nhất 8 ký tự, bao gồm ít nhất 1 chữ cái và 1 chữ số.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Nhập lại mật khẩu mới"
          name="confirmNewPassword"
          rules={[
            {
              required: true,
              message: "Vui lòng xác nhận mật khẩu mới!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Mật khẩu không trùng khớp!");
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withErrorBoundary(ModalChangePassword, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
