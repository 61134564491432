import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";
import IntroductionPage from "../pages/IntroductionPage";
import LoginPage from "../pages/LoginPage";
import LoginTemplate from "../templates/LoginTemplate";
import SignUpPage from "../pages/SignUpPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import DashboardTemplate from "../templates/DashboardTemplate";
import ProjectPage from "../pages/Project/ProjectPage";
import ProtectedRoute from "./ProtectedRoute";
import WelcomDashboardPage from "../pages/WelcomDashboardPage";
import CustomerPage from "../pages/CustomerPage";
import ProjectDetailPage from "../pages/Project/ProjectDetailPage";
import AdminPage from "../pages/AdminPage";
import LoginReportPage from "../pages/LoginReport/LoginReportPage";
import LoginReportDetailPage from "../pages/LoginReport/LoginReportDetailPage";
import ForgotPassLoginWifiPage from "../pages/ForgotPassLoginWifiPage";
import CheckOsPage from "../pages/CheckOsPage";

const dashboardRouteList = [
  {
    path: "project/list",
    element: ProjectPage,
  },
  {
    path: "project/list/:id",
    element: ProjectDetailPage,
  },
  {
    path: "customer/list",
    element: CustomerPage,
  },
  {
    path: "admin/list",
    element: AdminPage,
  },
  {
    path: "profile",
    element: ProjectDetailPage,
  },
  {
    path: "loginReport/list",
    element: LoginReportPage,
  },
  {
    path: "loginReport/list/:id",
    element: LoginReportDetailPage,
  },
  {
    path: "loginReport",
    element: LoginReportDetailPage,
  },
];

const MyRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<IntroductionPage />} />
      <Route path="/login" element={<LoginTemplate component={LoginPage} />} />
      <Route path="/signup" element={<LoginTemplate component={SignUpPage} />} />
      <Route path="/forgot-password" element={<LoginTemplate component={ForgotPasswordPage} />} />
      <Route path="/forgot-password-wifi" element={<ForgotPassLoginWifiPage />} />
      <Route path="/check-captive/ios" element={<CheckOsPage />} />
      <Route path="/dashboard">
        <Route index element={<DashboardTemplate component={WelcomDashboardPage} />} />
        {dashboardRouteList.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={
                <ProtectedRoute>
                  <DashboardTemplate component={item.element} />
                </ProtectedRoute>
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default withErrorBoundary(MyRoutes, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
