import React, { useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { ProjectDetailType, ProjectDeviceCreatedType, ProjectDeviceType } from "../../../types";
import { Select } from "antd";

interface Props {
  projectDetail: ProjectDetailType;
  onSuccess: (newDevice: ProjectDeviceType) => void;
}

const CreateAdminForm: React.FC<Props> = ({ projectDetail, onSuccess }) => {
  const dispatch = useDispatch();
  const projectId = projectDetail.profile.projectId;
  const deviceEntTypeList = projectDetail.profile.selectList.deviceEntTypeList;

  const {
    register,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<ProjectDeviceCreatedType>({
    defaultValues: {
      projectId: projectId,
      ip: "",
      deviceUserName: "",
      devicePassword: "",
      port: 21,
      deviceEntTypeId: deviceEntTypeList[0].value,
    },
  });
  const formValuesState = watch();

  const onSubmit: SubmitHandler<ProjectDeviceCreatedType> = async (formData) => {
    try {
      dispatch(setLoading(true));
      const resultCreateCustomerApi = await projectAPI.createDevice(formData);
      if (resultCreateCustomerApi.data.result) {
        handleMessage("success", resultCreateCustomerApi.data.msg);
        onSuccess(resultCreateCustomerApi.data.data);
      } else {
        handleMessage("error", resultCreateCustomerApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    register("deviceEntTypeId");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-device-form">
      <div className="text-center mb-6 mb-lg-10">
        <h1 className="mb-3">Tạo mới thiết bị</h1>
      </div>
      <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Host/IP</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.ip && "border-danger"}`}
                  placeholder="IP"
                  {...register("ip", {
                    required: "Vui lòng nhập IP!",
                  })}
                  onBlur={() => setValue("ip", formValuesState.ip.trim())}
                />
                {errors.ip && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.ip.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Port</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="number"
                  className={`form-control form-control-lg ${errors.port && "border-danger"}`}
                  placeholder="Port"
                  {...register("port", {
                    required: "Vui lòng nhập Port!",
                  })}
                />
                {errors.port && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.port.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Device username</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.deviceUserName && "border-danger"}`}
                  placeholder="Device username"
                  {...register("deviceUserName", {
                    required: "Vui lòng nhập Device username!",
                  })}
                  onBlur={() => setValue("deviceUserName", formValuesState.deviceUserName.trim())}
                />
                {errors.deviceUserName && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.deviceUserName.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Device password</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <input
                  type="password"
                  className={`form-control form-control-lg ${errors.devicePassword && "border-danger"}`}
                  placeholder="Device password"
                  {...register("devicePassword", {
                    required: "Vui lòng nhập Device password!",
                  })}
                  onBlur={() => setValue("devicePassword", formValuesState.devicePassword.trim())}
                />
                {errors.devicePassword && (
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                    {errors.devicePassword.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6">
          <label className="col-lg-4 col-form-label required fw-semibold fs-6">Loại thiết bị</label>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12 fv-row fv-plugins-icon-container">
                <Select
                  className="w-100 fw-bolder"
                  value={formValuesState.deviceEntTypeId}
                  onChange={(value) => setValue("deviceEntTypeId", value)}
                  options={deviceEntTypeList}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-lg-6 mt-6">
          <div className="col-12 text-end">
            <button className="btn btn-secondary me-3" type="reset" onClick={() => reset()}>
              Reset
            </button>
            <button className="btn btn-primary" type="submit">
              Tạo
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(CreateAdminForm, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
