import React, { useEffect, useState } from "react";
import { Empty, Modal, Select } from "antd";
import { withErrorBoundary } from "react-error-boundary";
import { handleMessage } from "../../components/commons/Message";
import { useDispatch, useSelector } from "react-redux";
import { projectAPI } from "../../services";
import { setLoading } from "../../redux/slices/loadingSlice";
import { ProjectType } from "../../types";
import { Link } from "react-router-dom";
import "../scss/project-page.scss";
import { setAccount } from "../../redux/slices/accountSlice";
import { handleCheckAxiosError } from "../../utils";
import CreateProjectForm from "../../components/dashboard/CreateProjectForm";
import ImagePreview from "../../components/commons/ImagePreview";
import { getAccountSelector, getProjectListSelector } from "../../redux/selectors";
import { setProjectList } from "../../redux/slices/projectListSlice";

const ProjectPage: React.FC = () => {
  const dispatch = useDispatch();
  const account = useSelector(getAccountSelector);
  const projectList = useSelector(getProjectListSelector);
  const [projectListFilter, setProjectListFilter] = useState<ProjectType[]>(projectList);
  const [projectIdDelete, setProjectIdDelete] = useState<number | null>(null);
  const [isShowCreateProjectForm, setIsShowCreateProjectForm] = useState(false);

  const handleGetProjectList = async () => {
    try {
      dispatch(setLoading(true));
      const resultProjectApi = await projectAPI.getProjectList();
      if (resultProjectApi.data.result) {
        dispatch(setProjectList(resultProjectApi.data.data));
        setProjectListFilter(resultProjectApi.data.data);
      } else {
        console.log(resultProjectApi.data);
        handleMessage("error", resultProjectApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeProjectType = async (value: string, project: ProjectType) => {
    try {
      dispatch(setLoading(true));
      const payload = { ...project, type: value };
      const resultChangeLoginType = await projectAPI.changeLoginType(payload);
      handleMessage(resultChangeLoginType.data.result ? "success" : "error", resultChangeLoginType.data.msg);
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteProject = async () => {
    try {
      dispatch(setLoading(true));
      if (projectIdDelete) {
        const resultDeleteProjectApi = await projectAPI.deleteProject(projectIdDelete);
        if (resultDeleteProjectApi.data.result) {
          handleMessage("success", resultDeleteProjectApi.data.msg);
          const newProjectList = projectList.filter((item) => item.id !== projectIdDelete);
          setProjectList(newProjectList);
          setProjectListFilter(newProjectList);
          const inputSearchEle: HTMLInputElement | null = document.querySelector("#project-page__search-input");
          if (inputSearchEle) {
            inputSearchEle.value = "";
          }
        } else {
          handleMessage("error", resultDeleteProjectApi.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      setProjectIdDelete(null);
    }
  };

  const handleInputSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    const newProjectListFilter = projectList.filter(
      (item) =>
        `${item.id}`.toLowerCase().includes(value) ||
        item.name.toLowerCase().includes(value) ||
        item.code.toLowerCase().includes(value)
    );
    setProjectListFilter(newProjectListFilter);
  };

  const handleCreatedProjectSuccess = (newProject: ProjectType) => {
    const inputSearchEle: HTMLInputElement | null = document.querySelector("#project-page__search-input");
    if (inputSearchEle) {
      inputSearchEle.value = "";
    }
    const newProjectList = [...projectList, newProject];
    setProjectList(newProjectList);
    setProjectListFilter(newProjectList);
    setIsShowCreateProjectForm(false);
  };

  const handleUpdateProjectRedis = async () => {
    try {
      dispatch(setLoading(true));
      const resultUpdateRedis = await projectAPI.updateRedis();
      handleMessage(resultUpdateRedis.data.result ? "success" : "error", resultUpdateRedis.data.msg);
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    projectList.length === 0 && handleGetProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-page">
      {/* <h1 className="page-heading d-flex text-gray-900 fw-bold fs-5 flex-column justify-content-center mb-5">
        Project Dashboard
      </h1> */}
      <div className="card card-flush h-xl-100">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">Danh sách dự án</span>
            <span className="text-gray-500 mt-1 fw-semibold fs-6">{projectListFilter.length} dự án</span>
          </h3>
          <div className="card-toolbar">
            <div className="d-flex flex-stack flex-wrap gap-4">
              {account?.role === "ROLE_ADMIN" && (
                <div className="position-relative my-1">
                  <button className="btn btn-primary btn-sm" onClick={handleUpdateProjectRedis}>
                    Update Redis
                  </button>
                </div>
              )}
              <div className="position-relative my-1">
                <button className="btn btn-primary btn-sm" onClick={() => setIsShowCreateProjectForm(true)}>
                  Thêm
                </button>
              </div>
              <div className="position-relative my-1">
                <img
                  src="/assets/icons/search.svg"
                  alt=""
                  className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
                />
                <input
                  type="text"
                  id="project-page__search-input"
                  data-kt-table-widget-4="search"
                  className="form-control form-control-sm w-125px fs-7 ps-12"
                  placeholder="Search"
                  onInput={(e) => handleInputSearch(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-2">
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer">
                <thead>
                  <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-100px sorting_disabled">Code</th>
                    <th className="text-start min-w-200px sorting_disabled">Tên dự án</th>
                    <th className="text-center w-50px sorting_disabled">Logo</th>
                    <th className="text-center min-w-100px sorting_disabled">Tên miền</th>
                    <th className="text-center min-w-100px sorting_disabled">Loại dự án</th>
                    <th className="text-end min-w-100px sorting_disabled"></th>
                  </tr>
                </thead>
                <tbody className="fw-bold text-gray-600">
                  {projectList.length === 0 || projectListFilter.length === 0 ? (
                    <tr>
                      <td colSpan={7}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    projectListFilter.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="text-start">
                            <Link
                              className="text-gray-800 text-hover-primary"
                              to={`/dashboard/project/list/${item.code}`}
                            >
                              {item.code}
                            </Link>
                          </td>
                          <td className="text-start">
                            <Link
                              className="text-gray-800 text-hover-primary"
                              to={`/dashboard/project/list/${item.code}`}
                            >
                              {item.name}
                            </Link>
                          </td>
                          <td className="text-center">
                            <ImagePreview src={item.logo} className="img-avatar-player rounded w-50px h-50px" />
                          </td>
                          <td className="text-center">{item.domain}</td>
                          <td className="text-center">
                            <span className="fw-bolder">
                              <Select
                                defaultValue={item.type}
                                className="w-100 fw-bolder"
                                onChange={(value) => handleChangeProjectType(value, item)}
                                options={item.loginTypeList}
                              />
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="d-flex justify-content-end">
                              <Link
                                to={`/dashboard/project/list/${item.code}`}
                                className="btn btn-icon btn-primary me-2"
                              >
                                <i className="fa-regular fa-pen-to-square fs-2"></i>
                              </Link>
                              <button className="btn btn-icon btn-danger" onClick={() => setProjectIdDelete(item.id)}>
                                <i className="fa-regular fa-trash-can fs-2"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal open={!!projectIdDelete} closable={false} footer={null}>
        <div className="py-20">
          <div className="text-center mb-10">
            <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
          </div>
          <div className="text-center mb-10">
            Bạn có chắc chắn muốn xoá dự án <span className="fw-bolder">#{projectIdDelete}</span> ?
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-light-primary mx-2" onClick={() => setProjectIdDelete(null)}>
              Huỷ
            </button>
            <button type="button" className="btn btn-light-danger mx-2" onClick={handleDeleteProject}>
              Tôi chắc chắn
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isShowCreateProjectForm}
        footer={null}
        onCancel={() => setIsShowCreateProjectForm(false)}
        maskClosable={false}
        destroyOnClose
        style={{ top: 20 }}
      >
        <CreateProjectForm onCreatedSuccess={handleCreatedProjectSuccess} />
      </Modal>
    </div>
  );
};

export default withErrorBoundary(ProjectPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
