import React, { useEffect, useReducer } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType } from "../../../types";
import { projectAPI } from "../../../services";
import { Empty, Modal } from "antd";
import { convertUnixTimeToDate, handleCheckAxiosError } from "../../../utils";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setAccount } from "../../../redux/slices/accountSlice";
import { handleMessage } from "../../commons/Message";
import AddDeleteUserByFile from "./AddDeleteUserByFile";
import CreateUserForm from "./CreateUserForm";
import NotificationSettingUser from "./NotificationSettingUser";
import ConfirmSettingUser from "./ConfirmSettingUser";
import { SettingProjectUserContext } from "./context/context";
import {
  setAction,
  setConfirmationData,
  setNotificationList,
  setSelectedEmailList,
  setUpdatedUser,
  setUserList,
  setUserListFilter,
  settingProjectUserReducer,
} from "./context/reducer";
import { initialSettingProjectUserState } from "./context/state";
import UpdateUserForm from "./UpdateUserForm";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

const SettingProjectUser: React.FC<Props> = ({ projectDetail }) => {
  const dispatch = useDispatch();
  const [state, dispatchContext] = useReducer(
    settingProjectUserReducer,
    initialSettingProjectUserState
  );
  const userList = state.userList;
  const userListFilter = state.userListFilter;
  const selectedEmailList = state.selectedEmailList;
  const confirmationData = state.confirmationData;
  const action = state.action;
  const notificationList = state.notificationList;

  const handleInputSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.toLowerCase().trim();
    dispatchContext(
      setUserListFilter(
        userList.filter((item) => {
          return (
            item.email.toLowerCase().includes(value) ||
            item.fullName.toLowerCase().includes(value) ||
            item.department.toLowerCase().includes(value) ||
            `${item.id}`.toLowerCase().includes(value) ||
            item.phone?.toLowerCase().includes(value)
          );
        })
      )
    );
  };

  const handleChangeCheckbox = (email: string, checked: boolean) => {
    if (email === "all" && checked) {
      const emailList = userList.map((item) => item.email);
      dispatchContext(setSelectedEmailList(emailList));
    } else if (email === "all" && !checked) {
      dispatchContext(setSelectedEmailList([]));
    } else if (email !== "all" && checked) {
      dispatchContext(setSelectedEmailList([...selectedEmailList, email]));
    } else {
      dispatchContext(setSelectedEmailList(selectedEmailList.filter((item) => item !== email)));
    }
  };

  const handleGetUserList = async () => {
    try {
      dispatch(setLoading(true));
      const resultGetUserList = await projectAPI.getUsersListOfProject(
        projectDetail.profile.projectId
      );
      if (resultGetUserList.data.result) {
        dispatchContext(setUserList(resultGetUserList.data.data));
      } else {
        handleMessage("error", resultGetUserList.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeUserStatus = async (email: string, currentStatus: boolean) => {
    try {
      dispatch(setLoading(true));
      const resultChangeUserStatusApi = await projectAPI.changeUserStatus(
        projectDetail.profile.projectId,
        [email],
        !currentStatus
      );
      if (resultChangeUserStatusApi.data.result) {
        dispatchContext(
          setUserList(
            userList.map((item) => {
              if (item.email === email) {
                item.status = !currentStatus;
              }
              return item;
            })
          )
        );
        handleMessage("success", resultChangeUserStatusApi.data.msg);
      } else {
        handleMessage("error", resultChangeUserStatusApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    handleGetUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchContext(setAction(null));
    dispatchContext(setSelectedEmailList([]));
    const checkboxAllEle: HTMLInputElement | null = document.querySelector(
      "#setting-project-user__select-all"
    );
    if (checkboxAllEle) {
      checkboxAllEle.checked = false;
    }
  }, [state.notificationList]);

  useEffect(() => {
    dispatchContext(setUserListFilter(userList));
    dispatchContext(setAction(null));
    dispatchContext(setSelectedEmailList([]));
    dispatchContext(setConfirmationData(null));
    const searchInputEle: HTMLInputElement | null = document.querySelector(
      "#setting-project-user__search-input"
    );
    const checkboxAllEle: HTMLInputElement | null = document.querySelector(
      "#setting-project-user__select-all"
    );
    if (searchInputEle) {
      searchInputEle.value = "";
    }
    if (checkboxAllEle) {
      checkboxAllEle.checked = false;
    }
  }, [userList]);

  return (
    <SettingProjectUserContext.Provider value={{ state, dispatchContext }}>
      <div className="user-list-of-project">
        <div className="card-body border-top p-9">
          <div className="card-toolbar d-flex justify-content-between flex-wrap">
            <div className="d-flex flex-start flex-wrap gap-4">
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  disabled={selectedEmailList.length === 0}
                  onClick={() => {
                    dispatchContext(
                      setConfirmationData({ type: "RESET_PASS", emailList: selectedEmailList })
                    );
                  }}
                >
                  Reset Password
                </button>
              </div>
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  disabled={selectedEmailList.length === 0}
                  onClick={() => {
                    dispatchContext(
                      setConfirmationData({ type: "DISABLE_STATUS", emailList: selectedEmailList })
                    );
                  }}
                >
                  Khoá
                </button>
              </div>
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  disabled={selectedEmailList.length === 0}
                  onClick={() => {
                    dispatchContext(
                      setConfirmationData({ type: "DELETE", emailList: selectedEmailList })
                    );
                  }}
                >
                  Xoá
                </button>
              </div>
            </div>
            <div className="d-flex flex-start flex-wrap gap-4">
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => dispatchContext(setAction("CREATE_USER"))}
                >
                  Thêm người dùng
                </button>
              </div>
              <div className="position-relative my-1">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => dispatchContext(setAction("ACTION_BY_FILE"))}
                >
                  Xử lý File
                </button>
              </div>
              <div className="position-relative my-1">
                <img
                  src="/assets/icons/search.svg"
                  alt=""
                  className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
                />
                <input
                  type="text"
                  data-kt-table-widget-4="search"
                  className="form-control form-control-sm w-125px fs-7 ps-12"
                  placeholder="Search"
                  id="setting-project-user__search-input"
                  onInput={handleInputSearch}
                />
              </div>
            </div>
          </div>
          <h3 className="card-title align-items-start flex-column mt-5">
            <span className="text-gray-500 mt-1 fw-semibold fs-6">
              Tổng số người dùng: {userList.length}&nbsp;&frasl;&nbsp;
              {projectDetail.profile.userLimit}
            </span>
          </h3>
          <div
            id="kt_table_widget_4_table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer mt-5"
          >
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer"
                id="kt_table_widget_4_table"
              >
                <thead>
                  <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <td>
                      <div className="form-check form-check-custom align-items-end form-check-sm">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="setting-project-user__select-all"
                          onChange={(e) => handleChangeCheckbox("all", e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="setting-project-user__select-all"
                        >
                          All
                        </label>
                      </div>
                    </td>
                    <th className="min-w-100px sorting_disabled">Họ tên</th>
                    <th className="text-start min-w-125px sorting_disabled">Số điện thoại</th>
                    <th className="text-start min-w-125px sorting_disabled">Phòng ban</th>
                    <th className="text-start min-w-100px sorting_disabled">Ngày tạo</th>
                    <th className="text-center min-w-100px sorting_disabled">Trạng thái</th>
                    <th className="text-center min-w-125px sorting_disabled">Wifi profile</th>
                    <th className="min-w-150px"></th>
                  </tr>
                </thead>
                <tbody className="fw-bold text-gray-600">
                  {userList.length === 0 || userListFilter.length === 0 ? (
                    <tr>
                      <td colSpan={7}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    userListFilter.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="text-start">
                            <div className="form-check form-check-custom align-items-end form-check-sm">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.email}
                                checked={selectedEmailList.includes(item.email)}
                                onChange={(e) => handleChangeCheckbox(item.email, e.target.checked)}
                              />
                            </div>
                          </td>
                          <td>
                            <p className="mb-0 fw-bolder">{item.fullName}</p>
                            <small className="fw-normal">{item.email}</small>
                          </td>
                          <td>{item?.phone}</td>
                          <td>{item.department}</td>
                          <td className="text-start">{convertUnixTimeToDate(item.creationDate)}</td>
                          <td>
                            <div className="form-check form-check-success form-switch form-check-custom form-check-solid justify-content-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={item.status}
                                onChange={() => handleChangeUserStatus(item.email, item.status)}
                              />
                            </div>
                          </td>
                          <td className="text-center">{item.wifiProfile}</td>
                          <td className="text-end">
                            <button
                              className="btn btn-icon btn-sm btn-primary me-2"
                              onClick={() => {
                                dispatchContext(setUpdatedUser(item));
                              }}
                            >
                              <i className="fa-regular fa-pen-to-square fs-2"></i>
                            </button>
                            <button
                              className="btn btn-icon btn-sm btn-warning me-2"
                              onClick={() => {
                                dispatchContext(
                                  setConfirmationData({
                                    type: "RESET_PASS",
                                    emailList: [item.email],
                                  })
                                );
                              }}
                            >
                              <i className="fa-solid fa-key"></i>
                            </button>
                            <button
                              className="btn btn-icon btn-sm btn-danger"
                              onClick={() => {
                                dispatchContext(
                                  setConfirmationData({ type: "DELETE", emailList: [item.email] })
                                );
                              }}
                            >
                              <i className="fa-regular fa-trash-can fs-2"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal
          open={!!action}
          footer={null}
          onCancel={() => {
            dispatchContext(setAction(null));
            dispatchContext(setNotificationList([]));
            setConfirmationData(null);
          }}
          className="setting-project-user__action-modal"
          destroyOnClose
          style={{ top: 20 }}
        >
          {action === "ACTION_BY_FILE" ? (
            <AddDeleteUserByFile projectDetail={projectDetail} />
          ) : action === "CREATE_USER" ? (
            <CreateUserForm projectDetail={projectDetail} />
          ) : action === "UPDATE_USER" ? (
            <UpdateUserForm projectDetail={projectDetail} />
          ) : (
            <ConfirmSettingUser projectDetail={projectDetail} confirmationData={confirmationData} />
          )}
        </Modal>

        {/* Modal notification */}
        <Modal
          open={notificationList.length > 0}
          footer={null}
          className="setting-project-user__notification-modal"
          onCancel={() => dispatchContext(setNotificationList([]))}
          destroyOnClose
          style={{ top: 20 }}
        >
          <NotificationSettingUser notificationList={notificationList} />
        </Modal>
      </div>
    </SettingProjectUserContext.Provider>
  );
};

export default withErrorBoundary(SettingProjectUser, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
