import axiosInstance from "../axiosInstance";
import {
  ProjectCreatedType,
  ProjectProfileType,
  ProjectType,
  RegisteredUserType,
  UpdatedUserType,
  WifiProfileCreatedType,
  WifiProfileType,
} from "../../types";
import { ProjectDeviceCreatedType } from "../../types/ProjectType";

const projectAPI = {
  // GET PROJECT LIST
  getProjectList: () => {
    return axiosInstance.post("/project");
  },
  // UPDATE PROJECT REDIS ALL
  updateRedis: () => {
    return axiosInstance.post("/project/updateDBToRedis");
  },
  // GET DETAIL
  getProjectDetail: (projectId: string | null) => {
    return axiosInstance.post("/project/details", {
      projectId,
    });
  },
  // CHANGE LOGIN TYPE
  changeLoginType: (payload: ProjectType) => {
    return axiosInstance.post("/project/type/login/change", {
      ...payload,
    });
  },
  // UPDATE PROFILE PROJECT
  updateProjectProfile: (payload: ProjectProfileType) => {
    return axiosInstance.post(`project/profile/edit`, {
      ...payload,
    });
  },
  // CREATE PROJECT
  createProject: (payload: ProjectCreatedType) => {
    return axiosInstance.post("/project/create", {
      ...payload,
    });
  },
  // DELETE PROJECT
  deleteProject: (projectId: number) => {
    return axiosInstance.post(`/project/delete/${projectId}`);
  },
  // GET ALL CUSOMER OF PROJECT
  getProjectCustomer: (projectId: string) => {
    return axiosInstance.post(`/project/users/customer`, {
      projectId,
    });
  },
  // GET ALL CUSTOMER NOT IN ANY PROJECT
  getCustomerListNotInAnyProject: () => {
    return axiosInstance.post("/project/getCustomersNotInAnyProject");
  },
  // ADD CUSTOMER LIST TO PROJECT
  addCustomerToProject: (projectId: string, customerIdList: string[]) => {
    return axiosInstance.post(`/project/users/customer/add`, {
      userIds: customerIdList,
      projectId,
    });
  },
  // DELETE CUSTOMER FROM PROJECT
  deleteCustomerFromProject: (projectId: string, customerIdList: string) => {
    return axiosInstance.post(`/project/users/customer/delete`, {
      userId: customerIdList,
      projectId,
    });
  },
  // GET USER LIST OF PROJECT
  getUsersListOfProject: (projectId: string) => {
    return axiosInstance.post(`/project/users`, {
      projectId,
    });
  },
  // REGISTER USER TO PROJECT
  registerUserToProject: (projectId: string, payload: RegisteredUserType) => {
    return axiosInstance.post(`/project/users/register`, {
      ...payload,
      projectId,
    });
  },
  // CHANGE USER STATUS
  changeUserStatus: (projectId: string, emailList: string[], status: boolean) => {
    return axiosInstance.post(`/project/users/changeStatus`, {
      emails: emailList,
      projectId,
      status: status ? 1 : 0,
    });
  },
  // DELETE USER TO PROJECT
  deleteUserToProject: (projectId: string, emailList: string[]) => {
    return axiosInstance.post(`/project/users/delete`, {
      emails: emailList,
      projectId,
    });
  },
  // RESET USER PASS
  resetUserPass: (projectId: string, emailList: string[]) => {
    return axiosInstance.post(`/project/users/resetPass`, {
      emails: emailList,
      projectId,
    });
  },
  // UPDATE USER'S INFORMATION
  updateUser: (projectId: string, updatedUser: UpdatedUserType) => {
    return axiosInstance.post("/project/users/edit", {
      ...updatedUser,
      projectId,
    });
  },
  // CREATE WIFI PROFILE
  createWifiProfile: (projectId: string, createdUser: WifiProfileCreatedType) => {
    return axiosInstance.post("/project/wifi-profile/create", {
      ...createdUser,
      projectId,
    });
  },
  // DELETE WIFI PROFILE
  deleteWifiProfile: (projectId: string, wifiProfileId: number) => {
    return axiosInstance.post("/project/wifi-profile/delete", {
      id: wifiProfileId,
      projectId,
    });
  },
  // UPDATE WIFI PROFILE
  updatedWifiProfile: (projectId: string, wifiProfile: WifiProfileType) => {
    return axiosInstance.post("/project/wifi-profile/edit", {
      ...wifiProfile,
      projectId,
    });
  },
  // CREATE DEVICE
  createDevice: (device: ProjectDeviceCreatedType) => {
    return axiosInstance.post("/project/devices/create", {
      ...device,
    });
  },
  // DELETE DEVICE
  deleteDevice: (projectId: string, idDevice: string) => {
    return axiosInstance.post("/project/devices/delete", {
      projectId,
      id: idDevice,
    });
  },
  // UPDATE DEVICE
  updateDevice: (device: ProjectDeviceCreatedType) => {
    return axiosInstance.post("/project/devices/edit", {
      ...device,
    });
  },
  // TRANSFER DEVICE TO OTHER PROJECT
  transferDeviceToOtherProject: (newProjectId: string, deviceId: string) => {
    return axiosInstance.post("/project/devices/move", {
      projectId: newProjectId,
      id: deviceId,
    });
  },
  // CONNECT DEVICE
  connectDevice: (id: string, connected: boolean, projectId: string) => {
    return axiosInstance.post("/project/devices/connect", {
      id,
      connected,
      projectId,
    });
  },
  // REFRESH USER FREE
  refreshUserFree: (projectId: string) => {
    return axiosInstance.post("/project/refresh-user-free", {
      projectId,
    });
  },
};

export default projectAPI;
