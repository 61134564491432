import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { getIntroductionSelector } from "../../../redux/selectors";

const FooterIntroduction: React.FC = () => {
  const introduction = useSelector(getIntroductionSelector);

  const handleScrollTo = (idElement: string) => {
    const element = document.querySelector(idElement);
    if (element) {
      element.scrollIntoView();
    }
  };
  return (
    <div className="footer-introduction pt-10">
      <div className="container-md pb-10">
        <div className="row">
          <div className="col-12 col-md-3">
            <h5 className="text-wimesh fw-bold mb-2 mb-md-3 mt-4 mt-md-0">THÔNG TIN CÔNG TY</h5>
            <p className="mb-0">{introduction?.companyInformation.name}</p>
            <p className="mb-0">Địa chỉ: {introduction?.companyInformation.address}</p>
            <p className="mb-0">
              Email: <a href={`mailto:${introduction?.email}`}>{introduction?.email}</a>
            </p>
            <p className="mb-0">
              Điện thoại:{" "}
              <a href={`tel:${introduction?.companyInformation.phone}`}>
                {introduction?.companyInformation.phone}
              </a>
            </p>
          </div>
          <div className="col-12 col-md-3">
            <h5 className="text-wimesh fw-bold mb-2 mb-md-3 mt-4 mt-md-0">DỊCH VỤ</h5>
            <p className="mb-2">Wifi marketing</p>
            <p className="mb-2">Wifi trường học</p>
            <p className="mb-2">Thi công hạ tầng mạng</p>
            <p className="mb-2">Lắp đặt camera an ninh</p>
          </div>
          <div className="col-12 col-md-3">
            <h5 className="text-wimesh fw-bold mb-2 mb-md-3 mt-4 mt-md-0">THÔNG TIN WEBSITE</h5>
            <p
              className="btn btn-link text-hover-wimesh d-block text-start fs-base p-0 mb-2"
              onClick={() => handleScrollTo("#Formats")}
            >
              Formats
            </p>
            <p
              className="btn btn-link text-hover-wimesh d-block text-start fs-base p-0 mb-2"
              onClick={() => handleScrollTo("#Differentiations")}
            >
              Differentiations
            </p>
            <p
              className="btn btn-link text-hover-wimesh d-block text-start fs-base p-0 mb-2"
              onClick={() => handleScrollTo("#Locations")}
            >
              Locations
            </p>
            <p
              className="btn btn-link text-hover-wimesh d-block text-start fs-base p-0 mb-2"
              onClick={() => handleScrollTo("#Targeting")}
            >
              Targeting
            </p>
            <p
              className="btn btn-link text-hover-wimesh d-block text-start fs-base p-0 mb-2"
              onClick={() => handleScrollTo("#Contact")}
            >
              Contact
            </p>
          </div>
          <div className="col-12 col-md-3">
            <h5 className="text-wimesh fw-bold mb-2 mb-md-3 mt-4 mt-md-0">BẢN ĐỒ</h5>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62697.38619760656!2d106.762808!3d10.842912!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317527a44d008f83%3A0xa11c4e5786fd2f06!2zMTEzIMSQ4bq3bmcgVsSDbiBCaSwgVHLGsOG7nW5nIFRo4buNLCBUaOG7pyDEkOG7qWMsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCA3MDAwMDAsIFZpZXRuYW0!5e0!3m2!1sen!2sus!4v1697605116156!5m2!1sen!2sus"
              height={150}
              className="w-100 border-0"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
      <p className="mb-0 bg-dark text-white-50 text-center py-4">
        © Copyright 2023 Wi-MESH. All rights reserved.
      </p>
    </div>
  );
};

export default withErrorBoundary(FooterIntroduction, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
