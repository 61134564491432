import axiosInstance from "../axiosInstance";

const uploadAPI = {
  // UPLOAD IMAGE RETURN IMAGE URL
  uploadFile: (file: any) => {
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    return axiosInstance.post("/upload", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // UPLOAD USER EXCEL FILE TO PROJECT
  uploadUsersFileToProject: (projectId: string, file: File) => {
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    formdata.append("projectId", projectId);
    return axiosInstance.post(`/project/users/upload`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // UPLOAD DELETE USER FILE TO PROJECT
  uploadDeleteUserFileToProject: (projectId: string, file: File) => {
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    formdata.append("projectId", projectId);
    return axiosInstance.post(`/project/users/delete/file`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default uploadAPI;
