import { CreatedCustomerType, CustomerType, UpdatedCustomerType } from "../../types";
import axiosInstance from "../axiosInstance";

const customerAPI = {
  // GET ALL CUSTOMER
  getCustomers: () => {
    return axiosInstance.post("/project/customers");
  },
  // DELETE CUSTOMER
  deleteCustomer: (customer: CustomerType) => {
    console.log(customer);
    return axiosInstance.post("/project/customers/delete", {
      id: customer.id,
      projectId: customer.projectId,
      code: customer.code,
    });
  },
  // CREATE CUSTOMER
  createCustomer: (createdCustomer: CreatedCustomerType) => {
    return axiosInstance.post("/project/customers/register", {
      ...createdCustomer,
    });
  },
  // UPDATE CUSTOMER
  updateCustomer: (updatedCustomer: UpdatedCustomerType) => {
    return axiosInstance.post("/project/customers/edit", {
      ...updatedCustomer,
    });
  },
};

export default customerAPI;
