import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./scss/login-page.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/slices/loadingSlice";
import { handleMessage } from "../components/commons/Message";
import { regex } from "../utils";
import { getIsGetIntroductionSelector, getIsRegistrationOpenSelector } from "../redux/selectors";
import { Navigate } from "react-router-dom";

interface FormValuesType {
  phone: string;
  password: string;
  confirmPassword: string;
  email: string;
  fullname: string;
}

const SignUpPage: React.FC = () => {
  const isGetIntroduction = useSelector(getIsGetIntroductionSelector);
  const isRegistrationOpen = useSelector(getIsRegistrationOpenSelector);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValuesType>();
  const formValuesState = watch();
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<FormValuesType> = async (data) => {
    try {
      dispatch(setLoading(true));
      alert(JSON.stringify(data));
    } catch (error) {
      console.log("Lỗi đăng ký: ", error);
      handleMessage("error", "Lỗi hệ thống vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="signup-page px-5 mb-5 pb-5">
      {!isGetIntroduction ? (
        <></>
      ) : isRegistrationOpen ? (
        <>
          {" "}
          <div className="d-flex justify-content-center align-items-center">
            <p className="mb-0 fs-2 fw-light text-capitalize">Đăng ký</p>
          </div>
          <form className="mt-5" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="mb-4 position-relative">
              <label className="text-uppercase mt-2 fw-bold">Tên đăng nhập</label>
              <input
                type="text"
                placeholder="Số điện thoại"
                className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
                  errors.phone && "border-danger"
                }`}
                {...register("phone", {
                  required: "Vui lòng nhập Số điện thoại!",
                  pattern: {
                    value: regex.phone,
                    message: "Số điện thoại không hợp lệ!",
                  },
                })}
                onBlur={() => setValue("phone", formValuesState.phone?.trim())}
              />
              {errors.phone && (
                <p className="error-message mb-0 text-danger px-4">{errors.phone.message}</p>
              )}
            </div>
            <div className="mb-4 position-relative">
              <label className="text-uppercase mt-2 fw-bold">Email</label>
              <input
                type="email"
                placeholder="Email"
                className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
                  errors.email && "border-danger"
                }`}
                {...register("email", {
                  required: "Vui lòng nhập Email!",
                  pattern: {
                    value: regex.email,
                    message: "Email không hợp lệ!",
                  },
                })}
                onBlur={() => setValue("email", formValuesState.email?.trim())}
              />
              {errors.email && (
                <p className="error-message mb-0 text-danger px-4">{errors.email.message}</p>
              )}
            </div>
            <div className="mb-4 position-relative">
              <label className="text-uppercase mt-2 fw-bold">Họ tên</label>
              <input
                type="text"
                placeholder="Họ tên"
                className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
                  errors.fullname && "border-danger"
                }`}
                {...register("fullname", {
                  required: "Vui lòng nhập Họ tên!",
                })}
                onBlur={() => setValue("fullname", formValuesState.fullname?.trim())}
              />
              {errors.fullname && (
                <p className="error-message mb-0 text-danger px-4">{errors.fullname.message}</p>
              )}
            </div>
            <div className="mb-4 position-relative">
              <label className="text-uppercase mt-2 fw-bold">Mật khẩu</label>
              <input
                type="password"
                placeholder="Mật khẩu"
                className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
                  errors.password && "border-danger"
                }`}
                {...register("password", {
                  required: "Vui lòng nhập Mật khẩu!",
                  pattern: {
                    value: regex.password,
                    message: "Tối thiểu tám ký tự, ít nhất một chữ cái và một số!",
                  },
                })}
              />
              {errors.password && (
                <p className="error-message mb-0 text-danger px-4">{errors.password.message}</p>
              )}
            </div>
            <div className="mb-4 position-relative">
              <label className="text-uppercase mt-2 fw-bold">Mật khẩu</label>
              <input
                type="password"
                placeholder="Nhập lại mật khẩu"
                className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
                  errors.confirmPassword && "border-danger"
                }`}
                {...register("confirmPassword", {
                  required: "Vui lòng nhập lại Mật khẩu!",
                  validate: (val: string) => {
                    if (watch("password") !== val) {
                      return "Mật khẩu không trùng khớp!";
                    }
                  },
                })}
              />
              {errors.confirmPassword && (
                <p className="error-message mb-0 text-danger px-4">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
            <button className="btn text-white fs-5 text-uppercase bg-wimesh bg-hover-wimesh rounded-pill w-100 mt-3">
              Đăng ký
            </button>
          </form>
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default withErrorBoundary(SignUpPage, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
