import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import "./scss/login-page.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/loadingSlice";
import { handleMessage } from "../components/commons/Message";
import { accountAPI } from "../services";
import OTPInput from "../components/commons/OTPInput";
import { useNavigate } from "react-router-dom";
import { regex } from "../utils";

interface FormValuesType {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValuesType>();
  const formValuesState = watch();
  const dispatch = useDispatch();
  const [isShowOtpInput, setIsShowOtpInput] = useState<boolean>(false);
  const [deadlineAllowResend, setDeadlingAllowResend] = useState(Date.now() + 60 * 1000);
  const navigate = useNavigate();

  const handleRequestOtp = async (email: string) => {
    try {
      dispatch(setLoading(true));
      const resultForgotPasswordApi = await accountAPI.forgotPassword(email);
      if (resultForgotPasswordApi.data.result) {
        handleMessage("success", resultForgotPasswordApi.data.msg);
        setIsShowOtpInput(true);
        setDeadlingAllowResend(Date.now() + 60 * 1000);
      } else {
        handleMessage("error", resultForgotPasswordApi.data.msg);
      }
    } catch (error) {
      console.log("Lỗi quên mật khẩu: ", error);
      handleMessage("error", "Lỗi hệ thống vui lòng thử lại!");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSubmit: SubmitHandler<FormValuesType> = async (data) => {
    handleRequestOtp(data.email);
  };

  const handleResendOtp = () => {
    handleRequestOtp(formValuesState.email);
  };

  const handleVerifyOTP = async (otpValue: string | null) => {
    if (otpValue) {
      try {
        dispatch(setLoading(true));
        const resultConfirmOtpApi = await accountAPI.confirmOTP(formValuesState.email, otpValue);
        if (resultConfirmOtpApi.data.result) {
          handleMessage("success", resultConfirmOtpApi.data.msg);
          navigate("/login");
        } else {
          handleMessage("error", resultConfirmOtpApi.data.msg);
        }
      } catch (error) {
        handleMessage("error", "Lỗi hệ thống. Vui lòng thử lại!");
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  return (
    <div className="forgot-password-page px-5 mb-5 pb-5">
      <div className="d-flex justify-content-center align-items-center">
        <p className="mb-0 fs-2 fw-light text-capitalize">Quên mật khẩu</p>
      </div>
      {isShowOtpInput ? (
        <>
          <p className="my-4 text-center">
            Vui lòng nhập mã OTP đã được gửi đến{" "}
            <span className="fw-bold text-wimesh">{formValuesState.email}</span> để xác thực việc
            quên mật khẩu của bạn
          </p>
          <OTPInput
            numberInput={6}
            onSubmit={(value) => handleVerifyOTP(value)}
            onResend={handleResendOtp}
            deadline={deadlineAllowResend}
          />
        </>
      ) : (
        <form className="mt-5" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-4 position-relative">
            <label className="text-uppercase mt-2 fw-bold">Email</label>
            <input
              type="email"
              placeholder="Email"
              className={`d-block w-100 rounded-pill py-3 px-4 mt-2 ${
                errors.email && "border-danger"
              }`}
              {...register("email", {
                required: "Vui lòng nhập Email!",
                pattern: {
                  value: regex.email,
                  message: "Email không hợp lệ!",
                },
              })}
              onBlur={() => setValue("email", formValuesState.email?.trim())}
            />
            {errors.email && (
              <p className="error-message mb-0 text-danger px-4">{errors.email.message}</p>
            )}
          </div>
          <button className="btn text-white fs-5 text-uppercase bg-wimesh bg-hover-wimesh rounded-pill w-100 mt-3">
            Gửi email xác nhận
          </button>
        </form>
      )}
    </div>
  );
};

export default withErrorBoundary(ForgotPassword, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
