import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLoading } from "../redux/slices/loadingSlice";
import axios from "axios";
import { handleMessage } from "../components/commons/Message";

interface ProjectInfo {
  logo: string;
  name: string;
  title: string;
  link: string;
}

const CheckOsPage: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);
  const [iconCopy, setIconCopy] = useState<"regular" | "solid">("regular");

  const handleCopyText = () => {
    if (projectInfo) {
      setIconCopy("solid");
      navigator.clipboard.writeText(projectInfo.link);
      handleMessage("success", "Copy thành công.");
      setTimeout(() => {
        setIconCopy("regular");
      }, 2500);
    }
  };

  const handleGetProjectInfo = async () => {
    try {
      dispatch(setLoading(true));
      const resultProjectInfoApi = await axios.post(
        `${process.env.REACT_APP_DOMAIN_API}/api-login/check-captive-ios?code=${searchParams.get("code")}`
      );
      if (resultProjectInfoApi.data.result) {
        setProjectInfo(resultProjectInfoApi.data.data);
      } else {
        handleMessage("error", resultProjectInfoApi.data.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    handleGetProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      {projectInfo ? (
        <React.Fragment>
          <div className="d-flex align-items-center py-5 border-bottom border-secondary border-2">
            <img src={projectInfo.logo} className="w-75px" alt="" />
            <div className="ms-5">
              <h2>{projectInfo.name}</h2>
              <h3>{projectInfo.title}</h3>
            </div>
          </div>
          <h1 className="text-primary mt-10 mb-5 text-center">Trình hiển thị mặc định của máy không được hỗ trợ!</h1>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img src="/assets/images/oops-captive.png" alt="" className="w-300px mx-auto" />
            <a
              href={projectInfo.link}
              target="_blank"
              className="btn btn-outline btn-outline-primary btn-active-light-primary rounded-pill fw-bold px-20 fs-4"
              rel="noreferrer"
            >
              Nhấn vào đây để mở trình duyệt
            </a>
          </div>
          <div className="border-bottom border-2 border-secondary mt-10 position-relative">
            <span
              className="position-absolute start-50 top-50 fs-4 px-3"
              style={{ transform: "translate(-50%, -50%)", background: "var(--bs-app-bg-color)" }}
            >
              Hoặc
            </span>
          </div>
          <div className="mt-5">
            <p className="mb-2 fs-4">
              1. Sao chép đường link:&nbsp;
              <span className="fw-bold">
                {projectInfo.link}&nbsp;
                <button className="btn btn-link p-0 ms-2" onClick={handleCopyText}>
                  <i className={`fa-${iconCopy} fa-paste fs-3 text-dark`}></i>
                </button>
              </span>
            </p>
            <p className="mb-2 fs-4">
              2. Mở bằng trình duyệt Chrome/FireFox/Edge/Safari có sẵn trên máy, mở 1 Tab mới, dán đường link đã copy
              vào thanh địa chỉ, rồi ấn Enter.
            </p>
            <p className="mb-2 fs-4">3. Làm theo các bước hướng dẫn tiếp theo để truy cập Internet.</p>
          </div>
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default CheckOsPage;
