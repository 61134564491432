import axios from "axios";
import baseAPI from "../config";
import { generateDeviceEntInit, generateDeviceId, namekey } from "../../utils";
import axiosInstance from "../axiosInstance";

const tokenAPI = {
  // GET TOKEN
  getToken: () => {
    const deviceId = localStorage.getItem(namekey.deviceId);
    const deviceEnt = generateDeviceEntInit(deviceId || generateDeviceId());
    return axios.post(`${baseAPI}/token`, JSON.stringify(deviceEnt), {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  // REFRESH TOKEN
  refreshTokenn: () => {
    return axiosInstance.post("/refresh-token", undefined);
  },
};

export default tokenAPI;
