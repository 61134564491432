import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { ProjectDetailType, ProjectProfileType, WifiProfileType } from "../../../types";
import { Empty, Modal } from "antd";
import { useDispatch } from "react-redux";
import { handleCheckAxiosError } from "../../../utils";
import { setAccount } from "../../../redux/slices/accountSlice";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { projectAPI } from "../../../services";
import { handleMessage } from "../../commons/Message";
import CreateWifiProfileForm from "./CreateWifiProfileForm";
import UpdateWifiProfileForm from "./UpdateWifiProfileForm";

interface Props {
  projectDetail: ProjectDetailType;
  onUpdateSuccess: (data: ProjectDetailType) => void;
}

const SettingProjectWifiProfile: React.FC<Props> = ({ projectDetail, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const wifiProfileList = projectDetail.profile.wifiProfile;
  const [deletedWifiProfile, setDeletedWifiProfile] = useState<WifiProfileType | null>(null);
  const [updatedWifiProfile, setUpdatedWifiProfile] = useState<WifiProfileType | null>(null);
  const [isShowCreateForm, setIsShowCreateForm] = useState(false);
  const [isShowConfirmRefreshUserFree, setIsShowConfirmRefreshUserFree] = useState(false);

  const handleRefreshUserFree = async () => {
    try {
      dispatch(setLoading(true));
      const resuktRefreshUserFreeApi = await projectAPI.refreshUserFree(projectDetail.profile.projectId);
      if (resuktRefreshUserFreeApi.data.result) {
        handleMessage("success", resuktRefreshUserFreeApi.data.msg);
      } else {
        handleMessage("error", resuktRefreshUserFreeApi.data.msg);
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      setIsShowConfirmRefreshUserFree(false);
    }
  };

  const handleDeleteWifiProfile = async () => {
    try {
      dispatch(setLoading(true));
      if (deletedWifiProfile) {
        const resultDeleteWifiProfileApi = await projectAPI.deleteWifiProfile(
          projectDetail.profile.projectId,
          deletedWifiProfile.id
        );
        handleUpdateProjectDetail("delete", deletedWifiProfile);
        if (resultDeleteWifiProfileApi.data.result) {
          handleMessage("success", resultDeleteWifiProfileApi.data.msg);
        } else {
          handleMessage("error", resultDeleteWifiProfileApi.data.msg);
        }
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      setDeletedWifiProfile(null);
    }
  };

  const handleUpdateProjectDetail = (type: "create" | "delete" | "update", wifiProfile: WifiProfileType) => {
    let newWifiProfileList: WifiProfileType[] = [];
    let newProjectProfile: ProjectProfileType;
    switch (type) {
      case "create":
        newWifiProfileList = [...wifiProfileList, wifiProfile];
        newProjectProfile = { ...projectDetail.profile, wifiProfile: newWifiProfileList };
        onUpdateSuccess({ ...projectDetail, profile: newProjectProfile });
        setIsShowCreateForm(false);
        break;
      case "update":
        newWifiProfileList = wifiProfileList.map((item) => {
          if (item.id === wifiProfile.id) {
            return wifiProfile;
          } else return item;
        });
        newProjectProfile = { ...projectDetail.profile, wifiProfile: newWifiProfileList };
        onUpdateSuccess({ ...projectDetail, profile: newProjectProfile });
        setUpdatedWifiProfile(null);
        break;
      case "delete":
        newWifiProfileList = wifiProfileList.filter((item) => item.id !== wifiProfile.id);
        newProjectProfile = { ...projectDetail.profile, wifiProfile: newWifiProfileList };
        onUpdateSuccess({ ...projectDetail, profile: newProjectProfile });
        break;
      default:
        break;
    }
  };

  return (
    <div className="setting-project-wifi-profile collapse show">
      <div className="card-body border-top p-9">
        <div className="card-toolbar">
          <div className="d-flex flex-end flex-wrap gap-4">
            <div className="position-relative my-1 me-auto">
              <button className="btn btn-primary btn-sm" onClick={() => setIsShowConfirmRefreshUserFree(true)}>
                Refresh user free
              </button>
            </div>
            <div className="position-relative my-1">
              <button className="btn btn-primary btn-sm" onClick={() => setIsShowCreateForm(true)}>
                Thêm
              </button>
            </div>
            <div className="position-relative my-1">
              <img
                src="/assets/icons/search.svg"
                alt=""
                className="position-absolute top-50 translate-middle-y ms-4 w-15px h-15px img-muted"
              />
              <input
                type="text"
                data-kt-table-widget-4="search"
                className="form-control form-control-sm w-125px fs-7 ps-12"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed table-hover fs-6 gy-3 gs-3 dataTable no-footer"
              id="kt_table_widget_4_table"
            >
              <thead>
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-100px sorting_disabled">ID</th>
                  <th className="min-w-100px sorting_disabled">Name</th>
                  <th className="text-center min-w-150px sorting_disabled">Thời gian sử dụng</th>
                  <th className="text-center min-w-150px sorting_disabled">Số lượng user</th>
                  <th className="text-end min-w-125px sorting_disabled">Băng thông</th>
                  <th className="text-start min-w-100px sorting_disabled"></th>
                </tr>
              </thead>
              <tbody className="fw-bold text-gray-600">
                {wifiProfileList.length === 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  wifiProfileList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>#{item.id}</td>
                        <td>{item.name}</td>
                        <td className="text-center">{item.aliveTimeout}</td>
                        <td className="text-center">{item.shareUser}</td>
                        <td className="text-end">{item.rateLimits}</td>
                        <td className="text-end">
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-icon btn-primary me-2"
                              onClick={() => setUpdatedWifiProfile(item)}
                            >
                              <i className="fa-regular fa-pen-to-square fs-2"></i>
                            </button>
                            <button className="btn btn-icon btn-danger" onClick={() => setDeletedWifiProfile(item)}>
                              <i className="fa-regular fa-trash-can fs-2"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <p className="text-warning fw-bolder mb-1 fs-5">Lưu ý:</p>
          <p className="mb-1">
            Các tài khoản dùng chung để đăng nhập qua mạng xã hội có các WiFi Profile tương ứng như sau:
          </p>
          <p className="mb-1">
            - Đăng nhập với <span className="text-primary">GOOGLE</span> tương ứng với WiFi Profile{" "}
            <span className="text-primary">USER_GOOGLE_SHARE</span>.
          </p>
          <p className="mb-1">
            - Đăng nhập với <span className="text-primary">MICROSOFT</span> tương ứng với WiFi Profile{" "}
            <span className="text-primary">USER_MICROSOFT_SHARE</span>.
          </p>
          <p className="mb-1">
            - Đăng nhập với <span className="text-primary">FACEBOOK</span> tương ứng với WiFi Profile{" "}
            <span className="text-primary">USER_FACEBOOK_SHARE</span>.
          </p>
          <p className="mb-1">
            Các WiFi Profile này sẽ tự động được tạo khi thêm mới một thiết bị. Nếu bạn không thấy danh sách WiFi
            Profile dùng chung này khi tạo thiết bị, hãy tạo thủ công theo các tên WiFi Profile tương ứng như trên.
          </p>
        </div>
      </div>

      <Modal
        open={!!deletedWifiProfile || !!updatedWifiProfile || isShowCreateForm || isShowConfirmRefreshUserFree}
        maskClosable={false}
        onCancel={() => {
          setDeletedWifiProfile(null);
          setUpdatedWifiProfile(null);
          setIsShowCreateForm(false);
        }}
        footer={null}
        destroyOnClose
        style={{ top: 10 }}
      >
        {deletedWifiProfile ? (
          <div className="py-20">
            <div className="text-center mb-10">
              <i className="fa-regular fa-circle-xmark fs-4x text-danger"></i>
            </div>
            <div className="text-center mb-10">
              Bạn có chắc chắn muốn xoá wifi profile <span className="fw-bolder">{deletedWifiProfile.name}</span> ?
            </div>
            <div className="text-center">
              <button type="button" className="btn btn-light-primary mx-2" onClick={() => setDeletedWifiProfile(null)}>
                Huỷ
              </button>
              <button type="button" className="btn btn-light-danger mx-2" onClick={handleDeleteWifiProfile}>
                Tôi chắc chắn
              </button>
            </div>
          </div>
        ) : isShowConfirmRefreshUserFree ? (
          <div className="py-20">
            <div className="text-center mb-10">
              <i className="fa-solid fa-triangle-exclamation fs-4x text-warning"></i>
            </div>
            <div className="text-center mb-10">
              Bạn có chắc chắn muốn <span className="fw-bolder">refresh user free</span> ?
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-secondary mx-2"
                onClick={() => setIsShowConfirmRefreshUserFree(false)}
              >
                Huỷ
              </button>
              <button type="button" className="btn btn-light-primary mx-2" onClick={handleRefreshUserFree}>
                Tôi chắc chắn
              </button>
            </div>
          </div>
        ) : updatedWifiProfile ? (
          <UpdateWifiProfileForm
            projectId={projectDetail.profile.projectId}
            wifiProfile={updatedWifiProfile}
            onSuccess={(newWifiProfile) => handleUpdateProjectDetail("update", newWifiProfile)}
          />
        ) : (
          <CreateWifiProfileForm
            projectId={projectDetail.profile.projectId}
            onSuccess={(newWifiProfile) => handleUpdateProjectDetail("create", newWifiProfile)}
          />
        )}
      </Modal>
    </div>
  );
};

export default withErrorBoundary(SettingProjectWifiProfile, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
