import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Loader from "./components/commons/Loader";
import { generateDeviceId, handleCheckAxiosError, namekey } from "./utils";
import { setLoading } from "./redux/slices/loadingSlice";
import Message, { handleMessage } from "./components/commons/Message";
import { tokenAPI, accountAPI, introductionAPI } from "./services";
import HistoryRouter, { history } from "./routes/HistoryRouter";
import MyRoutes from "./routes/MyRoutes";
import ButtonGoToTop from "./components/commons/ButtonGoToTop";
import "./sass/index.scss";
import { setIsFirstTimeGetAccountInfo, setAccount } from "./redux/slices/accountSlice";
import { getIsFirstTimeGetAccountInfoSelector } from "./redux/selectors";
import { withErrorBoundary } from "react-error-boundary";
import { setIntroduction } from "./redux/slices/introductionSlice";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isFirstTimeGetAccountInfo = useSelector(getIsFirstTimeGetAccountInfoSelector);

  const initApp = async () => {
    try {
      dispatch(setLoading(true));
      // CHECK DEVICE ID
      let deviceId: string | null = localStorage.getItem(namekey.deviceId);
      if (!deviceId) {
        deviceId = generateDeviceId();
        localStorage.setItem(namekey.deviceId, deviceId);
      }
      // CHECK TOKEN
      const token: string | null = localStorage.getItem(namekey.token);
      if (!token) {
        const resultTokenApi = await tokenAPI.getToken();
        if (resultTokenApi.data.result) {
          const newToken = resultTokenApi.data.data;
          localStorage.setItem(namekey.token, JSON.stringify(newToken));
        } else {
          console.log(resultTokenApi.data);
          handleMessage("error", resultTokenApi.data.msg);
        }
      }
      // GET INTRODUCTION DATA
      const resultIntroductionApi = await introductionAPI.getIntroduction();
      if (resultIntroductionApi.data.result) {
        dispatch(setIntroduction(resultIntroductionApi.data.data));
      } else {
        handleMessage("error", resultIntroductionApi.data.msg);
      }
      // GET ACCOUNT INFORMATION
      const resultAccountInfoApi = await accountAPI.getInfo();
      if (resultAccountInfoApi.data.result) {
        dispatch(setAccount(resultAccountInfoApi.data.data));
      }
    } catch (error) {
      handleCheckAxiosError(error, () => dispatch(setAccount(null)));
    } finally {
      dispatch(setLoading(false));
      dispatch(setIsFirstTimeGetAccountInfo());
    }
  };

  useEffect(() => {
    initApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HistoryRouter history={history}>
      {isFirstTimeGetAccountInfo && <MyRoutes />}
      <Loader />
      <ButtonGoToTop />
      <Message />
    </HistoryRouter>
  );
};

export default withErrorBoundary(App, {
  FallbackComponent: () => {
    return <div className="text-danger">!!! Something went wrong ...</div>;
  },
});
